import React from 'react'

export const isNum = num => typeof num === 'number'

// https://github.com/facebook/react/issues/8873#issuecomment-275423780
export function wrapCallback(origCb, ownCb) {
	return (...args) => {
		// call your own callback
		if (typeof ownCb === 'function') {
			ownCb(...args)
		}
		// call the original callback, if any
		if (typeof origCb === 'function') {
			origCb(...args)
		}
	}
}

const cbNames = [
	'onClick',
	'onFocus',
	'onBlur',
	'onMouseOver',
	'onMouseOut',
	'onMouseEnter',
	'onMouseLeave',
]

export function cloneElement(element, props = {}) {
	if (props.ref) {
		props.ref = wrapCallback(element.ref, props.ref)
	}
	cbNames.forEach(cbName => {
		if (props[cbName]) {
			props[cbName] = wrapCallback(element.props[cbName], props[cbName])
		}
	})
	return React.cloneElement(element, props)
}
