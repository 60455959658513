import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import ducks from 'front/ducks'

import Modal from 'ui/Modal'
import ModalTitle from 'ui/Modal/Title'
import ModalBody from 'ui/Modal/Body'
import ModalActions from 'ui/Modal/Actions'
import MenuButton from 'ui/Dropdown/MenuButton'
import Loading from 'ui/Loading'
import Button from 'ui/Button'
import { useAction } from '~/modules/utils/redux'
import { FiTrash as TrashIcon } from 'react-icons/fi'

function DeleteReportModal({ report, visible, setVisible }) {
	const [internalVisible, setInternalVisible] = useState(visible)

	const { requestId, loading, error, success } = useSelector(state =>
		state.getIn(['queries', 'deleteReport']).toJS()
	)

	const reportCode = useSelector(state => {
		const { organizationId, serviceId } = state.get('session').toJS()
		return state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
			'report_code',
		])
	})

	const deleteReport = useAction(ducks.actions.queriesDelete)
	const confirmDeleteReport = useAction(ducks.actions.queriesDeleteConfirm)
	const reset = useAction(ducks.actions.queriesDeleteReset)

	function runSecondaryAction(event) {
		event && event.preventDefault()
		setInternalVisible(false)
	}

	function runPrimaryAction(event) {
		event.preventDefault()
		deleteReport(report)
	}

	// automatically hide modal when request is successfull
	useEffect(() => {
		if (internalVisible && success && requestId === report.id) {
			setInternalVisible(false)
		}
	}, [internalVisible, success, requestId, report.id])

	// we delay the reset so that it happens once the modal is hidden
	useEffect(() => () => reset(), [])

	let state
	let body

	if (loading) {
		// loading
		state = 1
		body = <Loading>Procesando solicitud...</Loading>
	} else if (error) {
		// error
		state = 2
		let msg = `Tuvimos un error al eliminar ${
			reportCode === 'market' ? 'el mercado' : 'la consulta'
		}.`
		body = <p>{msg}</p>
	} else if (!loading && !error && requestId === null) {
		// waiting
		state = 0
	}

	return (
		<Modal
			visible={internalVisible}
			onDestroyed={() => {
				if (success && requestId === report.id) {
					confirmDeleteReport(report.id)
				}
				setVisible(false)
			}}
		>
			<ModalTitle>
				¿Eliminar{' '}
				{reportCode === 'market' ? 'el mercado ' : 'la consulta '}
				<i>{report.name}</i>?
			</ModalTitle>
			<ModalBody>{body}</ModalBody>
			<ModalActions>
				{state === 2 && (
					<Button role="primary" onClick={runSecondaryAction}>
						Cerrar
					</Button>
				)}
				{state === 0 && (
					<Button
						role="secondary"
						onClick={runSecondaryAction}
						disabled={state === 1}
					>
						Cancelar
					</Button>
				)}
				{state === 0 && (
					<Button
						role="warning"
						variant="contained"
						onClick={runPrimaryAction}
						disabled={state === 1}
					>
						Eliminar
					</Button>
				)}
			</ModalActions>
		</Modal>
	)
}

export default function useDeleteReport(report) {
	const [visible, setVisible] = useState(false)

	const reportCode = useSelector(state => {
		const { organizationId, serviceId } = state.get('session').toJS()
		return state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
			'report_code',
		])
	})

	const modal = visible && (
		<DeleteReportModal
			report={report}
			visible={visible}
			setVisible={setVisible}
		/>
	)

	const button = (
		<MenuButton onClick={() => setVisible(true)} icon={<TrashIcon />}>
			Eliminar {reportCode === 'market' ? 'mercado' : 'consulta'}
		</MenuButton>
	)

	return { button, modal }
}
