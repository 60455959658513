import { createActionTypes, makeActionCreator as mac } from '@loque/reducks'
import worker from '../worker'
import { asImmutable } from 'utils/immutable'
import notifications from './notifications'

const types = createActionTypes([
	'QUERY_PAGE_SET_DATA',
	'QUERY_PAGE_SET_NUMBER',
	'QUERY_PAGE_SET_LOADING',
])

const initialState = asImmutable({
	data: {},
	pageNumber: null,
	loading: false,
})

function reducer(state = initialState, action) {
	let nextState = state
	switch (action.type) {
		case types.QUERY_PAGE_SET_DATA:
			if (!action.pageData) return nextState.set('data', asImmutable({}))
			return nextState.set('data', asImmutable(action.pageData))

		case types.QUERY_PAGE_SET_NUMBER:
			return nextState.set('pageNumber', parseInt(action.pageNumber))

		case types.QUERY_PAGE_SET_LOADING:
			return nextState.set('loading', !!action.loading)

		default:
			return nextState
	}
}

const actions = {}

actions.queryPageSetData = mac(types.QUERY_PAGE_SET_DATA, 'pageData')
actions.queryPageSetNumber = mac(types.QUERY_PAGE_SET_NUMBER, 'pageNumber')
actions.queryPageSetLoading = mac(types.QUERY_PAGE_SET_LOADING, 'loading')

actions.queryPageChangeNumber = (pageNumber: number) => {
	return (dispatch: Dispatch) => {
		dispatch(actions.queryPageSetNumber(pageNumber))
		dispatch(actions.queryPageGet())
	}
}

actions.queryPageGet = (queryConfigSent = null) => {
	return (dispatch, getState) => {
		let queryConfig

		if (queryConfigSent) {
			queryConfig = queryConfigSent
		} else {
			const state = getState()

			const organizationId = state.getIn(['session', 'organizationId'])
			const serviceId = state.getIn(['session', 'serviceId'])
			const queryConfigId = state.getIn(['queries', 'queryConfigId'])

			if (organizationId !== undefined && serviceId !== undefined) {
				queryConfig = state.getIn([
					'user',
					'organizations',
					`${organizationId}`,
					'services',
					`${serviceId}`,
					'queries',
					`${queryConfigId}`,
				])
			}
		}

		dispatch(doGetQueryPage(queryConfig))
	}
}

function doGetQueryPage(queryConfig) {
	return (dispatch, getState) => {
		if (!queryConfig) return

		queryConfig = asImmutable(queryConfig)

		if (queryConfig.get('only_export') !== false) return

		const state = getState()

		const liveFilters = state.get('liveFilters').toJS()

		const queryConfigId = queryConfig.get('id')
		const queryConfigVersion = queryConfig.get('version')
		const queryConfigServiceVersion = queryConfig.get('service_version')
		const queryConfigUnidadesEquivalentes = queryConfig.get(
			'unidades_equivalentes'
		)
		const pageNumber = state.getIn(['queryPage', 'pageNumber'])

		dispatch(actions.queryPageSetLoading(true))

		worker
			.post(`/queries/${queryConfigId}/pages/${pageNumber}`, {
				liveFilters,
				queryConfigVersion,
				queryConfigServiceVersion,
				queryConfigUnidadesEquivalentes,
			})
			.then(res => {
				if (queryConfigId === res.body.queryConfigId) {
					dispatch(actions.queryPageSetData(res.body))
				} else {
					console.error('queryConfigId mismatch', {
						currState: queryConfigId,
						nextState: res.body.queryConfigId,
					})
					dispatch(actions.queryPageSetData(null))
				}
				dispatch(actions.queryPageSetLoading(false))
			})
			.catch(error => {
				dispatch(actions.queryPageSetData(null))
				dispatch(actions.queryPageSetLoading(false))
				if (error.status === 301) {
					dispatch(actions.queryPageSetData(error.body))
					return
				}

				if (error.status === 401) {
					return
				}

				console.error(
					`Error posting query-page for client processing`,
					error
				)
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al actualizar los resultados'
					)
				)
			})
	}
}

export default {
	types,
	reducer,
	actions,
}
