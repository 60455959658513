import styled, { css } from 'styled-components'

const headerStyle = css`
	text-transform: uppercase;
`

export default styled.div`
	padding-left: 1.5rem;
	${props => props.header && headerStyle}
`
