import styled, { css } from 'styled-components'
import ButtonUnstyled from '../Button/Unstyled'
import { darken, lighten } from 'polished'
import { FiUsers as UsersIcon } from 'react-icons/fi'

export const SharedIcon = styled(UsersIcon)`
	margin-left: 0.6rem;
	opacity: 0.5;
	width: 11px;
`

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0;
	list-style: none;
`

export const Row = styled.div`
	display: flex;
	margin: 0;
	list-style: none;
	gap: 1rem;
`

const HighlightedDisplay = css`
	color: ${(props) => darken(0.16, props.theme.color.accent)};
	background-color: ${(props) => lighten(0.4, props.theme.color.accent)};
	font-weight: 500;
	border-radius: 0 50% 50% 0 / 400%;
`

const sticky = css`
	position: sticky;
	background-color: #f5f5f5;
	z-index: 3;
	top: -1px;
`

export const Display = styled.div`
	padding: 4px 1.2rem 4px 0;
	width: 100%;
	display: flex;

	${(props) => props.highlighted && HighlightedDisplay}
	${(props) => props.sticky && sticky}
`

export const LabelWrapper = styled(ButtonUnstyled)`
	display: flex;
	flex: 1;
	text-align: left;
	padding-left: 20px;
	align-items: center;
`

export const Label = styled.div`
	flex: 1;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
`

export const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;

	& > *:not(:last-child) {
		margin-right: 0.5rem;
	}
`
