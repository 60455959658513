import worker from '../../worker'
import { push } from 'react-router-redux'
import { createActionTypes, makeActionCreator as mac } from '@loque/reducks'
import notifications from '../notifications'
import { addToBasePath } from '../../index'

const types = createActionTypes(['ADMIN_UPDATE_USERS'])
const actions = {}

actions.adminUpdateUsers = mac(types.ADMIN_UPDATE_USERS, 'users')

actions.adminGetUsers = () => {
	return dispatch => {
		worker
			.get('/admin/users')
			.then(res => {
				dispatch(actions.adminUpdateUsers(res.body))
			})
			.catch(res => {
				console.error(`Error fetching users`, res)
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al listar los usuarios'
					)
				)
			})
	}
}

actions.adminCreateUser = user => {
	return dispatch => {
		dispatch(
			notifications.actions.notify(
				'admin-create-user',
				'Creando usuario...'
			)
		)

		worker
			.post('/admin/users', user)
			.then(res => {
				dispatch(push(addToBasePath(`/admin/usuarios`)))
				dispatch(notifications.actions.notifyEnd('admin-create-user'))
			})
			.catch(res => {
				console.error(`Error creating the user`, res)
				dispatch(notifications.actions.notifyEnd('admin-create-user'))
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al crear el usuario'
					)
				)
			})
	}
}

actions.adminUpdateUser = (user, userId) => {
	return dispatch => {
		dispatch(
			notifications.actions.notify(
				'admin-update-user',
				'Actualizando usuario...'
			)
		)

		worker
			.put(`/admin/users/${userId}`, user)
			.then(res => {
				dispatch(notifications.actions.notifyEnd('admin-update-user'))
			})
			.catch(res => {
				console.error(`Error updating the user`, res)
				dispatch(notifications.actions.notifyEnd('admin-update-user'))
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al actualizar el usuario'
					)
				)
			})
	}
}

actions.adminDeleteUser = userId => {
	return dispatch => {
		dispatch(
			notifications.actions.notify(
				'admin-delete-user',
				'Eliminando usuario...'
			)
		)

		worker
			.delete(`/admin/users/${userId}`)
			.then(res => {
				dispatch(push(addToBasePath(`/admin/usuarios`)))
				dispatch(notifications.actions.notifyEnd('admin-delete-user'))
			})
			.catch(res => {
				console.error(`Error deleting the user`, res)
				dispatch(notifications.actions.notifyEnd('admin-delete-user'))
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al eliminar el usuario'
					)
				)
			})
	}
}

export default {
	types,
	actions,
}
