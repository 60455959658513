import { makeActionCreator as mac } from '@loque/reducks'
import types from './actionTypes'
import worker from '../../worker'
import { asImmutable } from 'utils/immutable'
import notifications from '../notifications'
import user from '../user'

const actions = {}

actions.sessionUpdateAll = mac(types.SESSION_UPDATE_ALL, 'session')
actions.sessionUpdateOnline = mac(types.SESSION_UPDATE_ONLINE, 'status')
actions.sessionUpdateLoadingService = mac(
	types.SESSION_UPDATE_LOADING_SERVICE,
	'loadingService'
)
actions.sessionUpdateLogin = mac(types.SESSION_UPDATE_LOGIN, 'status')
actions.sessionNavBarInUse = mac(types.SESSION_NAVBAR_IN_USE, 'inuse')
actions.sessionUpdateCredentialsMismatch = mac(
	types.SESSION_UPDATE_CREDENTIALS_MISMATCH,
	'status'
)
actions.toggleNavBar = mac(types.SESSION_TOGGLE_NAVBAR)

const userResumenBagoRequest = mac(types.USER_RESUMEN_BAGO_REQUEST)
actions.userResumenBagoSuccess = mac(types.USER_RESUMEN_BAGO_SUCCESS)
actions.userResumenBagoError = mac(types.USER_RESUMEN_BAGO_ERROR, 'error')

actions.userMultiExportSelect = mac(types.USER_MULTI_EXPORT_SELECT)
actions.userMultiExportCancelSelect = mac(types.USER_MULTI_EXPORT_CANCEL_SELECT)
const userMultiExportRequest = mac(types.USER_MULTI_EXPORT_REQUEST)
actions.userMultiExportProgress = mac(
	types.USER_MULTI_EXPORT_PROGRESS,
	'progress'
)
actions.userMultiExportSuccess = mac(types.USER_MULTI_EXPORT_SUCCESS)
actions.userMultiExportError = mac(types.USER_MULTI_EXPORT_ERROR, 'error')
actions.userMultiExportSetLastState = mac(
	types.USER_MULTI_EXPORT_SET_LAST_STATE,
	'state'
)

let lastLoginStatus

actions.sessionListeners = () => {
	return (dispatch, getState) => {
		worker.on('session', (res) => {
			if (lastLoginStatus === true && res.body.login === false) {
				// just logged out
				dispatch(user.actions.userLogout())
			}

			lastLoginStatus = res.body.login

			dispatch(actions.sessionUpdateAll(res.body))
		})

		worker.on('session-online', (res) => {
			dispatch(actions.sessionUpdateOnline(res.body))
		})
	}
}

actions.sessionResetLabServiceQuery = () => {
	return () => worker.delete(`/session/organization-service-query`)
}

actions.sessionSelectLabService = (
	organizationId = null,
	serviceId = null,
	opts = { resetQueryConfigId: true }
) => {
	return (_, getState) => {
		opts = asImmutable(opts)

		const state = getState()
		let org

		if (!state.get('user').has('organizations')) return

		if (typeof organizationId === 'string') {
			org = state
				.getIn(['user', 'organizations'], asImmutable({}))
				.find(
					(org) =>
						org.get('name').toUpperCase() ===
						organizationId.toUpperCase(),
					null,
					asImmutable({})
				)

			organizationId = org.get('id')
		}

		if (typeof serviceId === 'string') {
			serviceId = org
				.getIn('services', asImmutable({}))
				.find(
					(service) =>
						service.get('name').toUpperCase() ===
						serviceId.toUpperCase(),
					null,
					asImmutable({})
				)
				.get('id')
		}

		if (
			(Number.isInteger(organizationId) === false &&
				organizationId !== null) ||
			(Number.isInteger(serviceId) === false && serviceId !== null)
		) {
			console.error('invalid organizationId or serviceId', {
				organizationId,
				serviceId,
			})
			return
		}

		let endpoint = `/session/organization/${organizationId}/service/${serviceId}?`
		endpoint += `resetQueryConfigId=${!!opts.get('resetQueryConfigId')}`

		worker.post(endpoint)
	}
}

actions.sessionSelectLab = (
	organizationId,
	opts = { resetServiceId: true, resetQueryConfigId: true }
) => {
	return () => {
		opts = asImmutable(opts)

		let endpoint = `/session/organization/${organizationId}?`
		endpoint += `resetServiceId=${!!opts.get('resetServiceId')}`
		endpoint += `&`
		endpoint += `resetQueryConfigId=${!!opts.get('resetQueryConfigId')}`
		worker.post(endpoint)
	}
}

actions.sessionSelectService = (
	serviceId,
	opts = { resetQueryConfigId: true }
) => {
	return () => {
		opts = asImmutable(opts)

		let endpoint = `/session/service/${serviceId}?`
		endpoint += `resetQueryConfigId=${!!opts.get('resetQueryConfigId')}`

		worker.post(endpoint)
	}
}

actions.sessionSelectQuery = (queryConfigId) => {
	return (_, getState) => {
		const state = getState()

		if (state.getIn(['session', 'queryConfigId']) !== queryConfigId) {
			worker.post(`/session/query/${queryConfigId}`)
		}
	}
}

actions.userResumenBagoRequest = (orgId, serviceId) => {
	return (dispatch) => {
		dispatch(userResumenBagoRequest())
		worker
			.post(
				`/session/resumenBago/organization/${orgId}/service/${serviceId}`
			)
			.then(() => {
				dispatch(actions.userResumenBagoSuccess())
			})
			.catch((error) => {
				dispatch(actions.userResumenBagoError(error))
			})
	}
}

actions.userMultiExportRequest = (paths) => {
	return (dispatch) => {
		dispatch(userMultiExportRequest())
		return worker
			.post(`/queries/multi-export`, { paths })
			.then((res) => {
				dispatch(actions.userMultiExportProgress(res.body))
				setTimeout(() => {
					dispatch(actions.userMultiExportPoll())
				}, 2000)
			})
			.catch((error) => {
				dispatch(actions.userMultiExportError(error))
			})
	}
}

actions.userMultiExportPoll = () => {
	return (dispatch) => {
		return worker
			.get(`/queries/multi-export`)
			.then((res) => {
				let multi = res.body
				switch (multi.state) {
					case 'progress':
						dispatch(
							actions.userMultiExportProgress(multi.progress)
						)
						setTimeout(() => {
							dispatch(actions.userMultiExportPoll())
						}, 2000)
						break
					case 'done':
					case 'downloaded':
						dispatch(actions.userMultiExportSuccess())
						dispatch(
							actions.userMultiExportSetLastState(multi.state)
						)
						break
					case 'error':
						dispatch(actions.userMultiExportError())
						break
					case 'empty':
						dispatch(actions.userMultiExportCancelSelect())
						break
				}
			})
			.catch((error) => {
				console.error(error)
				dispatch(actions.userMultiExportError())
			})
	}
}

actions.userGetLastMultiExport = () => {
	return () => {
		return worker.get(`/queries/last-multi-export`)
	}
}

actions.userLoginRequest = mac(types.USER_LOGIN_REQUEST)
actions.userLoginSuccess = mac(types.USER_LOGIN_SUCCESS)
actions.userLoginError = mac(types.USER_LOGIN_ERROR, 'error')
actions.userLoginReset = mac(types.USER_LOGIN_RESET)

actions.userRefreshRequest = mac(types.USER_REFRESH_REQUEST)
actions.userRefreshSuccess = mac(types.USER_REFRESH_SUCCESS)
actions.userRefreshError = mac(types.USER_REFRESH_ERROR, 'error')

export default actions
