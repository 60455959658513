import React from 'react'
import styled from 'styled-components'
import Label from './Label'
import { formControls } from './common'

const InputBase = styled.input`
	${formControls}
`

const Input = React.forwardRef(
	({ id = '', name = '', label, ...props }, ref) => {
		if (id !== '' && name === '') {
			name = id
		} else if (id === '' && name !== '') {
			id = name
		}

		props.id = id
		props.name = name

		let node = <InputBase ref={ref} {...props} />

		if (label) {
			node = <Label text={label}>{node}</Label>
		}

		return node
	}
)

// We wrap the component in a styled() factory to make it eligible for interpolation
// More info: https://www.styled-components.com/docs/advanced#caveat
export default styled(Input)``
