import { isNum } from '../utils'

export default function calculateWrapperRect(
	triggerRect = {},
	anchorPoints,
	offset,
	popoverRect
) {
	const doc = document.documentElement
	const body = document.body.getBoundingClientRect()

	const top = triggerRect.top + doc.scrollTop
	const bottom = triggerRect.bottom + doc.scrollTop
	const left = triggerRect.left + doc.scrollLeft
	const right = triggerRect.right + doc.scrollLeft

	const rect = {}

	switch (anchorPoints.popover.x) {
		case 'left':
			switch (anchorPoints.trigger.x) {
				case 'left':
					rect.left = left - offset.x
					break
				case 'center':
					rect.left = left + Math.abs(triggerRect.width) / 2
					break
				case 'right':
					rect.left = right + offset.x
					break
			}
			break
		case 'right':
			switch (anchorPoints.trigger.x) {
				case 'left':
					rect.right = body.width - left + offset.x
					break
				case 'center':
					rect.right =
						body.width - right + Math.abs(triggerRect.width) / 2
					break
				case 'right':
					rect.right = body.width - right - offset.x
					break
			}
			break
		case 'center':
			const popoverHalfWidth =
				popoverRect && isNum(popoverRect.width)
					? Math.abs(popoverRect.width) / 2
					: 0
			switch (anchorPoints.trigger.x) {
				case 'left':
					rect.left = left - popoverHalfWidth - offset.x
					break
				case 'center':
					rect.left =
						left +
						Math.abs(triggerRect.width) / 2 -
						popoverHalfWidth
					break
				case 'right':
					rect.left = right - popoverHalfWidth + offset.y
					break
			}
			break
	}

	switch (anchorPoints.popover.y) {
		case 'top':
			switch (anchorPoints.trigger.y) {
				case 'top':
					rect.top = top - offset.y
					break
				case 'center':
					rect.top = top + Math.abs(triggerRect.height) / 2
					break
				case 'bottom':
					rect.top = bottom + offset.y
					break
			}
			break
		case 'bottom':
			switch (anchorPoints.trigger.y) {
				case 'top':
					rect.bottom = body.height - top + offset.y
					break
				case 'center':
					rect.bottom =
						body.height - bottom + Math.abs(triggerRect.height) / 2
					break
				case 'bottom':
					rect.bottom = body.height - bottom - offset.y
					break
			}
			break
		case 'center':
			const popoverHalfHeight =
				popoverRect && isNum(popoverRect.height)
					? Math.abs(popoverRect.height) / 2
					: 0
			switch (anchorPoints.trigger.y) {
				case 'top':
					rect.top = top - popoverHalfHeight - offset.y
					break
				case 'center':
					rect.top =
						top +
						Math.abs(triggerRect.height) / 2 -
						popoverHalfHeight
					break
				case 'bottom':
					rect.top = bottom - popoverHalfHeight + offset.y
					break
			}
			break
	}

	return rect
}
