import React, { useState, useRef, useContext } from 'react'
import { useSpring, animated } from 'react-spring'
import styled, { css } from 'styled-components'
import { lighten } from 'polished'
import { FiChevronDown as ChevronDown } from 'react-icons/fi'
import Popover from '../Popover'
import { wrapCallback } from '../utils'
import Paper from '../Paper'
import Button from '../Button'

const transition = {
	from: {
		opacity: 0,
		transform: 'translateY(-10%)',
	},
	enter: {
		opacity: 1,
		transform: 'translateY(0)',
	},
	leave: {
		opacity: 0,
		transform: 'translateY(-6%)',
	},
	config: { mass: 1, tension: 1200, friction: 60 },
}

const Trigger = styled(Button)`
	align-items: center;
`

const TriggerDisplay = styled.div`
	flex: 1;
	text-align: left;
`

const TriggerArrow = styled(animated.div)`
	display: flex;
	align-items: center;
`

const OptionsWrapper = styled(Paper).attrs({
	noHPadding: true,
	// noVPadding: true,
	elevation: 3,
})`
	display: flex;
	flex-direction: column;
	min-width: ${props => props.minWidth};
	max-height: ${props => (props.maxHeight ? props.maxHeight : 'none')};
	overflow: ${props => (props.maxHeight ? 'auto' : 'none')};
`

function OptionsPopover({
	children,
	hidePopover,
	onChange,
	triggerWidth,
	maxHeight,
	inline,
	value,
	name,
	id,
}) {
	const childrenCount = React.Children.count(children)

	const childrenWithProps = React.Children.map(
		children,
		(child, childIdx) => {
			if (child.props.separator) return child

			function onClick(event) {
				event.preventDefault()
				event.target.id = id
				event.target.name = name
				event.target.value = child.props.value // we are adding this props to make it compatible with Formik
				event.target.content = child.props.content
				if (child.props.value !== undefined) {
					onChange(event)
				}
				hidePopover(event)
			}

			return React.cloneElement(child, {
				...child.props,
				onClick: wrapCallback(child.props.onClick, onClick),
				selected: child.props.value === value,
				first: childIdx === 0,
				last: childIdx === childrenCount - 1,
			})
		}
	)

	return (
		<OptionsWrapper
			minWidth={inline ? 'auto' : `${triggerWidth}px`}
			maxHeight={maxHeight}
		>
			{childrenWithProps}
		</OptionsWrapper>
	)
}

const optionSelected = css`
	background: ${props => props.theme.color.grey};
	cursor: default;

	&:hover {
		background: ${props => props.theme.color.grey};
	}
`

const optionRegular = css`
	cursor: pointer;
	padding: 0.5em 1em;

	&:hover {
		background: ${props =>
			!props.selected && lighten(0.06, props.theme.color.grey)};
	}
`

// const optionFirst = css`
// 	border-top-left-radius: 6px;
// 	border-top-right-radius: 6px;
// `

// const optionLast = css`
// 	border-bottom-left-radius: 6px;
// 	border-bottom-right-radius: 6px;
// `

const optionsSeparator = css`
	display: block;
	width: 100%;
	height: 1px;
	min-height: 1px;
	max-height: 1px;
	background: ${props => lighten(0.06, props.theme.color.grey)};
`

const OptionBase = styled.div`
	white-space: nowrap;

	${props => !props.separator && optionRegular}
	${props => props.selected && optionSelected}
	${props => props.separator && optionsSeparator}
	/* ${props => props.first && optionFirst}
	${props => props.last && optionLast} */
`

const SelectContext = React.createContext()

export const Option = React.forwardRef(
	({ children, value, ...otherProps }, ref) => {
		const currValue = useContext(SelectContext)
		const selected = currValue === value

		return (
			<OptionBase
				ref={ref}
				value={value}
				selected={selected}
				{...otherProps}
			>
				{children}
			</OptionBase>
		)
	}
)

export default function Select({
	id = '',
	name = '',
	onChange = () => {},
	onBlur = () => {},
	value,
	placeholder,
	children,
	disabled = false,
	anchorPoints = {
		trigger: {
			x: 'center',
			y: 'bottom',
		},
		popover: {
			x: 'center',
			y: 'top',
		},
	},
	hideOnBlur = true,

	width = 'auto',
	role = 'default',
	variant = 'text',
	maxHeight = null,
	grow = false,
	style = {},
}) {
	const [open, setOpen] = useState(false)
	const [triggerWidth, setTriggerWidth] = useState()
	const triggerRef = useRef(null)

	const { transform } = useSpring({
		transform: `rotate(${open ? 180 : 0}deg)`,
		config: {
			mass: 1,
			tension: 350,
			friction: 40,
		},
	})

	let triggerDisplay = placeholder

	if (value !== null) {
		let selectedChild = React.Children.toArray(children).find(
			child => child.props.value === value
		)
		if (selectedChild) {
			triggerDisplay = selectedChild.props.children
		}
	}

	if (id !== '' && name === '') {
		name = id
	} else if (id === '' && name !== '') {
		id = name
	}

	return (
		<SelectContext.Provider value={value}>
			<Popover
				triggerAction="click"
				transition={transition}
				anchorPoints={anchorPoints}
				offset={{ x: 0, y: 0 }}
				content={
					<OptionsPopover
						value={value}
						triggerWidth={triggerWidth}
						triggerRef={triggerRef}
						onChange={onChange}
						maxHeight={maxHeight}
					>
						{children}
					</OptionsPopover>
				}
				hideOnBlur={hideOnBlur}
				onVisible={() => setOpen(true)}
				onHidden={() => {
					onBlur(triggerRef.current)
					setOpen(false)
				}}
			>
				<Trigger
					id={id}
					name={name}
					ref={node => {
						if (!node) return
						const nextWidth = node.getBoundingClientRect().width
						triggerRef.current = node
						if (triggerWidth === nextWidth) return
						setTriggerWidth(nextWidth)
					}}
					width={width}
					role={role}
					variant={variant}
					open={open}
					disabled={disabled}
					style={{
						flex: grow ? 1 : 'initial',
						...style,
					}}
				>
					<TriggerDisplay>{triggerDisplay}</TriggerDisplay>
					{!disabled && (
						<TriggerArrow style={{ transform }}>
							<ChevronDown />
						</TriggerArrow>
					)}
				</Trigger>
			</Popover>
		</SelectContext.Provider>
	)
}
