const color = {
	white: '#fff',
	whiteAlpha: 'rgba(255, 255, 255, 0.8)',

	black: '#000',
	blackAlpha: 'rgba(0, 0, 0, 0.58)',
	blackAlpha2: 'rgba(0, 0, 0, 0.6)',

	blueD1: '#318FDB',
	blue: '#03a9f4',
	blueL1: '#5BB3FB',
	blueL1Alpha: '#5BB3FB',

	greyD1: '#ccc',
	grey: '#ddd',
	greyL1: '#fafafa',
	greyL2: '#e4e4e4',
	greyL2Alpha: '#e4e4e4e6',

	redD1: '#bb5858',
	red: '#de8888',
}

color.primaryD1 = color.blueD1
color.primary = color.blue
color.primaryL1 = color.blueL1
color.primaryL1Alpha = color.blueL1Alpha

color.accent = color.blue

color.fg = color.blackAlpha
color.bg = color.greyL1

export default color
