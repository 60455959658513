import React from 'react'
import Paper from '../Paper'

export default function Menu({ children }) {
	return (
		<Paper elevation={3} noHPadding>
			{children}
		</Paper>
	)
}
