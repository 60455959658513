import styled, { css } from 'styled-components'

export const Frame = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	${props =>
		props.withBackground &&
		'background: url("/img/background-pattern.jpg");'}
`

const expandedStyle = css`
	width: 100% !important;
	height: 100% !important;
`

const noBackgroundStyle = css`
	background: none !important;
	box-shadow: none !important;
`

export const Main = styled.div`
	flex: 1;
	display: flex;
	/* max-height: 100%; */
	overflow: hidden;
	${props => props.withPadding && 'padding: 0 24px;'}
	${props => props.expanded && expandedStyle}
	${props => props.noBackground && noBackgroundStyle}
`

export const MainBody = styled.div`
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
`

export const MainModal = styled.div`
	padding: ${props => props.theme.spacing.v.lrgr} 0;
	display: flex;
	justify-content: center;
`

const formStyle = css`
	display: flex;
	justify-content: center;
`

export const Content = styled.div`
	height: 100%;
	padding: 19.2px 28px;
	overflow-x: hidden;
	overflow-y: auto;
	flex: 1;
	${props => props.modal && 'padding-top: 192px;'}
	${props => props.hasForm && formStyle}

	${Main} {
		box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2),0 1px 1px 0 rgba(0,0,0,0.14),0 2px 1px -1px rgba(0,0,0,0.12);
		border-radius: 3px;
		display: flex;
		flex-direction: column;
		background: #fff;

		width: 700px;
		height: auto;
		flex: initial;
		flex-shrink: 0;
		align-self: flex-start;
	}
`
export const ContentModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
