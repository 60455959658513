import { makeActionCreator as mac } from '@loque/reducks'
import types from './actionTypes'
import queryPage from '../queryPage'

const actions = {}

actions.filtersToggleColumn = mac(types.FILTERS_TOGGLE_COLUMN, 'dimensionsY')
const doFiltersToggleRow = mac(types.FILTERS_TOGGLE_ROW, 'cell')
const doFiltersResetAll = mac(types.FILTERS_RESET_ALL)
const doFiltersToggleSort = mac(types.FILTERS_TOGGLE_SORT, 'sorter')
const doFiltersSetSearch = mac(types.FILTERS_SET_SEARCH, 'param')

actions.filtersToggleSort = (sorter: {
	direction: string,
	cellIdx: number,
	format: string,
}) => {
	return (dispatch: Dispatch) => {
		dispatch(doFiltersToggleSort(sorter))
		dispatch(queryPage.actions.queryPageSetNumber(1))
		dispatch(queryPage.actions.queryPageGet())
	}
}

actions.filtersResetAll = () => {
	return (dispatch: Dispatch) => {
		dispatch(doFiltersResetAll())
		// dispatch(queryPage.actions.queryPageSetNumber(1))
	}
}

actions.filtersToggleRow = cell => {
	return (dispatch: Dispatch) => {
		dispatch(doFiltersToggleRow(cell))
		dispatch(queryPage.actions.queryPageSetNumber(1))
		dispatch(queryPage.actions.queryPageGet())
	}
}

actions.filtersSetSearch = param => {
	return dispatch => {
		dispatch(doFiltersSetSearch(param))
		dispatch(queryPage.actions.queryPageSetNumber(1))
		dispatch(queryPage.actions.queryPageGet())
	}
}

export default actions
