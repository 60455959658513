import React from 'react'
import styled from 'styled-components'
import ButtonUnstyled from '../Button/Unstyled'
import { FiX as CloseIcon } from 'react-icons/fi'

const Wrapper = styled.div`
	font-size: 1.4rem;
	margin-bottom: 3.4rem;
	display: flex;
	align-items: center;
`

const Text = styled.span`
	flex: 1;
`

const Button = styled(ButtonUnstyled)`
	display: flex;
	align-items: center;
`

export default function Title({ children, onHideClick = null }) {
	let closeBtn

	if (typeof onHideClick === 'function') {
		closeBtn = (
			<Button onClick={onHideClick}>
				<CloseIcon />
			</Button>
		)
	}

	return (
		<Wrapper>
			<Text>{children}</Text>
			{closeBtn}
		</Wrapper>
	)
}
