import React from 'react'

import { Content, MainModal } from '~/frontend/layout'

const NoSelection = () => {
	return (
		<Content>
			<MainModal>
				<h1>No section selected</h1>
			</MainModal>
		</Content>
	)
}

export default NoSelection
