import React from 'react'
import Notification from './Notification'
import styled from 'styled-components'
import Portal from 'ui/utils/Portal'

const Wrapper = styled.div`
	position: fixed;
	bottom: 0;
	right: 0;
	width: 340px;
	padding: 0;
`
const NotificationCenterStyle = styled.div`
	padding: 0;

	& > * {
		margin-bottom: 1rem;
	}
`
export default function NotificationCenter({
	notifications,
	onNotificationRemove = () => {},
}) {
	return (
		<Portal>
			<Wrapper>
				<NotificationCenterStyle>
					{notifications.map((notification, index) => {
						return (
							<Notification
								key={index}
								notification={notification}
								onRemove={onNotificationRemove}
							/>
						)
					})}
				</NotificationCenterStyle>
			</Wrapper>
		</Portal>
	)
}
