import { asImmutable } from 'utils/immutable'
import types from './actionTypes'

const initialState = asImmutable({})

function reducer(state = initialState, action) {
	let nextState = state

	switch (action.type) {
		case types.USER_UPDATE:
			return asImmutable(action.user)

		default:
			return nextState
	}
}

export default reducer
