import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAction } from 'utils/redux'
import ducks from 'front/ducks'

import Modal from 'ui/Modal'
import ModalTitle from 'ui/Modal/Title'
import ModalBody from 'ui/Modal/Body'
import ModalActions from 'ui/Modal/Actions'
import MenuButton from 'ui/Dropdown/MenuButton'
import Loading from 'ui/Loading'
import Button from 'ui/Button'

import { FiShare2 as ShareIcon } from 'react-icons/fi'

import AdminTable from '../Admin/AdminTable'

function ShareReportModal({ report, visible, setVisible }) {
	const [intVisible, setIntVisible] = useState(visible)
	const [users, setUsers] = useState([])

	const { targets, requestId, loading, error } = useSelector(state =>
		state.getIn(['queries', 'getShareTargets']).toJS()
	)

	const { organizationId, serviceId } = useSelector(state =>
		state.get('session').toJS()
	)

	const reportCode = useSelector(state =>
		state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
			'report_code',
		])
	)

	const getShareTargets = useAction(ducks.actions.queriesGetShareTargets)
	const resetShareTargets = useAction(
		ducks.actions.queriesGetShareTargetsReset
	)
	const updateShareTargets = useAction(ducks.actions.updateReportShareTargets)

	useEffect(() => {
		if (!visible) return

		if (
			!loading &&
			!error &&
			(!users || !users.length) &&
			requestId === null
		) {
			getShareTargets(report.id, organizationId, serviceId)
		} else if (
			targets &&
			targets.length &&
			(!users || !users.length) &&
			requestId === report.id
		) {
			setUsers(targets)
		}
	}, [
		visible,
		loading,
		error,
		requestId,
		report.id,
		users,
		targets,
		organizationId,
		serviceId,
	])

	function updateUsersAccess(userIdx, rule, nextState) {
		if (users === null || (Array.isArray(users) && !users.length)) return

		nextState = !!nextState

		// We need to clone users without the reference to it
		// because if not setUsers() will think that the state
		// hasn't changed and thus will bail out of a state
		// update.
		// This is because the State Hooks uses the Object.is
		// comparison algorithm.
		// More info:
		// https://reactjs.org/docs/hooks-reference.html#bailing-out-of-a-state-update
		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is#Description
		const nextUsers = [...users]

		if (rule === 'enabled') {
			nextUsers[userIdx][rule] = nextState

			if (nextState === false) {
				let privileges = nextUsers[userIdx].privilege
				Object.keys(privileges).map(prop => (privileges[prop] = false))
			}
		} else {
			nextUsers[userIdx].privilege[rule] = nextState
		}

		setUsers(nextUsers)
	}

	function runSecondaryAction(event) {
		event && event.preventDefault()
		setIntVisible(false)
	}

	function runPrimaryAction(event) {
		event.preventDefault()

		if (users === null || (Array.isArray(users) && !users.length)) return

		updateShareTargets(organizationId, serviceId, report.id, users)
		setIntVisible(false)
	}

	// we delay the reset so that it happens once the modal is hidden
	useEffect(
		() => () => {
			setUsers([])
			resetShareTargets()
		},
		[]
	)

	// let state
	let body
	let actions

	if (!users || !users.length) {
		// state = 1
		body = <Loading>Cargando usuarios...</Loading>
	} else if (loading) {
		// loading
		// state = 1
		body = <Loading>Procesando solicitud...</Loading>
	} else if (error) {
		// error
		// state = 2
		body = (
			<p>
				Tuvimos un error al mover{' '}
				{reportCode === 'market' ? 'el mercado' : 'la consulta'}.
			</p>
		)
	} else if (users && users.length) {
		const tableBody = users.map((user, userIdx) => {
			const enabledCheckbox = (
				<input
					type="checkbox"
					value={true}
					checked={user.enabled}
					onChange={() =>
						updateUsersAccess(userIdx, 'enabled', !user.enabled)
					}
				/>
			)

			const editRegularCheckbox = (
				<input
					type="checkbox"
					value={true}
					checked={!!user.privilege.edit_regular}
					disabled={!user.enabled}
					onChange={() =>
						updateUsersAccess(
							userIdx,
							'edit_regular',
							!user.privilege.edit_regular
						)
					}
				/>
			)

			const editBlockedCheckbox = (
				<input
					type="checkbox"
					value={true}
					checked={!!user.privilege.edit_blocked}
					disabled={!user.enabled}
					onChange={() =>
						updateUsersAccess(
							userIdx,
							'edit_blocked',
							!user.privilege.edit_blocked
						)
					}
				/>
			)

			const shareCheckbox = (
				<input
					type="checkbox"
					value={true}
					checked={!!user.privilege.share}
					disabled={!user.enabled}
					onChange={() =>
						updateUsersAccess(
							userIdx,
							'share',
							!user.privilege.share
						)
					}
				/>
			)

			return [
				{ dimension: 'user', value: user.email },
				{ value: enabledCheckbox, action: true },
				{ value: editRegularCheckbox, action: true },
				{ value: editBlockedCheckbox, action: true },
				{ value: shareCheckbox, action: true },
			]
		})

		body = (
			<AdminTable
				header={[
					[
						{ dimension: 'user', value: 'Usuario' },
						{ value: 'Habilitar' },
						{ value: 'Editar campos regulares' },
						{ value: 'Editar campos bloqueados' },
						{ value: 'Compartir' },
					],
				]}
				body={tableBody}
				defaultSortDim={'user'}
			/>
		)

		actions = (
			<ModalActions>
				<Button role="secondary" onClick={runSecondaryAction}>
					Cancelar
				</Button>
				<Button
					role="primary"
					onClick={runPrimaryAction}
					// disabled={disablePrimaryAction}
				>
					Actualizar
				</Button>
			</ModalActions>
		)
	}

	return (
		<Modal visible={intVisible} onDestroyed={setVisible}>
			<ModalTitle>
				Compartir
				{reportCode === 'market' ? ' el mercado ' : ' la consulta '}
				{report.name && <i>{report.name}</i>}
			</ModalTitle>
			<ModalBody>{body}</ModalBody>
			{actions}
		</Modal>
	)
}

export default function useShareReport(report) {
	const [visible, setVisible] = useState(false)

	const reportCode = useSelector(state => {
		const { organizationId, serviceId } = state.get('session').toJS()
		return state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
			'report_code',
		])
	})

	if (report.folder_id !== null && report.folder_id !== undefined) {
		// cannot share reports inside folder
		return { button: null, modal: null }
	}

	const modal = visible && (
		<ShareReportModal
			report={report}
			visible={visible}
			setVisible={setVisible}
		/>
	)

	const button = (
		<MenuButton onClick={() => setVisible(true)} icon={<ShareIcon />}>
			Compartir {reportCode === 'market' ? 'mercado' : 'consulta'}
		</MenuButton>
	)

	return { button, modal }
}
