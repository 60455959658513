import { asImmutable } from 'utils/immutable'
import types from './actionTypes'

export const initialState = asImmutable({
	rowFilters: [],
	sort: null,
	search: '',
	loading: false,
})

function reducer(state = initialState, action) {
	switch (action.type) {
		case types.FILTERS_TOGGLE_ROW:
			const filterIdx = state.get('rowFilters').findIndex(filter => {
				return filter.get('dimension') === action.cell.get('dimension')
			})

			let rowFilters

			if (filterIdx >= 0) {
				rowFilters = state.get('rowFilters').delete(filterIdx)
			} else {
				rowFilters = state.get('rowFilters').push(action.cell)
			}

			rowFilters = rowFilters.sort((a, b) => {
				if (a.get('concept') !== b.get('concept')) {
					if (a.get('concept') < b.get('concept')) return -1
					if (a.get('concept') > b.get('concept')) return 1
				}

				if (a.get('dimension') !== b.get('dimension')) {
					if (a.get('dimension') < b.get('dimension')) return -1
					if (a.get('dimension') > b.get('dimension')) return 1
				}

				if (a.get('value') !== b.get('value')) {
					if (a.get('value') < b.get('value')) return -1
					if (a.get('value') > b.get('value')) return 1
				}

				// equal
				return 0
			})

			return state.set('rowFilters', rowFilters)

		case types.FILTERS_RESET_ALL:
			return initialState

		case types.FILTERS_TOGGLE_SORT:
			return state.set('sort', asImmutable(action.sorter))

		case types.FILTERS_SET_SEARCH:
			let param = action.param || ''
			param += ''
			return state.set('search', param.toLowerCase())

		default:
			return state
	}
}

export default reducer
