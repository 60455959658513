import { useState, useEffect } from 'react'

export default function useAutoFocus(visible, ref) {
	const [focused, setFocused] = useState(false)

	useEffect(() => {
		if (visible && ref.current && !focused) {
			if (ref.current.tabIndex === -1) {
				ref.current.tabIndex = 0
			}
			ref.current.focus()
			setFocused(true)
		} else if (!visible && ref.current && focused) {
			setFocused(false)
		}
	}, [visible, ref.current, focused])
}
