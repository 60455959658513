import React from 'react'
import { useSelector } from 'react-redux'

import { asImmutable } from 'utils/immutable'

import ducks from 'front/ducks'
import { useAction } from '~/modules/utils/redux'

import useShareReport from './useShareReport'
import useMoveReportToFolder from './useMoveReportToFolder'
import useMoveReportOutOfFolder from './useMoveReportOutOfFolder'
import useDeleteReport from './useDeleteReport'
import useDuplicateReport from './useDuplicateReport'

import CreateFolderModal from './CreateFolderModal'

import useRenameFolder from './useRenameFolder'
import useDeleteFolder from './useDeleteFolder'
import useShareFolder from './useShareFolder'

import Dropdown from 'ui/Dropdown'
import Menu from 'ui/Dropdown/Menu'
import MenuButton from 'ui/Dropdown/MenuButton'
import ButtonUnstyled from 'ui/Button/Unstyled'
import List from 'ui/FileList/List'
import {
	FiPlus as PlusIcon,
	FiMoreHorizontal as MenuHorizontalIcon,
	FiEdit as EditIcon,
	FiEye as EyeIcon,
} from 'react-icons/fi'
import Button from 'ui/Button'
import ButtonGroup from 'ui/Button/Group'

import NavBarSection from 'ui/NavBar/NavBarSection'
import NavBarSeparator from 'ui/NavBar/NavBarSeparator'
import NavBarItem from 'ui/NavBar/NavBarItem'

import produce from 'immer'
import Loading from 'ui/Loading'

export default function ReportsList({
	reportCode,
	queries,
	queryConfigId,
	selectMode,
	setSelected,
}) {
	const folders = useSelector((state) => {
		const organizationId = state.getIn(['session', 'organizationId'])
		const serviceId = state.getIn(['session', 'serviceId'])
		return asImmutable(
			state.getIn([
				'user',
				'organizations',
				`${organizationId}`,
				'services',
				`${serviceId}`,
				'folders',
			]),
			[],
			false
		)
	})

	const loadingService = useSelector((state) =>
		state.getIn(['session', 'loadingService'])
	)

	const navToQueryNew = useAction(ducks.actions.navToQueryNew)
	const navToQuery = useAction(ducks.actions.navToQuery)

	const tree = folders
		.sortBy((f) => f.get('name'))
		.map((f) => {
			f = f.set('type', 'folder')
			f = f.set('label', f.get('name'))
			return f
		})
		.map((f) =>
			f.set(
				'children',
				queries
					.filter((query) => query.get('folder_id') === f.get('id'))
					.map((q) => {
						q = q.set('type', 'file')
						q = q.set('label', q.get('name'))
						return q
					})
					.toList()
			)
		)
		.concat(
			queries
				.filter(
					(q) =>
						!q.has('folder_id') ||
						q.get('folder_id') === null ||
						q.get('folder_id') === undefined ||
						!folders.find((f) => f.get('id') === q.get('folder_id'))
				)
				.map((q) => {
					q = q.set('type', 'file')
					q = q.set('label', q.get('name'))
					return q
				})
				.toList()
		)
		.toJS()

	const newReportClick = (event) => {
		event.preventDefault()
		navToQueryNew()
	}

	const showList = !loadingService || !!tree.length

	return (
		<React.Fragment>
			<NavBarSection>
				<NavBarItem>
					<ButtonGroup even>
						<Button
							role="primary"
							title="Crear un nuevo mercado"
							onClick={newReportClick}
						>
							<PlusIcon />
							{reportCode === 'market' ? 'Mercado' : 'Consulta'}
						</Button>
						<CreateFolderModal>
							<Button
								title={`Crear una nueva ${
									reportCode === 'market'
										? 'línea'
										: 'carpeta'
								}`}
							>
								<PlusIcon />
								{reportCode === 'market' ? 'Línea' : 'Carpeta'}
							</Button>
						</CreateFolderModal>
					</ButtonGroup>
				</NavBarItem>
			</NavBarSection>
			<NavBarSeparator />
			<NavBarSection>
				<NavBarItem header>
					{reportCode === 'market'
						? 'Mercados guardados'
						: 'Consultas guardadas'}
				</NavBarItem>
			</NavBarSection>
			<NavBarSeparator />

			<NavBarSection style={{ flex: 1, paddingTop: 0, overflow: 'auto' }}>
				{!showList && (
					<NavBarItem>
						<Loading
							style={{
								borderColor: 'rgba(0,0,0,0.4)',
								width: '16px',
								height: '16px',
							}}
						>
							Cargando servicio...
						</Loading>
					</NavBarItem>
				)}
				{showList && (
					<List
						selectMode={selectMode}
						highlighted={queryConfigId}
						initialTree={mapTree(tree, navToQuery, navToQueryNew, {
							reportCode,
						})}
						onSelect={setSelected}
					></List>
				)}
			</NavBarSection>
		</React.Fragment>
	)
}

function FolderButtons({
	isHovering,
	folder,
	queries,
	navToQueryNew,
	reportCode,
}) {
	const folderShare = useShareFolder(folder.id, folder.label)
	const folderRename = useRenameFolder(folder.id, folder.label)
	const folderDelete = useDeleteFolder(folder.id, folder.label, queries)

	const canShare = folder.privilege && folder.privilege.change_queries
	const canRename = folder.privilege && folder.privilege.rename_folder
	const canDelete = folder.privilege && folder.privilege.delete_folder

	if (!canShare && !canRename && !canDelete) return null

	return (
		<React.Fragment>
			{isHovering && (
				<ButtonUnstyled
					title={`Crear ${
						reportCode === 'market' ? 'un mercado' : 'una consulta'
					} dentro de la ${
						reportCode === 'market' ? 'línea' : 'carpeta'
					}`}
					style={{ display: 'flex', alignItems: 'center' }}
					onClick={() => {
						navToQueryNew({
							state: {
								folder: {
									id: folder.id,
									name: folder.label,
								},
							},
						})
					}}
				>
					<PlusIcon />
				</ButtonUnstyled>
			)}
			<Dropdown
				content={
					<Menu>
						{canShare && folderShare.button}
						{canRename && folderRename.button}
						{canDelete && folderDelete.button}
					</Menu>
				}
			>
				<ButtonUnstyled
					style={{ display: 'flex', alignItems: 'center' }}
				>
					<MenuHorizontalIcon />
				</ButtonUnstyled>
			</Dropdown>
			{canShare && folderShare.modal}
			{canRename && folderRename.modal}
			{canDelete && folderDelete.modal}
		</React.Fragment>
	)
}

function FileButtons({ report, folder }) {
	const reportDelete = useDeleteReport(report)
	const reportMoveToFolder = useMoveReportToFolder(
		report.id,
		report.label,
		report.folder_id
	)
	const reportMoveOutOfFolder = useMoveReportOutOfFolder(
		report.id,
		report.label,
		report.folder_id
	)
	const reportShare = useShareReport(report)
	const reportDuplicate = useDuplicateReport(report.id, folder && folder.id)
	const navToQueryEdit = useAction(ducks.actions.navToQueryEdit)

	const canEdit =
		report.privilege.edit_blocked || report.privilege.edit_regular

	const canShare = report.privilege.share
	const canChange =
		!folder || (folder.privilege && folder.privilege.change_queries)

	// NOTE: we use custom hooks that return the corresponding button and modal
	// for each option instead of a component that renders both because we need
	// to render the button inside the Dropdown and the modal out of so that we
	// can keep the modal mounted even when the Dropdown is closed

	return (
		<React.Fragment>
			<Dropdown
				content={
					<Menu>
						<MenuButton
							icon={canEdit ? <EditIcon /> : <EyeIcon />}
							onClick={() => navToQueryEdit(report.id)}
						>
							{canEdit
								? 'Editar configuración'
								: 'Ver configuración'}
						</MenuButton>
						{canShare && reportShare.button}
						{reportMoveToFolder.button}
						{reportMoveOutOfFolder.button}
						{reportDuplicate.button}
						{canChange && reportDelete.button}
					</Menu>
				}
			>
				<ButtonUnstyled
					style={{ display: 'flex', alignItems: 'center' }}
				>
					<MenuHorizontalIcon />
				</ButtonUnstyled>
			</Dropdown>
			{canShare && reportShare.modal}
			{reportMoveToFolder.modal}
			{reportMoveOutOfFolder.modal}
			{reportDuplicate.modal}
			{canChange && reportDelete.modal}
		</React.Fragment>
	)
}

function mapTree(tree, navToQuery, navToQueryNew, props = {}, folder = null) {
	return produce(tree, (draft) =>
		draft.sort((a, b) => {
			// place folders on top
			if (
				(a.type === 'folder' && b.type === 'folder') ||
				(a.type === 'file' && b.type === 'file')
			) {
				const nameA = a.label.toUpperCase()
				const nameB = b.label.toUpperCase()
				if (nameA < nameB) {
					return -1
				}
				if (nameA > nameB) {
					return 1
				}
			} else if (a.type === 'folder' && b.type === 'file') {
				return -1
			} else if (a.type === 'file' && b.type === 'folder') {
				return 1
			}
			return 0
		})
	).map((item) => {
		if (item.type === 'folder') {
			item.buttons = (_props) => (
				<FolderButtons
					{..._props}
					{...props}
					folder={item}
					navToQueryNew={navToQueryNew}
				/>
			)
			if (!item.hasOwnProperty('children')) {
				item.children = []
			}
			// TODO: shared
			item.children = mapTree(
				item.children,
				navToQuery,
				navToQueryNew,
				props,
				folder
			)
		} else {
			item.onClick = () => navToQuery(item.id)
			item.buttons = (_props) => (
				<FileButtons
					{..._props}
					{...props}
					report={item}
					folder={folder}
				/>
			)
			// TODO: shared={item.is_shared}
		}
		return item
	})
}
