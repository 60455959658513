import React from 'react'
import styled from 'styled-components'
import Loading from 'ui/Loading'

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

export default function Authenticating() {
	return (
		<Wrapper>
			<Loading size={26}>Cargando aplicación...</Loading>
		</Wrapper>
	)
}
