import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import NotificationCenter from '../components/NotificationCenter/NotificationCenter'

import { ThemeProvider } from 'styled-components'
import lightTheme from 'ui/Theme/light'
import GlobalStyle from 'ui/Theme/GlobalStyle'

import ducks from '../ducks'
import { useAction } from '~/modules/utils/redux'
import Authenticating from './Authenticating'

export default function App({ children }) {
	const [userRequested, setUserRequested] = useState(false)
	const notifications = useSelector(state => state.get('notifications'))
	const { success, loading, error } = useSelector(state =>
		state.getIn(['session', 'userRefresh']).toJS()
	)

	const userRefresh = useAction(ducks.actions.userRefresh)
	const notifyEnd = useAction(ducks.actions.notifyEnd)
	const userLogout = useAction(ducks.actions.userLogout)

	useEffect(() => {
		if (!userRequested && !loading) {
			userRefresh()
			setUserRequested(true)
		}
	}, [userRequested, loading])

	let body
	if (error) {
		body = (
			<div>
				Tuvimos un error, intentá{' '}
				<a
					href=""
					onClick={e => {
						e.preventDefault()
						userLogout().then(() => window.location.reload(true))
					}}
				>
					actualizar la página
				</a>
				.
			</div>
		)
	} else if (success) {
		body = children
	} else {
		body = <Authenticating />
	}

	return (
		<ThemeProvider theme={lightTheme}>
			<React.Fragment>
				{body}
				<NotificationCenter
					notifications={notifications.toJS()}
					onNotificationRemove={notifyEnd}
				/>
				<GlobalStyle />
			</React.Fragment>
		</ThemeProvider>
	)
}
