import { asImmutable } from 'utils/immutable'

const initialState = asImmutable({
	global: {
		no_lab: 'Seleccione un laboratorio',
		no_service: 'Seleccione un servicio',
	},
	query: {
		label_new: 'Nueva consulta',
		no_report: 'Cree una nueva consulta o seleccione una de la lista',
	},
	market: {
		label_new: 'Nuevo mercado',
		no_report: 'Cree un nuevo mercado o seleccione uno de la lista',
	},
})

export default {
	reducer: () => initialState,
}
