export default function promise() {
	let _resolver,
		_rejector,
		_promise = new Promise((resolve, reject) => {
			_resolver = resolve
			_rejector = reject
		})

	_promise.resolve = _resolver
	_promise.reject = _rejector

	return _promise
}

const actions = {}

export function uniqueAction(id, action, reject = false) {
	if (actions.hasOwnProperty(id)) {
		if (reject) return reject
		return actions[id].promise
	}

	const _p = promise()

	actions[id] = {
		promise: _p,
	}

	action()
		.then(_p.resolve)
		.then(_p.reject)
		.catch(_p.reject)

	return _p
}
