import worker from '../worker'
import { asImmutable } from 'utils/immutable'

import lab from './admin/lab'
import provider from './admin/provider'
import session from './admin/session'
import service from './admin/service'
import serviceStructure from './admin/serviceStructure'
import user from './admin/user'

const initialState = asImmutable({
	labs: [],
	providers: {},
	users: [],
	user: {},
	services: [],
	serviceDimensions: [],
})

function reducer(state = initialState, action) {
	switch (action.type) {
		case lab.types.ADMIN_UPDATE_LABS:
			return state.set('labs', asImmutable(action.labs))

		case provider.types.ADMIN_UPDATE_PROVIDERS:
			return state.set('providers', asImmutable(action.providers))

		case user.types.ADMIN_UPDATE_USERS:
			return state.set('users', asImmutable(action.users))

		// case ADMIN_UPDATE_USER:
		// 	return state.set('user', asImmutable(action.user))

		case service.types.ADMIN_UPDATE_SERVICES:
			return state.set('services', asImmutable(action.services))

		case serviceStructure.types.ADMIN_UPDATE_SERVICE_DIMENSIONS:
			return state.set(
				'serviceDimensions',
				asImmutable(action.serviceDimensions)
			)

		default:
			return state
	}
}

const actions = {}

actions.adminGetAllLists = () => {
	return dispatch => {
		dispatch(lab.actions.adminGetLabs())
		dispatch(provider.actions.adminGetProviders())
		dispatch(service.actions.adminGetServices())
		dispatch(user.actions.adminGetUsers())
	}
}

actions.adminListeners = () => {
	return dispatch => {
		worker.on('admin/labs', res => {
			dispatch(lab.actions.adminUpdateLabs(res.body))
		})

		worker.on('admin/providers', res => {
			dispatch(provider.actions.adminUpdateProviders(res.body))
		})

		worker.on('admin/services', res => {
			dispatch(service.actions.adminUpdateServices(res.body))
		})

		worker.on('admin/serviceStructures', res => {
			dispatch(
				serviceStructure.actions.adminUpdateServiceStructure(res.body)
			)
		})

		worker.on('admin/users', res => {
			dispatch(user.actions.adminUpdateUsers(res.body))
		})

		worker.on('admin/session', res => {
			dispatch(session.actions.adminSessionUpdateAll(res.body))
		})
	}
}

export default {
	reducer,
	actions,
}
