import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ducks from '../ducks'

import ReportsNav from '../components/Reports/ReportsNav'
import FadeIn from '~/frontend/ui/FadeIn'

import { Frame, Main } from '../layout'

class Reports extends React.Component {
	state = {
		labName: undefined,
		serviceName: undefined,
		lastLabId: null,
		lastServiceId: null,
	}

	updatingSession = false

	requireAuthentication = (login) => {
		if (!login) {
			this.props.navToLogin()
		}
	}

	checkLocation = (props) => {
		const session = JSON.stringify({
			labName: props.labName,
			serviceName: props.serviceName,
		})

		const state = JSON.stringify({
			labName: this.state.labName,
			serviceName: this.state.serviceName,
		})

		const currLocation = JSON.stringify({
			labName: props.params.labName,
			serviceName: props.params.serviceName,
		})

		if (session === state && state === currLocation) return

		// if session and state differ
		// then navigate to where the session dictates
		if (session !== state) {
			this.setState({
				labName: props.labName,
				serviceName: props.serviceName,
			})

			this.updatingSession = false
			this.props.navToLabService()
		}
		// if currLocation (props.params) and session differ
		// then update session to reflect currLocation changes
		else if (currLocation !== session && this.updatingSession === false) {
			this.updatingSession = true
			this.props.sessionSelectLabService(
				props.params.labName,
				props.params.serviceName
			)
		}
	}

	requireFormEntities = (props) => {
		const stateHasChanged =
			this.state.lastLabId !== props.organizationId ||
			this.state.lastServiceId !== props.serviceId

		const propsAreNotNull =
			props.organizationId !== null && props.serviceId !== null

		if (stateHasChanged && propsAreNotNull && props.serviceName) {
			props.dimensionsFetch(props.serviceName.toLowerCase())

			this.setState({
				lastLabId: props.organizationId,
				lastServiceId: props.serviceId,
			})
		}
	}

	componentDidMount() {
		this.requireAuthentication(this.props.login)
		this.checkLocation(this.props)
		this.requireFormEntities(this.props)
	}

	componentWillReceiveProps(nextProps) {
		this.requireAuthentication(nextProps.login)
		this.checkLocation(nextProps)
		this.requireFormEntities(nextProps)
	}

	render() {
		if (this.props.login !== true) return null

		let hideReportsList = true

		if (
			this.props.labName !== null &&
			this.props.labName !== undefined &&
			this.props.serviceName !== null &&
			this.props.serviceName !== undefined
		) {
			hideReportsList = false
		}

		return (
			<FadeIn key="Reports" wrapper={Frame}>
				<Main>
					<ReportsNav hideReportsList={hideReportsList} />
					{this.props.children}
				</Main>
			</FadeIn>
		)
	}
}

const mapStateToProps = (state) => {
	const login = state.getIn(['session', 'login'])

	const organizationId = state.getIn(['session', 'organizationId'], null)
	const serviceId = state.getIn(['session', 'serviceId'], null)

	const labName = state
		.getIn(['user', 'organizations', `${organizationId}`, 'name'], '')
		.toUpperCase()
	const serviceName = state
		.getIn(
			[
				'user',
				'organizations',
				`${organizationId}`,
				'services',
				`${serviceId}`,
				'name',
			],
			''
		)
		.toUpperCase()

	return {
		login,
		labName: labName === '' ? undefined : labName,
		serviceName: serviceName === '' ? undefined : serviceName,
		organizationId,
		serviceId,
		queryList: state.get('queryList'),
	}
}

const mapDispatchToProps = {
	navToLogin: ducks.actions.navToLogin,
	navToLabService: ducks.actions.navToLabService,
	sessionSelectLabService: ducks.actions.sessionSelectLabService,
	dimensionsFetch: ducks.actions.dimensionsFetch,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reports))
