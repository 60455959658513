import styled from 'styled-components'

export default styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;

	padding-left: ${props => !props.noPadding && props.theme.spacing.h.smr};
	padding-right: ${props => !props.noPadding && props.theme.spacing.h.smr};
	padding-bottom: ${props => !props.noPadding && props.theme.spacing.v.smst};

	& > *:not(:last-child) {
		margin-right: 1rem;
	}
`
