import { createActionTypes } from '@loque/reducks'

export default createActionTypes([
	'SESSION_UPDATE_ALL',
	'SESSION_UPDATE_ONLINE',
	'SESSION_UPDATE_LOGIN',
	'SESSION_UPDATE_LOADING_SERVICE',
	'SESSION_NAVBAR_IN_USE',
	'SESSION_UPDATE_CREDENTIALS_MISMATCH',
	'SESSION_TOGGLE_NAVBAR',

	'USER_RESUMEN_BAGO_REQUEST',
	'USER_RESUMEN_BAGO_SUCCESS',
	'USER_RESUMEN_BAGO_ERROR',

	'USER_MULTI_EXPORT_SELECT',
	'USER_MULTI_EXPORT_CANCEL_SELECT',
	'USER_MULTI_EXPORT_REQUEST',
	'USER_MULTI_EXPORT_PROGRESS',
	'USER_MULTI_EXPORT_SUCCESS',
	'USER_MULTI_EXPORT_ERROR',
	'USER_MULTI_EXPORT_SET_LAST_STATE',

	'USER_LOGIN_REQUEST',
	'USER_LOGIN_SUCCESS',
	'USER_LOGIN_ERROR',
	'USER_LOGIN_RESET',

	'USER_REFRESH_REQUEST',
	'USER_REFRESH_SUCCESS',
	'USER_REFRESH_ERROR',
])
