import { asImmutable } from 'utils/immutable'
import types from './actionTypes'

export const initialState = asImmutable({
	name: '',
	config: {
		blocked: {
			all: false,
			name: false,
			columns: false,
			rows: false,
			filters: false,
		},
		columns: [
			{ type: 'measure', fields: [] },
			{ type: 'period', fields: [] },
		],
		rows: [],
		filters: [],
		sort: [],
		date: {
			field_name: 'fecha',
			name: 'Fecha',
		},
		add_last_price: false,
	},
	favorite: false,
	isEmpty: true,
	privilege: {
		edit_regular: true,
		edit_blocked: true,
		share: true,
	},
	folder_id: null,
})

function reducer(state = initialState, action) {
	let targetSlotIdx
	let nextState = state

	switch (action.type) {
		case types.QUERY_TMP_LOAD:
			if (action.query === undefined) return initialState

			return asImmutable(action.query)

		case types.QUERY_TMP_SET_BLOCKED:
			return nextState.setIn(
				['config', 'blocked', `${action.key}`],
				action.value
			)

		case types.QUERY_TMP_SET_DATEFIELD:
			const res = nextState.setIn(
				['config', 'date'],
				asImmutable(action.date)
			)
			return res

		case types.QUERY_TMP_NAME_UPDATE:
			return nextState.update('name', name => action.name)

		case types.QUERY_TMP_FAV_TOGGLE:
			return nextState.update('favorite', favorite => !favorite)

		// SLOTS

		// action { axis, slotType, firstField }
		case types.QUERY_TMP_DIMENSION_ADD_SLOT:
			return nextState.updateIn(['config', action.axis], slotList =>
				slotList.push(
					asImmutable({
						type: action.slotType,
						fields: [action.firstField],
					})
				)
			)

		// action { axis, slotIndex }
		case types.QUERY_TMP_DIMENSION_REMOVE_SLOT:
			return nextState.deleteIn(['config', action.axis, action.slotIndex])

		// action { axis, slots }
		case types.QUERY_TMP_DIMENSION_UPDATE_SLOTS:
			return nextState.updateIn(['config', action.axis], () =>
				asImmutable(action.slots)
			)

		// action { axis, slots, state }
		case types.QUERY_TMP_DIMENSION_UPDATE_SLOT_BLOCK:
			return nextState.setIn(
				['config', action.axis, action.slotIndex, 'blocked'],
				action.blocked
			)

		// SLOT-FIELDS

		case types.QUERY_TMP_INVERT_PERIOD_MEASURE:
			nextState = nextState.updateIn(['config', 'columns'], columns => {
				return columns.sort((slotA, slotB) => {
					if (
						(slotA.get('type') !== 'period' &&
							slotA.get('type') !== 'measure') ||
						(slotB.get('type') !== 'period' &&
							slotB.get('type') !== 'measure')
					) {
						// one of the slots is neither period or measure
						return 0
					}
					return 1
				})
			})

			return nextState

		// action { axis, slotType, newField }
		case types.QUERY_TMP_DIMENSION_ADD_FIELD:
			targetSlotIdx = nextState
				.getIn(['config', action.axis])
				.findIndex(slot => slot.get('type') === action.slotType)

			return nextState.updateIn(
				['config', action.axis, targetSlotIdx, 'fields'],
				fields => fields.push(asImmutable(action.newField))
			)

		// action { axis, slotType, fieldIndex }
		case types.QUERY_TMP_DIMENSION_REMOVE_FIELD:
			targetSlotIdx = nextState
				.getIn(['config', action.axis])
				.findIndex(slot => slot.get('type') === action.slotType)

			return nextState.updateIn(
				['config', action.axis, targetSlotIdx, 'fields'],
				fields => fields.delete(action.fieldIndex)
			)

		// action { axis, slotIndex, fields }
		case types.QUERY_TMP_DIMENSION_UPDATE_FIELDS:
			return nextState.updateIn(
				['config', action.axis, action.slotIndex, 'fields'],
				fields => asImmutable(action.fields)
			)

		case types.QUERY_TMP_DIMENSION_UPDATE_BLOCK:
			return nextState.setIn(
				['config', action.axis, action.slotIndex, 'blocked'],
				action.blocked
			)

		// FILTERS

		// action { filter }
		case types.QUERY_TMP_FILTER_ADD:
			return nextState.updateIn(['config', 'filters'], filterList =>
				filterList.push(asImmutable(action.filter))
			)

		// action { filterIndex, filterContent }
		case types.QUERY_TMP_FILTER_UPDATE:
			return nextState.updateIn(
				['config', 'filters', action.filterIndex],
				_ => asImmutable(action.filterContent)
			)

		// action { filterIndex }
		case types.QUERY_TMP_FILTER_REMOVE:
			return nextState.deleteIn(['config', 'filters', action.filterIndex])

		// action { filterIndex, filterContent, blocked }
		case types.QUERY_TMP_FILTER_UPDATE_BLOCK:
			return nextState.setIn(
				['config', 'filters', action.filterIndex, 'blocked'],
				action.blocked
			)

		// PARAMETERS

		// action { filterIndex, dimension }
		case types.QUERY_TMP_FILTER_SET_DIMENSION:
			return nextState.updateIn(
				['config', 'filters', action.filterIndex],
				filter => filter.set('dimension', asImmutable(action.dimension))
			)

		// action { filterIndex, include }
		case types.QUERY_TMP_FILTER_SET_INCLUDE:
			return nextState.updateIn(
				['config', 'filters', action.filterIndex],
				filter => filter.set('include', asImmutable(action.include))
			)

		// action { filterIndex,  parameter}
		case types.QUERY_TMP_FILTER_ADD_PARAMETER:
			return nextState.updateIn(
				['config', 'filters', action.filterIndex, 'parameters'],
				parameterList => parameterList.push(action.parameter)
			)

		// action { filterIndex, parameterIndex }
		case types.QUERY_TMP_FILTER_REMOVE_PARAMETER:
			return nextState.updateIn(
				['config', 'filters', action.filterIndex, 'parameters'],
				parameterList => parameterList.delete(action.parameterIndex)
			)

		// action { filterIndex, parameters }
		case types.QUERY_TMP_FILTER_UPDATE_PARAMETERS:
			return nextState.updateIn(
				['config', 'filters', action.filterIndex, 'parameters'],
				() => asImmutable(action.parameters)
			)

		// action { folderId }
		case types.QUERY_TMP_SET_FOLDER_ID:
			return nextState.set('folder_id', action.folderId)

		// action { folderId }
		case types.QUERY_TMP_SET_ADD_LAST_PRICE:
			return nextState.setIn(
				['config', 'add_last_price'],
				!!action.add_last_price
			)

		default:
			return nextState
	}
}

export default reducer
