import React, { useEffect } from 'react'
import ducks from '../ducks'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router'

import VerticalGroup from 'ui/Button/VerticalGroup'
import Select, { Option } from 'ui/Select'
import { asImmutable } from 'utils/immutable'
import { useAction } from '~/modules/utils/redux'

function LabServicePicker() {
	const organizations = useSelector((state) =>
		state.getIn(['user', 'organizations']).toList()
	)
	const organizationId = useSelector((state) =>
		state.getIn(['session', 'organizationId'])
	)
	const serviceId = useSelector((state) =>
		state.getIn(['session', 'serviceId'])
	)
	const loadingService = useSelector((state) =>
		state.getIn(['session', 'loadingService'])
	)
	const serviceFactsTableName = useSelector((state) =>
		state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
			'facts_table_name',
		])
	)
	const fromIDB = useSelector((state) =>
		state.getIn(
			[
				'user',
				'organizations',
				`${organizationId}`,
				'services',
				`${serviceId}`,
				'from_idb',
			],
			false
		)
	)
	const sessionSelectLab = useAction(ducks.actions.sessionSelectLab)
	const sessionSelectService = useAction(ducks.actions.sessionSelectService)
	const userGetService = useAction(ducks.actions.userGetService)

	useEffect(() => {
		if (
			organizationId !== null &&
			organizationId !== undefined &&
			serviceId !== null &&
			serviceId !== undefined &&
			(!serviceFactsTableName || fromIDB) &&
			!loadingService
		) {
			// if service is not being requested
			userGetService(organizationId, serviceId)
		}
	}, [
		organizationId,
		serviceId,
		userGetService,
		serviceFactsTableName,
		loadingService,
		fromIDB,
	])

	const orgSelectOptions = organizations
		.sortBy((org) => org.get('name'))
		.map((org) => {
			return (
				<Option key={org.get('id')} value={org.get('id')}>
					<div>{org.get('name')}</div>
				</Option>
			)
		})

	let serviceSelect = null

	if (
		organizationId !== null &&
		organizationId !== undefined &&
		organizations.size
	) {
		const organization = organizations.find(
			(org) => org.get('id') === organizationId,
			null,
			asImmutable({})
		)
		const services = organization
			.get('services', asImmutable({}))
			.toList()
			.toJS()

		const serviceOptions = services
			.sort((servA, servB) => (servA.name > servB.name ? 1 : -1))
			.map((service) => {
				if (!service.name) return null

				const serviceName = service.name.toUpperCase()

				return (
					<Option key={service.id} value={service.id}>
						<div>{serviceName}</div>
						<div
							style={{
								height: '1.2em',
								fontSize: '.86em',
								opacity: '.8',
							}}
						>
							Datos: {service.last_update}
						</div>
					</Option>
				)
			})

		const serviceSelectDisabled = organizationId === null

		serviceSelect = (
			<Select
				placeholder="Servicios disponibles"
				value={serviceId}
				disabled={serviceSelectDisabled}
				style={{
					paddingLeft: '1.2rem',
					paddingRight: '1.2rem',
				}}
				onChange={(e) => {
					sessionSelectService(e.target.value)
				}}
			>
				{serviceOptions}
			</Select>
		)
	}

	return (
		<VerticalGroup>
			{
				<Select
					placeholder="Laboratorios disponibles"
					value={organizationId}
					disabled={organizations.size <= 1}
					style={{ paddingLeft: '1.2rem', paddingRight: '1.2rem' }}
					onChange={(e) => {
						sessionSelectLab(e.target.value)
					}}
				>
					{orgSelectOptions}
				</Select>
			}
			{serviceSelect}
		</VerticalGroup>
	)
}

export default withRouter(LabServicePicker)
