import { makeActionCreator as mac } from '@loque/reducks'
import types from './actionTypes'
import worker from '../../worker'
import { push } from 'react-router-redux'
import { addToBasePath } from '../../index'
import isPlainObject from 'lodash.isplainobject'
import session from '../session'

const actions = {}

actions.userUpdate = mac(types.USER_UPDATE, 'user')

actions.userListeners = () => {
	return dispatch => {
		worker.on('user', res => {
			let orgs = []

			if (isPlainObject(res.body.organizations)) {
				orgs = Object.values(res.body.organizations)
			}

			const oneOrg = orgs.length === 1 && orgs[0] !== undefined

			if (oneOrg) {
				const services = Object.values(orgs[0].services) || []

				const oneService =
					services.length === 1 && services[0] !== undefined

				if (oneService) {
					dispatch(
						session.actions.sessionSelectLabService(
							orgs[0].id,
							services[0].id,
							{
								resetQueryConfigId: false,
							}
						)
					)
				} else {
					dispatch(
						session.actions.sessionSelectLab(orgs[0].id, {
							resetServiceId: false,
							resetQueryConfigId: false,
						})
					)
				}
			}

			dispatch(actions.userUpdate(res.body))
		})
	}
}

actions.userLogin = (email, password) => {
	return dispatch => {
		dispatch(session.actions.userLoginRequest())

		worker
			.post('/user/login', { username: email, password })
			.then(() => {
				dispatch(session.actions.userLoginSuccess())
			})
			.catch(res => {
				if (res.body.msg) {
					dispatch(session.actions.userLoginError(res.body.msg))
				}
			})
	}
}

actions.userRefresh = () => {
	return (dispatch, getState) => {
		const userTimestamp = getState().getIn(['user', 'timestamp'], 0)
		const currTimestamp = Math.floor(Date.now() / 1000)
		const diff = currTimestamp - userTimestamp

		if (diff < 10) {
			// console.log('user is still valid', {
			// 	diff,
			// 	currTimestamp,
			// 	userTimestamp,
			// })
			return
		}

		dispatch(session.actions.userRefreshRequest())

		worker
			.get('/user')
			.then(() => {
				const organizationId = getState().getIn([
					'session',
					'organizationId',
				])
				const serviceId = getState().getIn(['session', 'serviceId'])
				dispatch(session.actions.userRefreshSuccess())

				if (
					Number.isInteger(organizationId) &&
					Number.isInteger(serviceId)
				) {
					dispatch(actions.userGetService(organizationId, serviceId))
				}
			})
			.catch(error => {
				console.log('user refresh error', error)
				dispatch(session.actions.userRefreshError(error))
			})
	}
}

actions.userGetService = (organizationId, serviceId) => {
	return (dispatch, getState) => {
		const loading = getState().getIn(['session', 'loadingService'])
		if (loading) return

		dispatch(session.actions.sessionUpdateLoadingService(true))
		worker
			.get(
				`/user/service?organizationId=${organizationId}&serviceId=${serviceId}`
			)
			.then(() => {
				dispatch(session.actions.sessionUpdateLoadingService(false))
			})
			.catch(error => {
				console.log('error in userGetService', error)
				dispatch(session.actions.sessionUpdateLoadingService(false))
			})
	}
}

actions.userLogout = (redirect = true) => {
	return dispatch => {
		return worker.post('/user/logout').then(() => {
			redirect && dispatch(push(addToBasePath(`/login`)))
		})
	}
}

actions.userGetWithAccessToQuery = (organizationId, serviceId, queryId) => {
	return () => {
		worker.get(
			`/user/query/${queryId}?organizationId=${organizationId}&serviceId=${serviceId}`
		)
	}
}

actions.updateReportShareTargets = (
	organizationId,
	serviceId,
	queryId,
	users
) => {
	return () => {
		worker.put(`/user/query/${queryId}`, {
			organizationId,
			serviceId,
			users,
		})
	}
}

export default actions
