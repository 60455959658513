import React from 'react'
import {
	Col,
	Display,
	LabelWrapper,
	Label,
	ButtonsWrapper,
	SharedIcon,
} from './common'
import useHover from '../utils/useHover'
import Checkbox from './Checkbox'

export default function ListFile({
	id,
	label,
	onClick,
	buttons,
	shared = false,
	dispatch,
	selectMode,
	selected,
	highlighted,
	idChain,
}) {
	idChain = [...idChain, id]
	const [isHovering, hoverProps] = useHover()

	function highlightHandler(event) {
		!selectMode && dispatch({ type: 'highlightFile', idChain })

		if (typeof onClick === 'function') {
			onClick(event)
		}
	}

	function toggleSelectHandler(event) {
		dispatch({ type: 'toggleSelectFile', idChain })
	}

	return (
		<Col as="li" style={{ lineHeight: '1.8em' }}>
			<Display {...hoverProps} highlighted={highlighted && !selectMode}>
				<LabelWrapper>
					{selectMode && (
						<Checkbox
							style={{ marginRight: '.5rem' }}
							checked={!!selected}
							onChange={toggleSelectHandler}
						/>
					)}
					<Label
						style={{ cursor: selectMode ? 'default' : 'pointer' }}
						onClick={highlightHandler}
					>
						{label}
						{shared && <SharedIcon />}
					</Label>
				</LabelWrapper>
				<ButtonsWrapper>
					{typeof buttons === 'function' && buttons({ isHovering })}
				</ButtonsWrapper>
			</Display>
		</Col>
	)
}
