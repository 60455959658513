import React from 'react'

export default function wrapStrings(children) {
	// If multiple children sent then wrap loose strings in divs
	// so that they behave as expected with Button's `display: flex-inline`.
	if (Array.isArray(children) && children.length > 1) {
		let str = ''
		let nextChildren = []
		let key = 0
		React.Children.forEach(children, child => {
			if (typeof child === 'string') {
				str += child
			} else {
				if (str !== '') {
					nextChildren.push(<span key={key++}>{str}</span>)
					str = ''
				}
				nextChildren.push(child)
			}
		})
		if (str !== '') {
			nextChildren.push(<span key={key++}>{str}</span>)
			str = ''
		}
		children = nextChildren
	}

	return children
}
