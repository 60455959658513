import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'

export const Col = styled.div`
	display: flex;
	flex-direction: column;

	align-items: ${props => {
		if (props.left) {
			return 'flex-start'
		} else if (props.righ) {
			return 'flex-end'
		} else if (props.stretch) {
			return 'stretch'
		} else {
			return 'center'
		}
	}};

	& > *:not(:last-child) {
		margin-bottom: 1rem;
	}
`

export const Row = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: ${props => {
		if (props.center) {
			return 'center'
		} else if (props.right) {
			return 'flex-end'
		}
		return 'flex-start'
	}};

	& > *:not(:last-child) {
		margin-right: ${props =>
			props.separation ? props.separation : '1rem'};
	}
`

export const FormSection = styled.div`
	margin-bottom: 3rem;
	& > *:not(:last-child) {
		margin-bottom: 1rem;
	}
`
export const FormSectionHeader = styled(Row)`
	/* margin-bottom: 1rem; */
`
export const FormSectionTitleWrapper = styled(Row).attrs({
	separation: '0.5rem',
})`
	flex: 1;
`
export const FormSectionTitle = styled.div`
	font-weight: 500;
	color: ${props => lighten(0.06, props.theme.color.fg)};
`
export const FormSectionActions = styled.div``

const EmptySectionBody = styled.div`
	background: ${props => lighten(0.1, props.theme.color.grey)};
	border: 1px dashed ${props => lighten(0.02, props.theme.color.grey)};
	width: 100%;
	height: 2em;
`

export const FormSectionBody = React.forwardRef(
	({ name, children, ...props }, ref) => {
		const empty = React.Children.toArray(children).length === 0
		return (
			<div ref={ref} {...props}>
				{empty ? <EmptySectionBody /> : children}
			</div>
		)
	}
)
