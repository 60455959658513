import React, { useState, useEffect } from 'react'
import ducks from 'front/ducks'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useAction } from '~/modules/utils/redux'

import ValidationMsg from '../utils/ValidationMsg'
import Modal from 'ui/Modal'
import ModalTitle from 'ui/Modal/Title'
import ModalBody from 'ui/Modal/Body'
import ModalActions from 'ui/Modal/Actions'
import Select, { Option } from 'ui/Select'
import Button from 'ui/Button'
import { FiChevronRight as ChevronRightIcon } from 'react-icons/fi'
import Loading from '~/frontend/ui/Loading'
import useFetch from './SintesisKSM'

const SelectsWrapper = styled.div`
	display: flex;
	& > *:not(:last-child) {
		margin-right: 0.5rem;
	}
`

function SintesisKModal({ visible, setVisible }) {
	const [internalVisible, setInternalVisible] = useState(visible)

	const email = useSelector(state => state.getIn(['user', 'email']))
	const { organizationCode, serviceName } = useSelector(state => {
		const organizationId = state.getIn(['session', 'organizationId'])
		const serviceId = state.getIn(['session', 'serviceId'])
		const organizationCode = state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'code',
		])
		const serviceName = state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
			'name',
		])
		return { organizationCode, serviceName }
	})

	const sintesisKRequest = useAction(ducks.actions.sintesisKRequest)
	const [state, send] = useFetch(sintesisKRequest)

	const [year, setYear] = useState()
	const [month, setMonth] = useState()
	const [day, setDay] = useState()
	const [invalidDate, setInvalidDate] = useState(false)

	function isDefined(arg) {
		return arg !== null && arg !== undefined && arg !== ''
	}

	function isValidDate(year, month, day) {
		return isDefined(year) && isDefined(month) && isDefined(day)
	}

	// whenever year, month or day change while invalidDate = true, check if invalidDate should be updated
	useEffect(() => {
		if (invalidDate === true) {
			setInvalidDate(!isValidDate(year, month, day))
		}
	}, [invalidDate, isValidDate, year, month, day])

	function handleSecondaryAction() {
		setInternalVisible(false)
	}

	function handlePrimaryAction() {
		if (isValidDate(year, month, day) === false) {
			setInvalidDate(true)
		} else {
			const data = {
				email,
				organizationCode,
				serviceName,
				dateStart: `${year}-${month}-${day}`,
			}
			send('FETCH', { data })
		}
	}

	useEffect(() => {
		if (state.matches('success')) {
			handleSecondaryAction()
		}
	}, [state.value, handleSecondaryAction])

	let body
	let actionsDisabled = false
	let showSecondary = true
	let primaryText = 'Solicitar extracción'

	switch (state.value) {
		case 'idle':
			if (invalidDate) {
				actionsDisabled = true
			}
			const maxYear = new Date().getFullYear()
			const yearsCount = 20
			const minYear = maxYear - yearsCount

			const yearsOptions = []
			for (let i = yearsCount; i >= 0; i--) {
				const yearOption = `${minYear + i}`

				yearsOptions.push(
					<Option key={i} value={yearOption}>
						{yearOption}
					</Option>
				)
			}

			const monthsOptions = []
			for (let i = 12; i >= 1; i--) {
				const monthOption = pad(i)

				monthsOptions.push(
					<Option key={i} value={monthOption}>
						{monthOption}
					</Option>
				)
			}

			let maxStartDay = new Date(year, month, 0).getDate()
			const daysOptions = []
			for (let i = maxStartDay; i >= 1; i--) {
				const dayOption = pad(i)

				daysOptions.push(
					<Option key={i} value={dayOption}>
						{dayOption}
					</Option>
				)
			}
			body = (
				<React.Fragment>
					<label>Fecha de inicio</label>
					<SelectsWrapper>
						<Select
							placeholder="Año"
							value={year}
							onChange={e => setYear(e.target.value)}
							maxHeight="500px"
						>
							{yearsOptions}
						</Select>
						<Select
							placeholder="Mes"
							value={month}
							onChange={e => setMonth(e.target.value)}
							maxHeight="500px"
						>
							{monthsOptions}
						</Select>
						<Select
							placeholder="Día"
							value={day}
							onChange={e => setDay(e.target.value)}
							maxHeight="500px"
						>
							{daysOptions}
						</Select>
					</SelectsWrapper>
					<ValidationMsg msg="Fecha inválida" shown={invalidDate} />
				</React.Fragment>
			)
			break
		case 'loading':
			actionsDisabled = true
			body = (
				<Loading>
					Preparando la solicitud con fecha de inicio:{' '}
					{`${year}-${month}-${day}`}
				</Loading>
			)
			break
		case 'failure':
			primaryText = 'Cerrar'
			showSecondary = false
			let msg = 'Tuvimos un problema preparando tu solicitud.'
			if (state.context.error.description === 'internal_error') {
				msg =
					'Tuvimos un problema interno. Por favor, comunicate con soporte para solucionarlo.'
			}
			body = <div>{msg}</div>
			break
		default:
			break
	}

	return (
		<Modal visible={internalVisible} onDestroyed={() => setVisible(false)}>
			<ModalTitle>Solicitar extracción de Síntesis K</ModalTitle>
			<ModalBody>{body}</ModalBody>
			<ModalActions>
				{showSecondary && (
					<Button
						role="secondary"
						onClick={handleSecondaryAction}
						disabled={actionsDisabled}
					>
						Cancelar
					</Button>
				)}
				<Button
					role="primary"
					onClick={
						showSecondary
							? handlePrimaryAction
							: handleSecondaryAction
					}
					disabled={actionsDisabled}
				>
					{primaryText}
				</Button>
			</ModalActions>
		</Modal>
	)
}

export default function SintesisK() {
	const [visible, setVisible] = useState(false)

	return (
		<React.Fragment>
			<Button role="link" noHover onClick={() => setVisible(true)}>
				Extracción de Síntesis K
				<ChevronRightIcon />
			</Button>
			{visible && (
				<SintesisKModal visible={visible} setVisible={setVisible} />
			)}
		</React.Fragment>
	)
}

function pad(num) {
	const numInt = parseInt(num)

	if (isNaN(numInt)) {
		console.error(
			'pad has received a value that cannot be parsed to integer',
			num
		)
		return
	}

	return numInt > 9 ? `${numInt}` : `0${numInt}`
}
