// based on https://gist.github.com/ryanflorence/1771621
export default function sortArrayByProp(arr, prop) {
	if (!arr.length) return arr

	const sorters = {
		string: function(a, b) {
			if (a < b) {
				return -1
			} else if (a > b) {
				return 1
			} else {
				return 0
			}
		},

		number: function(a, b) {
			return a - b
		},
	}

	const type = typeof arr[0][prop] || 'string'
	return arr.sort(function(a, b) {
		return sorters[type](a[prop], b[prop])
	})
}
