import { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'

export default function Portal({ children, element = 'div' }) {
	const portal = useRef(document.createElement(element))

	useEffect(() => {
		document.body.appendChild(portal.current)
		return () => {
			document.body.removeChild(portal.current)
		}
	}, [])

	return createPortal(children, portal.current)
}
