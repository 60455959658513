import { createActionTypes, makeActionCreator as mac } from '@loque/reducks'
import worker from '../worker'
import { asImmutable } from 'utils/immutable'

const types = createActionTypes(['TMP_UPDATE_ALL'])

const initialState = asImmutable({
	shareUsers: null,
})

function reducer(state = initialState, action) {
	let nextState = state

	switch (action.type) {
		case types.TMP_UPDATE_ALL:
			return nextState.merge(asImmutable(action.tmp))

		default:
			return nextState
	}
}

const actions = {}

actions.tmpUpdateAll = mac(types.TMP_UPDATE_ALL, 'tmp')
actions.tmpListeners = () => {
	return dispatch => {
		worker.on('tmp', res => {
			dispatch(actions.tmpUpdateAll(res.body))
		})
	}
}

export default {
	types,
	reducer,
	actions,
}
