import styled from 'styled-components'

export const Table = styled.table`
	width: 100%;
`

export const THead = styled.thead``
export const TBody = styled.tbody``

export const Tr = styled.tr`
	vertical-align: middle;
`

export const Th = styled.th`
	text-align: left;
	font-weight: 500;
`

const vertical = ['top', 'middle', 'bottom']
const horizontal = ['right', 'center', 'left']

function getAlign(align, options, defaultValue) {
	if (!align) return defaultValue

	align = align
		.split(' ')
		.filter(v => v !== '')
		.find(v => options.includes(v))

	return align || defaultValue
}

export const Td = styled.td`
	text-align: ${props => getAlign(props.align, horizontal, 'left')};
	vertical-align: ${props => getAlign(props.align, vertical, 'middle')};
`
