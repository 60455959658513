import { createActionTypes, makeActionCreator as mac } from '@loque/reducks'
import worker from '../worker'
import { asImmutable } from 'utils/immutable'

const types = createActionTypes([
	'QUERIES_PRES_PROD_DATA',
	'QUERIES_PRES_PROD_LOADING',
	'QUERIES_PRES_PROD_CLEAR',
	'QUERIES_PRES_PROD_ERROR',
])

const initialState = asImmutable({
	data: null,
	loading: false,
	error: null,
})

function reducer(state = initialState, action) {
	let nextState = state
	switch (action.type) {
		case types.QUERIES_PRES_PROD_DATA:
			return nextState.set('data', asImmutable(action.data))

		case types.QUERIES_PRES_PROD_LOADING:
			return nextState.set('loading', asImmutable(action.loading))

		case types.QUERIES_PRES_PROD_CLEAR:
			return nextState.set('data', asImmutable([]))

		case types.QUERIES_PRES_PROD_ERROR:
			return nextState.set('error', asImmutable(action.error))

		default:
			return nextState
	}
}

const actions = {}

actions.queriesPresProdData = mac(types.QUERIES_PRES_PROD_DATA, 'data')
actions.queriesPresProdLoading = mac(types.QUERIES_PRES_PROD_LOADING, 'loading')
actions.queriesPresProdClear = mac(types.QUERIES_PRES_PROD_CLEAR)
actions.queriesPresProdError = mac(types.QUERIES_PRES_PROD_ERROR, 'error')

actions.queriesPresProdFetch = queryConfig => {
	return (dispatch, getState) => {
		if (getState().getIn(['queriesPresProd', 'loading'])) return
		dispatch(actions.queriesPresProdLoading(true))

		worker
			.get(`/queries/${queryConfig.get('id')}/presprod`, {})
			.then(res => {
				dispatch(actions.queriesPresProdData(res.body))
				dispatch(actions.queriesPresProdLoading(false))
			})
			.catch(error => {
				console.error(`Error getting queries pres-prod`, error)
				dispatch(actions.queriesPresProdLoading(false))
				dispatch(actions.queriesPresProdError(error))
			})
	}
}

export default {
	types,
	reducer,
	actions,
}
