import worker from '../../worker'
import { push } from 'react-router-redux'
import { createActionTypes, makeActionCreator as mac } from '@loque/reducks'
import notifications from '../notifications'
import { addToBasePath } from '../../index'

const types = createActionTypes(['ADMIN_UPDATE_SERVICE_DIMENSIONS'])
const actions = {}

actions.adminUpdateServiceStructure = mac(
	types.ADMIN_UPDATE_SERVICE_DIMENSIONS,
	'serviceStructure'
)

actions.adminGetServiceStructure = () => {
	return dispatch => {
		worker
			.get('/admin/services/structure')
			.then(res => {
				dispatch(actions.adminUpdateServiceStructure(res.body))
			})
			.catch(res => {
				console.error(`Error fetching service structure`, res)
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al listar las dimensiones de los servicios'
					)
				)
			})
	}
}

// { name: '', groupName: '', fields: [], relation: [] }
actions.adminCreateServiceDimension = serviceDimension => {
	return dispatch => {
		dispatch(
			notifications.actions.notify(
				'create-service-structure',
				'Creando dimensión de servicios...'
			)
		)

		worker
			.post('/admin/services/structure', serviceDimension)
			.then(res => {
				dispatch(push(addToBasePath(`/admin/estructura`)))
				dispatch(
					notifications.actions.notifyEnd('create-service-structure')
				)
			})
			.catch(res => {
				console.error(`Error creating the serviceDimension`, res)
				dispatch(
					notifications.actions.notifyEnd('create-service-structure')
				)
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al crear la dimensión de servicios'
					)
				)
			})
	}
}

actions.adminUpdateServiceDimension = (id, serviceDimension) => {
	return dispatch => {
		dispatch(
			notify('update-service-structure', 'Actualizando dimensión...')
		)

		worker
			.put(`/admin/services/structure/${id}`, serviceDimension)
			.then(res => {
				dispatch(push(addToBasePath(`/admin/estructura`)))
				dispatch(
					notifications.actions.notifyEnd('update-service-structure')
				)
			})
			.catch(res => {
				console.error(`Error updating the service structure`, res)
				dispatch(
					notifications.actions.notifyEnd('update-service-structure')
				)
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al actualizar la dimensión'
					)
				)
			})
	}
}

actions.adminDeleteServiceDimension = id => {
	return dispatch => {
		dispatch(
			notifications.actions.notify(
				'delete-service-structure',
				'Eliminando dimensión...'
			)
		)

		worker
			.delete(`/admin/services/dimensions/${id}`)
			.then(res => {
				dispatch(push(addToBasePath(`/admin/estructura`)))
				dispatch(
					notifications.actions.notifyEnd('delete-service-structure')
				)
			})
			.catch(res => {
				console.error(`Error deleting the v`, res)
				dispatch(
					notifications.actions.notifyEnd('delete-service-structure')
				)
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al eliminar la dimensión'
					)
				)
			})
	}
}

export default {
	types,
	actions,
}
