import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useTransition, animated } from 'react-spring'

import Portal from '../utils/Portal'
import useAutoFocus from '../utils/useAutoFocus'
import Paper from '../Paper'

const ContainerBase = styled(animated.div)`
	position: fixed;
	left: 0;
	right: 0;
	margin: auto;

	top: 40px;
	display: flex;
	justify-content: center;
	z-index: 4;

	max-height: calc(100% - 40px - 40px);
`

const ContentWrapper = styled(Paper)`
	min-width: 420px;
	padding: 2.8rem 2rem;
`

const transition = {
	from: {
		opacity: 0.9,
		transform: 'translateY(-100%)',
	},
	enter: {
		opacity: 1,
		transform: 'translateY(0)',
	},
	leave: {
		opacity: 0.8,
		transform: 'translateY(-300%)',
	},
}

export default function Container({
	visible,
	onBlur,
	children,
	userStyle = {},
	userWrapperStyle = {},
	onDestroyed = () => {},
}) {
	const containerRef = useRef()
	const targetRef = useRef(document.getElementById('target'))

	const transitions = useTransition(visible, null, {
		...transition,
		onDestroyed,
	})

	useAutoFocus(visible, containerRef)

	useEffect(() => {
		if (!targetRef.current) return

		if (visible) {
			targetRef.current.classList.add('blur')
		} else {
			targetRef.current.classList.remove('blur')
		}

		return () => {
			targetRef.current.classList.remove('blur')
		}
	}, [visible, targetRef.current])

	return (
		<React.Fragment>
			{transitions.map(({ item: visible, props: style, key }) => {
				if (!visible) return null

				return (
					<Portal key={key}>
						<ContainerBase
							ref={containerRef}
							style={Object.assign({}, style, userStyle)}
							onBlur={onBlur}
						>
							<ContentWrapper
								elevation={24}
								style={userWrapperStyle}
							>
								{children}
							</ContentWrapper>
						</ContainerBase>
					</Portal>
				)
			})}
		</React.Fragment>
	)
}
