import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Curtain from './Curtain'
import Container from './Container'

export default function Modal({
	onVisibilityChange,
	onDestroyed,
	visible,
	hideOnEsc,
	hideOnBlur,
	containerStyle = {},
	containerWrapperStyle = {},
	children,
}) {
	useEffect(() => {
		if (hideOnEsc === false) return

		function handleEsc(event) {
			if (event.key === 'Escape') {
				onVisibilityChange(false)
			}
		}

		// only add the event listener when the modal is shown
		// to avoid listening to esc down in other context
		// while the modal is mounted
		if (visible) {
			window.addEventListener('keydown', handleEsc, false)
		} else {
			window.removeEventListener('keydown', handleEsc, false)
		}
		return () => {
			window.removeEventListener('keydown', handleEsc, false)
		}
	}, [visible, hideOnEsc])

	return (
		<React.Fragment>
			<Curtain visible={visible} />
			<Container
				userStyle={containerStyle}
				userWrapperStyle={containerWrapperStyle}
				onBlur={() => {
					if (hideOnBlur) {
						onVisibilityChange(false)
					}
				}}
				onDestroyed={destroyed => {
					destroyed && onDestroyed()
				}}
				visible={visible}
			>
				{children}
			</Container>
		</React.Fragment>
	)
}

Modal.defaultProps = {
	visible: false,
	onVisibilityChange: () => {},
	onDestroyed: () => {},
	hideOnEsc: false,
	hideOnBlur: false,
}
Modal.propTypes = {
	visible: PropTypes.bool,
	children: PropTypes.node.isRequired,
	onVisibilityChange: PropTypes.func,
	onDestroyed: PropTypes.func,
	hideOnEsc: PropTypes.bool,
	hideOnBlur: PropTypes.bool,
}
