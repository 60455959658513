import { createActionTypes, makeActionCreator as mac } from '@loque/reducks'
import worker from '../worker'
import uuid from 'uuid'
import { asImmutable } from 'utils/immutable'

const types = createActionTypes(['NOTIFICATIONS_ADD', 'NOTIFICATIONS_REMOVE'])

const initialState = asImmutable([])

function reducer(state = initialState, action) {
	let nextState = state
	switch (action.type) {
		case types.NOTIFICATIONS_ADD:
			return nextState.push(
				asImmutable({
					label: action.label || uuid.v4(),
					message: action.message,
					type: action._type || 'loading',
					showRemoveBtn: action.showRemoveBtn || false,
					stopwatch: action.stopwatch || false,
				})
			)

		case types.NOTIFICATIONS_REMOVE:
			return nextState.filterNot(
				notification => notification.get('label') === action.label
			)

		default:
			return nextState
	}
}

const actions = {}

actions.notify = mac(
	types.NOTIFICATIONS_ADD,
	'label',
	'message',
	'_type',
	'showRemoveBtn',
	'stopwatch'
)
actions.notifyEnd = mac(types.NOTIFICATIONS_REMOVE, 'label')

actions.notifyError = message => {
	return dispatch => {
		dispatch(actions.notify(null, message, 'error'))
	}
}

actions.notificationsListeners = () => {
	return dispatch => {
		worker.on('notification-start', res => {
			dispatch(
				actions.notify(res.body.label, res.body.message, res.body.type)
			)
		})

		worker.on('notification-end', res => {
			dispatch(actions.notifyEnd(res.body.label))
		})
	}
}

export default {
	types,
	reducer,
	actions,
}
