import { asImmutable } from 'utils/immutable'

import { LOCATION_CHANGE } from 'react-router-redux'

const initialState = asImmutable({
	locationBeforeTransitions: null,
})

function reducer(state = initialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return state.merge({
				locationBeforeTransitions: action.payload,
			})

		default:
			return state
	}
}

export default {
	reducer,
}
