import worker from '../worker'
import { asImmutable } from 'utils/immutable'

const types = {}

const initialState = asImmutable({})

function reducer(state = initialState, action) {
	let nextState = state
	switch (action.type) {
		default:
			return nextState
	}
}

const actions = {}

actions.sintesisKRequest = data => {
	return () =>
		worker.post(`/queries/sintesisk`, { ...data }).catch(error => {
			console.error(`Error requesting Síntesis K`, error)
			throw error.body && error.body.error && error.body.error
		})
}

export default {
	types,
	reducer,
	actions,
}
