import React, { useReducer, useEffect, useRef } from 'react'
import ListBase from './ListBase'
import produce from 'immer'
import { Col, Row } from './common'
import Button from '../Button'
import ButtonGroup from '../Button/Group'
import {
	FiCheckSquare as Checked,
	FiSquare as Unchecked,
	FiMinusSquare as Indeterminate,
	FiMinimize2 as Collapse,
	FiMaximize2 as Expand,
} from 'react-icons/fi'

function updateNodeInTree(tree, idChain, finalFn, intermediateFn = (a) => a) {
	if (tree === undefined || tree.length === 0) return []

	return tree.map((node) => {
		if (node.id === idChain[0]) {
			if (idChain.length > 1) {
				// Not the last node
				node.children = updateNodeInTree(
					node.children,
					idChain.slice(1),
					finalFn,
					intermediateFn
				)
				node = intermediateFn(node)
			} else {
				// Last node
				node = finalFn(node)
			}
		}
		return node
	})
}

function updateParents(tree, idChain, fn) {
	if (tree === undefined || tree.length === 0) return []

	return tree.map((node) => {
		if (node.id === idChain[0]) {
			if (idChain.length > 1) {
				// Not the last node
				node.children = updateParents(
					node.children,
					idChain.slice(1),
					fn
				)
				node = fn(node)
			}
		}
		return node
	})
}

function updateChildren(tree, updater) {
	if (tree === undefined || tree.length === 0) {
		return []
	}
	return tree.map((node) => {
		if (node.hasOwnProperty('children')) {
			node.children = updateChildren(node.children, updater)
		}
		return updater(node)
	})
}

function updateParentsState(tree, idChain) {
	return updateParents(tree, idChain, (node) => {
		const all = node.children.every((child) => {
			if (child.type === 'file') {
				return child.selected === true
			} else if (child.type === 'folder') {
				return child.selected === 'all'
			}
		})
		const none = node.children.every((child) => {
			if (child.type === 'file') {
				return child.selected !== true
			} else if (child.type === 'folder') {
				return child.selected === 'none'
			}
		})
		const some = all === none
		if (all) {
			node.selected = 'all'
		} else if (none) {
			node.selected = 'none'
		} else if (some) {
			node.selected = 'some'
		}
		return node
	})
}

function reducer(tree, action) {
	switch (action.type) {
		case 'toggleOpenFolder':
			return produce(tree, (draft) => {
				draft = updateNodeInTree(draft, action.idChain, (folder) => {
					if (folder.highlightedParent && folder.open) {
						// If folder is the parent of the highlighted
						// file it can't be closed.
						return folder
					}
					folder.open = !folder.open
					if (folder.open === false) {
						folder.children = updateChildren(
							folder.children,
							(child) => {
								if (child.open && child.type === 'folder') {
									child.open = false
									child.highlightedParent = false
								}
								return child
							}
						)
					}
					return folder
				})
			})

		case 'highlightFile':
			return produce(tree, (draft) => {
				draft = updateChildren(draft, (child) => {
					if (child.type === 'folder') {
						child.highlightedParent = false
					} else if (child.type === 'file') {
						child.highlighted = false
					}
					return child
				})
				draft = updateNodeInTree(
					draft,
					action.idChain,
					(file) => {
						file.highlighted = true
						return file
					},
					(folder) => {
						folder.open = true
						folder.highlightedParent = true
						return folder
					}
				)
			})

		case 'toggleSelectFolder':
			return produce(tree, (draft) => {
				draft = updateNodeInTree(draft, action.idChain, (node) => {
					switch (node.selected) {
						case 'all':
							node.selected = 'none'
							break
						case 'none':
							node.selected = 'all'
							break
						case 'some':
							node.selected = 'none'
							break
						default:
							node.selected = 'all'
							break
					}

					if (['all', 'none'].includes(node.selected)) {
						node.children = updateChildren(
							node.children,
							(child) => {
								if (child.type === 'folder') {
									child.selected = node.selected
								} else if (child.type === 'file') {
									child.selected = node.selected === 'all'
								}
								return child
							}
						)
					}
					return node
				})

				// Update the tree upwards
				draft = updateParentsState(draft, action.idChain)
			})

		case 'toggleSelectFile':
			return produce(tree, (draft) => {
				draft = updateNodeInTree(draft, action.idChain, (node) => {
					if (node.type !== 'file') {
						throw new Error(
							'Called toggleSelectFile targeting a folder',
							tree,
							action,
							node
						)
					}
					node.selected = !node.selected
					return node
				})

				// Update the tree upwards
				draft = updateParentsState(draft, action.idChain)
			})

		case 'selectAll':
			return produce(tree, (draft) => {
				updateChildren(draft, (child) => {
					if (child.type === 'folder') {
						child.selected = 'all'
					} else if (child.type === 'file') {
						child.selected = true
					}
					return child
				})
			})

		case 'selectNone':
			return produce(tree, (draft) => {
				updateChildren(draft, (child) => {
					if (child.type === 'folder') {
						child.selected = 'none'
					} else if (child.type === 'file') {
						child.selected = false
					}
					return child
				})
			})

		case 'collapsed':
			return produce(tree, (draft) => {
				updateChildren(draft, (child) => {
					if (child.type === 'folder') {
						child.open = false
					}
					return child
				})
			})

		case 'expanded':
			return produce(tree, (draft) => {
				updateChildren(draft, (child) => {
					if (child.type === 'folder') {
						child.open = true
					}
					return child
				})
			})

		default:
			throw new Error(`Invalid action "${action.type}"`)
	}
}

function flattenByType(tree, type = null, idChain = []) {
	let res = []
	tree.forEach((node) => {
		if (node.hasOwnProperty('children') && node.children.length) {
			res = res.concat(
				flattenByType(node.children, type, [...idChain, node.id])
			)
		}
		if (type === null || node.type === type) {
			res.push({ ...node, idChain: [...idChain, node.id] })
		}
	})
	return res
}

function extractSelectedPaths(tree) {
	return flattenByType(tree, 'file')
		.filter((f) => f.selected)
		.map((f) => f.idChain)
}

function extractHighlightedFile(tree) {
	return flattenByType(tree, 'file').find((f) => f.highlighted)
}

function getNodeById(tree, nodeId) {
	return flattenByType(tree).find((node) => node.id === nodeId)
}

export default function List({
	initialTree = [],

	highlighted = undefined,
	onHighlightedChange = null,

	selectMode = false,
	onSelect = null,
}) {
	const [tree, dispatch] = useReducer(reducer, initialTree)

	const highlightedFile = useRef(extractHighlightedFile(tree))
	useEffect(() => {
		if (typeof onHighlightedChange === 'function') {
			const nextHighlightedFile = extractHighlightedFile(tree)

			if (
				// first file was highlighted
				(highlightedFile.current === undefined &&
					nextHighlightedFile !== undefined) ||
				// highlighted file has chanded
				(highlightedFile.current &&
					highlightedFile.current.id !== nextHighlightedFile.id)
			) {
				highlightedFile.current = nextHighlightedFile
				onHighlightedChange(highlightedFile.current.id)
			}
		}
	}, [tree, onHighlightedChange, highlightedFile.current])

	const selectedFiles = useRef(extractSelectedPaths(tree))
	useEffect(() => {
		if (selectMode && typeof onSelect === 'function') {
			const nextSelectedFiles = extractSelectedPaths(tree)

			if (
				JSON.stringify(nextSelectedFiles) !==
				JSON.stringify(selectedFiles.current)
			) {
				selectedFiles.current = nextSelectedFiles
				onSelect(selectedFiles.current)
			}
		}
	}, [tree, selectMode, onSelect, selectedFiles.current])

	useEffect(() => {
		if (highlighted !== undefined) {
			const highlightedFile = getNodeById(initialTree, highlighted)
			if (highlightedFile) {
				dispatch({
					type: 'highlightFile',
					idChain: highlightedFile.idChain,
				})
			}
		}
	}, [initialTree, highlighted])

	let toolbar
	if (selectMode) {
		const files = flattenByType(tree, 'file')
		// none|all|mixed
		let selectionState = 'mixed'
		if (files.every((file) => file.selected)) {
			selectionState = 'all'
		} else if (files.every((file) => !file.selected)) {
			selectionState = 'none'
		}

		let multiSelectBtn
		switch (selectionState) {
			case 'all':
				multiSelectBtn = (
					<Button
						variant="text"
						title="Deseleccionar"
						onClick={() => dispatch({ type: 'selectNone' })}
					>
						<Checked />
					</Button>
				)
				break
			case 'none':
				multiSelectBtn = (
					<Button
						variant="text"
						title="Seleccionar"
						onClick={() => dispatch({ type: 'selectAll' })}
					>
						<Unchecked />
					</Button>
				)
				break
			case 'mixed':
				multiSelectBtn = (
					<Button
						variant="text"
						title="Seleccionar"
						onClick={() => dispatch({ type: 'selectAll' })}
					>
						<Indeterminate />
					</Button>
				)
				break
		}

		const folders = flattenByType(tree, 'folder')
		// collapsed|expanded|mixed
		let collapsedState = 'mixed'
		if (folders.every((folder) => folder.open)) {
			collapsedState = 'expanded'
		} else if (folders.every((folder) => !folder.open)) {
			collapsedState = 'collapsed'
		}

		let collapsedBtn
		if (['expanded', 'mixed'].includes(collapsedState)) {
			collapsedBtn = (
				<Button
					variant="text"
					title="Contraer"
					onClick={() => dispatch({ type: 'collapsed' })}
				>
					<Collapse />
				</Button>
			)
		} else if (collapsedState === 'collapsed') {
			collapsedBtn = (
				<Button
					variant="text"
					title="Expandir"
					onClick={() => dispatch({ type: 'expanded' })}
				>
					<Expand />
				</Button>
			)
		}

		toolbar = (
			<Row
				style={{
					justifyContent: 'flex-end',
					paddingRight: '0.35rem',
					position: 'sticky',
					top: 0,
					background: '#f5f5f5',
				}}
			>
				<ButtonGroup>
					{multiSelectBtn}
					{collapsedBtn}
				</ButtonGroup>
			</Row>
		)
	}

	return (
		<Col>
			{toolbar}
			<ListBase tree={tree} selectMode={selectMode} dispatch={dispatch} />
		</Col>
	)
}
