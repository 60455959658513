import { createActionTypes } from '@loque/reducks'

export default createActionTypes([
	'QUERIES_SET_QUERY_CONFIG_ID',
	'QUERIES_SET_LOADING_QUERY_CONFIG',
	'QUERIES_SET_LOADING_QUERIES',

	'QUERIES_DUPLICATE_REQUEST',
	'QUERIES_DUPLICATE_SUCCESS',
	'QUERIES_DUPLICATE_ERROR',
	'QUERIES_DUPLICATE_RESET',

	'QUERIES_GET_SHARE_TARGETS_REQUEST',
	'QUERIES_GET_SHARE_TARGETS_SUCCESS',
	'QUERIES_GET_SHARE_TARGETS_ERROR',
	'QUERIES_GET_SHARE_TARGETS_RESET',

	'QUERIES_GET_FOLDER_TARGETS_REQUEST',
	'QUERIES_GET_FOLDER_TARGETS_SUCCESS',
	'QUERIES_GET_FOLDER_TARGETS_ERROR',
	'QUERIES_GET_FOLDER_TARGETS_RESET',

	'QUERIES_PUT_FOLDER_REQUEST',
	'QUERIES_PUT_FOLDER_SUCCESS',
	'QUERIES_PUT_FOLDER_ERROR',
	'QUERIES_PUT_FOLDER_RESET',

	'QUERIES_DELETE_REQUEST',
	'QUERIES_DELETE_SUCCESS',
	'QUERIES_DELETE_ERROR',
	'QUERIES_DELETE_RESET',
])
