import { useMachine } from '@xstate/react'
import { Machine, assign } from 'xstate'

const fetchMachine = Machine(
	{
		id: 'fetch',
		initial: 'idle',
		context: {
			request: {},
			result: {},
			error: {},
		},
		states: {
			idle: {
				on: {
					FETCH: 'loading',
				},
			},
			loading: {
				entry: ['setRequest'],
				invoke: {
					src: 'doFetch',
					onDone: { target: 'success', actions: 'setResult' },
					onError: { target: 'failure', actions: 'setError' },
				},
			},
			success: {
				type: 'final',
			},
			failure: {
				on: {
					RETRY: 'loading',
					RESET: {
						target: 'loading',
						actions: 'reset',
					},
				},
			},
		},
	},
	{
		actions: {
			setRequest: assign((ctx, event) => ({
				request: event.data,
			})),
			setResult: assign((ctx, event) => ({
				result: event.data,
			})),
			setError: assign((ctx, event) => ({
				error: event.data,
			})),
			reset: assign((ctx, event) => ({
				request: {},
				result: {},
				error: {},
			})),
		},
		services: {
			doFetch: ctx => {
				return Promise.resolve({ userIdIs: ctx.request.userID })
			},
		},
	}
)

export default function useFetch(fetchFn) {
	const [state, send] = useMachine(fetchMachine, {
		services: {
			doFetch: (ctx, event) => fetchFn(ctx.request),
		},
	})

	return [state, send]
}
