import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ducks from 'front/ducks'

import Modal from 'ui/Modal'
import ModalTitle from 'ui/Modal/Title'
import ModalBody from 'ui/Modal/Body'
import ModalActions from 'ui/Modal/Actions'
import MenuButton from 'ui/Dropdown/MenuButton'
import Loading from 'ui/Loading'
import Button from 'ui/Button'
import Select, { Option } from 'ui/Select'
import { FiCornerRightUp as RightUpArrowIcon } from 'react-icons/fi'

import sortArrayByProp from '~/modules/utils/sortArrayByProp'
import { useAction } from '~/modules/utils/redux'

function MoveReportToFolderModal({
	reportId,
	reportName,
	currFolderId,

	visible,
	setVisible,
}) {
	const [internalVisible, setInternalVisible] = useState(visible)
	const [selectedFolder, setSelectedFolder] = useState({})

	const {
		requestId: getTargetsRequestId,
		loading: getTargetsLoading,
		error: getTargetsError,
		targets,
	} = useSelector(state =>
		state.getIn(['queries', 'getFolderTargets']).toJS()
	)

	const {
		requestId: putFolderRequestId,
		loading: putFolderLoading,
		error: putFolderError,
		success: purFolderSuccess,
	} = useSelector(state => state.getIn(['queries', 'putFolder']).toJS())

	const reportCode = useSelector(state => {
		const { organizationId, serviceId } = state.get('session').toJS()
		return state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
			'report_code',
		])
	})

	const getTargets = useAction(ducks.actions.queriesGetFolderTargets)
	const resetTargets = useAction(ducks.actions.queriesGetFolderTargetsReset)

	const putFolder = useAction(ducks.actions.queriesPutFolder)
	const confirmPutFolder = useAction(ducks.actions.queriesPutFolderConfirm)
	const resetPutFolder = useAction(ducks.actions.queriesPutFolderReset)

	useEffect(() => {
		if (
			internalVisible &&
			getTargetsRequestId === null &&
			!getTargetsLoading &&
			!getTargetsError &&
			targets === null
		) {
			getTargets(reportId, currFolderId)
		}
	}, [
		internalVisible,
		getTargetsRequestId,
		getTargetsLoading,
		getTargetsError,
		targets,
		getTargets,
		reportId,
		currFolderId,
	])

	function runSecondaryAction(event) {
		event && event.preventDefault()
		setInternalVisible(false)
	}

	function runPrimaryAction(event) {
		event.preventDefault()
		putFolder(reportId, selectedFolder.id)
	}

	// we delay the reset so that it happens once the modal is hidden
	useEffect(
		() => () => {
			resetTargets()
			resetPutFolder()
		},
		[]
	)

	useEffect(() => {
		if (
			internalVisible &&
			purFolderSuccess &&
			putFolderRequestId === reportId
		) {
			// we are done
			setInternalVisible(false)
		}
	}, [internalVisible, purFolderSuccess, putFolderRequestId, reportId])

	let state
	let body

	if (getTargetsLoading) {
		// getTargetsLoading
		state = 1
		body = (
			<Loading>
				Cargando {reportCode === 'market' ? ' líneas' : ' carpetas'}...
			</Loading>
		)
	} else if (putFolderLoading) {
		// putFolderLoading
		state = 1
		body = <Loading>Procesando solicitud...</Loading>
	} else if (getTargetsError) {
		// getTargetsError
		state = 2
		body = (
			<p>
				Tuvimos un error al listar las{' '}
				{reportCode === 'market' ? ' líneas' : ' carpetas'}.
			</p>
		)
	} else if (putFolderError) {
		// putFolderError
		state = 2
		body = (
			<p>
				Tuvimos un error al mover{' '}
				{reportCode === 'market' ? 'el mercado' : 'la consulta'}.
			</p>
		)
	} else if (!purFolderSuccess && targets !== null) {
		// waiting
		state = 0
		const foldersOptions = sortArrayByProp(targets, 'name').map(f => {
			return (
				<Option key={f.id} value={f.id} content={f}>
					{f.name}
				</Option>
			)
		})
		body = (
			<React.Fragment>
				<Select
					placeholder={`Seleccionar una ${
						reportCode === 'market' ? 'línea' : 'carpeta'
					}`}
					value={selectedFolder.id}
					onChange={e => {
						setSelectedFolder(e.target.content)
					}}
				>
					{foldersOptions}
				</Select>
			</React.Fragment>
		)
	}

	return (
		<Modal
			visible={internalVisible}
			onDestroyed={() => {
				if (purFolderSuccess && reportId === putFolderRequestId) {
					confirmPutFolder(reportId)
				}
				setVisible(false)
			}}
		>
			<ModalTitle>
				Seleccionar destino para
				{reportCode === 'market' ? ' el mercado ' : ' la consulta '}
				{reportName && <i>{reportName}</i>}
			</ModalTitle>
			<ModalBody>{body}</ModalBody>
			<ModalActions>
				{state === 2 && (
					<Button role="primary" onClick={runSecondaryAction}>
						Cerrar
					</Button>
				)}
				{state === 0 && (
					<Button
						role="secondary"
						onClick={runSecondaryAction}
						disabled={state === 1}
					>
						Cancelar
					</Button>
				)}
				{state === 0 && (
					<Button
						role="primary"
						onClick={runPrimaryAction}
						disabled={state === 1}
					>
						Mover
					</Button>
				)}
			</ModalActions>
		</Modal>
	)
}

export default function useMoveReportToFolder(
	reportId,
	reportName,
	currFolderId
) {
	const [visible, setVisible] = useState(false)

	if (currFolderId !== null && currFolderId !== undefined) {
		// already inside a folder
		return { button: null, modal: null }
	}

	const modal = visible && (
		<MoveReportToFolderModal
			reportId={reportId}
			reportName={reportName}
			currFolderId={currFolderId}
			visible={visible}
			setVisible={setVisible}
		/>
	)

	const button = (
		<MenuButton
			onClick={() => setVisible(true)}
			icon={<RightUpArrowIcon />}
		>
			Mover a
		</MenuButton>
	)

	return { button, modal }
}
