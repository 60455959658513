import React from 'react'
import wrapStrings from './wrapStrings'
import styled, { css } from 'styled-components'
import { darken, lighten } from 'polished'

const box = css`
	padding: ${props => props.theme.controls.padding(props.slim)};
	border-radius: ${props => props.theme.controls.borderRadius};
	border: 1px solid transparent;
`

export function grd(from, to) {
	return `
	background: radial-gradient(
		100% 100% at center 0px,
		${from} 30%,
		${to} 80%
	);
	`
}

const primaryVariants = {
	contained: css`
		color: ${props => props.theme.color.white};
		${props =>
			grd(
				props.theme.color.accent,
				darken(0.05, props.theme.color.accent)
			)}
		border-color: ${props => darken(0.04, props.theme.color.accent)};

		&:hover:not(:disabled) {
			${props =>
				grd(
					darken(0.02, props.theme.color.accent),
					darken(0.08, props.theme.color.accent)
				)}

			border-color: ${props => darken(0.08, props.theme.color.accent)};
		}
	`,
	text: css`
		background: transparent;
		color: ${props => darken(0.12, props.theme.color.accent)};

		&:hover:not(:disabled) {
			color: ${props => darken(0.16, props.theme.color.accent)};
			background: ${props => lighten(0.4, props.theme.color.accent)};
		}
	`,
}

const primary = css`
	${box}
	${props => primaryVariants[props.variant]}
`
const secondaryVariants = {
	contained: css`
		color: ${props => darken(0.22, props.theme.color.fg)};
		${props => grd(lighten(0.04, props.theme.color.grey), props.theme.color.grey)}
		border-color: ${props => darken(0.06, props.theme.color.grey)};

		&:hover:not(:disabled) {
			color: ${props => darken(0.42, props.theme.color.fg)};
			${props =>
				grd(
					lighten(0.02, props.theme.color.grey),
					darken(0.04, props.theme.color.grey)
				)}

			border-color: ${props => darken(0.08, props.theme.color.grey)};
		}

		&:disabled {
			filter: saturate(0.6) brightness(1.01) !important;
		}
	`,

	text: css`
		background: transparent;
		color: ${props => darken(0.12, props.theme.color.fg)};

		&:hover:not(:disabled) {
			color: ${props => darken(0.42, props.theme.color.fg)};
			background: ${props => lighten(0.92, props.theme.color.fg)};
		}
	`,

	outlined: css`
		background: transparent;
		color: ${props => props.theme.color.fg};
		border-color: ${props => darken(0.04, props.theme.color.grey)};

		&:hover:not(:disabled) {
			color: ${props => props.theme.color.fg};
			background-color: ${props => lighten(0.06, props.theme.color.grey)};
			border-color: ${props => lighten(0.06, props.theme.color.grey)};
		}

		&:disabled {
			filter: saturate(0.6) brightness(1.1) !important;
		}
	`,
}

const secondary = css`
	${box}
	${props => secondaryVariants[props.variant]}
`

const defaultVariants = {
	contained: css`
		color: ${props => darken(0.22, props.theme.color.fg)};
		${props => grd(lighten(0.04, props.theme.color.grey), props.theme.color.grey)}
		border-color: ${props => darken(0.06, props.theme.color.grey)};

		&:hover:not(:disabled) {
			color: ${props => darken(0.42, props.theme.color.fg)};
			${props =>
				grd(
					lighten(0.02, props.theme.color.grey),
					darken(0.04, props.theme.color.grey)
				)}

			border-color: ${props => darken(0.08, props.theme.color.grey)};
		}

		&:disabled {
			filter: saturate(0.6) brightness(1.01) !important;
		}
	`,

	text: css`
		background: transparent;
		color: ${props => darken(0.12, props.theme.color.fg)};

		&:hover:not(:disabled) {
			color: ${props => darken(0.42, props.theme.color.fg)};
			background: ${props => lighten(0.92, props.theme.color.fg)};
		}
	`,

	search: css`
		background: #fff;
		color: ${props => darken(0.12, props.theme.color.fg)};
		border-color: ${props => darken(0.04, props.theme.color.grey)};

		&:hover:not(:disabled) {
			color: ${props => darken(0.42, props.theme.color.fg)};
			background: ${props => lighten(0.92, props.theme.color.fg)};
		}
	`,

	outlined: css`
		background: transparent;
		color: ${props => props.theme.color.fg};
		border-color: ${props => darken(0.04, props.theme.color.grey)};

		&:hover:not(:disabled) {
			color: ${props => props.theme.color.fg};
			background-color: ${props => lighten(0.06, props.theme.color.grey)};
			border-color: ${props => darken(0.06, props.theme.color.grey)};
		}

		&:disabled {
			filter: saturate(0.6) brightness(1.1) !important;
		}
	`,
}

const _default = css`
	${box}
	${props => secondaryVariants[props.variant]}
	${props => defaultVariants[props.variant]}
`

const warningVariants = {
	contained: css`
		color: white;
		${props => grd(props.theme.color.red, darken(0.06, props.theme.color.red))}
		border-color: ${props => darken(0.06, props.theme.color.red)};

		&:hover:not(:disabled) {
			${props =>
				grd(
					darken(0.04, props.theme.color.red),
					darken(0.1, props.theme.color.red)
				)}
		}
	`,

	text: css`
		background: transparent;
		color: ${props => darken(0.16, props.theme.color.red)};

		&:hover:not(:disabled) {
			color: ${props => darken(0.22, props.theme.color.red)};
			background: ${props => lighten(0.24, props.theme.color.red)};
		}
	`,
}

const warning = css`
	${box}
	${props => warningVariants[props.variant]}
`

const linkHover = css`
	&:hover:not(:disabled) {
		border-bottom-color: ${props => props.theme.color.blue};
	}
`

const link = css`
	color: ${props => props.theme.color.blue};
	border-bottom: 1px solid transparent;

	${props => !props.noHover && linkHover}
`

function def(variant, ...options) {
	return options.find(opt => opt === variant) || options[options.length - 1]
}

const ButtonBase = styled.button.attrs(({ role, variant }) => {
	switch (role) {
		case 'primary':
			variant = def(variant, 'text', 'contained')
			break
		case 'secondary':
			variant = def(variant, 'contained', 'text')
			break
		case 'warning':
			variant = def(variant, 'contained', 'text')
			break
		case 'link':
			variant = 'inline'
			break
		case 'default':
		default:
			role = 'default'
			variant = def(variant, 'text', 'outlined', 'search', 'contained')
			break
	}

	return {
		role,
		variant,
	}
})`
	padding: 0;
	border: none;
	background: none;
	font-size: 100%;
	color: inherit;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	&:focus {
		outline: none;
	}

	& > *:not(:last-child) {
		margin-right: 0.4em;
	}

	${props => {
		switch (props.role) {
			case 'primary':
				return primary
			case 'secondary':
				return secondary
			case 'warning':
				return warning
			case 'link':
				return link
			case 'default':
			default:
				return _default
		}
	}}

	&:disabled {
		cursor: not-allowed;
		filter: saturate(0.6) opacity(0.8) brightness(1.1);
	}
`

const Button = React.forwardRef(function Button({ children, ...props }, ref) {
	return (
		<ButtonBase ref={ref} {...props}>
			{wrapStrings(children)}
		</ButtonBase>
	)
})

// We wrap the component in a styled() factory to make it eligible for interpolation
// More info: https://www.styled-components.com/docs/advanced#caveat
export default styled(Button)``
