import React from 'react'
import styled, { keyframes } from 'styled-components'

const Wrapper = styled.div`
	display: flex;
	align-items: center;
`

const rotate1 = keyframes`
	to {
		transform: rotate(360deg);
	}
`

const Spinner = styled.div`
	height: ${props => props.size}px;
	width: ${props => props.size}px;
	border-radius: 50%;
	border: ${props => props.width}px solid ${props => props.color};
	border-top-color: transparent !important;
	border-bottom-color: transparent !important;
	animation: ${rotate1} 1000ms ease infinite;
`

const Msg = styled.div`
	margin-left: 0.8rem;
`

export default function Loading({
	children,
	wrapperStyle = {},
	style,
	size = 20,
	width = 2,
	color = '#1d9af2',
}) {
	return (
		<Wrapper style={wrapperStyle}>
			<Spinner style={style} size={size} width={width} color={color} />
			{children && <Msg>{children}</Msg>}
		</Wrapper>
	)
}
