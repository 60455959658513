import worker from '../../worker'
import { push } from 'react-router-redux'
import { createActionTypes, makeActionCreator as mac } from '@loque/reducks'
import notifications from '../notifications'
import { addToBasePath } from '../../index'

const types = createActionTypes(['ADMIN_UPDATE_SERVICES'])
const actions = {}

actions.adminUpdateServices = mac(types.ADMIN_UPDATE_SERVICES, 'services')

actions.adminGetServices = () => {
	return dispatch => {
		worker
			.get('/admin/services')
			.then(res => {
				dispatch(actions.adminUpdateServices(res.body))
			})
			.catch(res => {
				console.error(`Error fetching services`, res)
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al listar los servicios'
					)
				)
			})
	}
}

// { name: '', measures: [], factsTableName: '', dimensionGroups: [] }
actions.adminCreateService = service => {
	return dispatch => {
		dispatch(
			notifications.actions.notify(
				'create-service',
				'Creando servicio...'
			)
		)

		worker
			.post('/admin/services', service)
			.then(res => {
				dispatch(push(addToBasePath(`/admin/servicios`)))
				dispatch(notifications.actions.notifyEnd('create-service'))
			})
			.catch(res => {
				console.error(`Error creating the service`, res)
				dispatch(notifications.actions.notifyEnd('create-service'))
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al crear el servicio'
					)
				)
			})
	}
}

actions.adminUpdateService = (id, service) => {
	return dispatch => {
		dispatch(
			notifications.actions.notify(
				'update-service',
				'Actualizando servicio...'
			)
		)

		worker
			.put(`/admin/services/${id}`, service)
			.then(res => {
				dispatch(push(addToBasePath(`/admin/servicios`)))
				dispatch(notifications.actions.notifyEnd('update-service'))
			})
			.catch(res => {
				console.error(`Error updating the service`, res)
				dispatch(notifications.actions.notifyEnd('update-service'))
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al actualizar el servicio'
					)
				)
			})
	}
}

actions.adminDeleteService = id => {
	return dispatch => {
		dispatch(
			notifications.actions.notify(
				'delete-service',
				'Eliminando servicio...'
			)
		)

		worker
			.delete(`/admin/services/${id}`)
			.then(res => {
				dispatch(push(addToBasePath(`/admin/servicios`)))
				dispatch(notifications.actions.notifyEnd('delete-service'))
			})
			.catch(res => {
				console.error(`Error deleting the service`, res)
				dispatch(notifications.actions.notifyEnd('delete-service'))
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al eliminar el servicio'
					)
				)
			})
	}
}

export default {
	types,
	actions,
}
