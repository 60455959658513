import React from 'react'
import styled from 'styled-components'
import { FiAlertCircle as WarningIcon } from 'react-icons/fi'

const Wrapper = styled.div`
	color: ${props => props.theme.color.redD1};
	margin: 0;
	display: ${props => (props.shown ? 'block' : 'none')};
	align-items: center;
`
const Text = styled.p`
	margin-bottom: 0;
`

export default function ValidationMsg({ msg, shown }) {
	return (
		<Wrapper shown={shown}>
			<WarningIcon
				style={{
					width: '14px',
					height: '14px',
					minWidth: '14px',
					minHeight: '14px',
					marginRight: '.3rem',
					marginBottom: '-2px',
				}}
			/>
			{msg}
		</Wrapper>
	)
}
