import { asImmutable } from 'utils/immutable'

const initialState = asImmutable([
	{
		field_name: 'unidades',
		name: 'Unidades',
		format: 'unit',
	},
	{
		field_name: 'valores',
		name: 'Valores c/IVA',
		format: 'currency',
	},
	{
		field_name: 'valores_iva',
		name: 'Valores s/IVA',
		format: 'currency',
	},
	{
		field_name: 'importe',
		name: 'Importe',
		format: 'currency_2d',
	},
	{
		field_name: 'ajuste_lab',
		name: 'Ajuste Laboratorio',
		format: 'currency_2d',
	},
	{
		field_name: 'aporte_lab',
		name: 'Aporte Laboratorio',
		format: 'currency_2d',
	},
	{
		field_name: 'unidades_equivalentes',
		name: 'Unidades Equivalentes',
		format: 'unit',
	},
	{
		field_name: 'ioma_precio',
		name: 'Precio IOMA',
		format: 'currency_2d',
	},
	{
		field_name: 'pami_precio',
		name: 'Precio PAMI',
		format: 'currency_2d',
	},
	{
		field_name: 'precio_publico',
		name: 'Precio Público',
		format: 'currency_2d',
	},
	{
		field_name: 'kilogramos',
		name: 'Kilogramos',
		format: 'kilograms',
	},
	{
		field_name: 'litros',
		name: 'Litros',
		format: 'liters',
	},
])

export default {
	reducer: () => initialState,
}
