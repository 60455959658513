import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import { browserHistory } from 'react-router'
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux'

import ducks from './ducks'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
	ducks.reducer,
	composeEnhancers(applyMiddleware(thunk, routerMiddleware(browserHistory)))
)

const actions = ducks.actions

// initial fetch
store.dispatch(actions.sessionListeners())
store.dispatch(actions.userListeners())
store.dispatch(actions.notificationsListeners())
store.dispatch(actions.adminListeners())
store.dispatch(actions.tmpListeners())
store.dispatch(actions.userMultiExportPoll())

if (window) {
	for (const action in actions) {
		if (actions.hasOwnProperty(action) && action.startsWith('debug')) {
			if (!window.debug) {
				window.debug = {}
			}

			let actionName = action.replace('debug', '')
			actionName = actionName[0].toLowerCase() + actionName.slice(1)

			window.debug[actionName] = (...args) => {
				store.dispatch(actions[action](...args))
				return `action '${actionName}' dispatched`
			}
		}
	}
}

// More info:
// https://github.com/reactjs/react-router-redux/issues/301
// https://github.com/reactjs/react-router-redux#what-if-i-use-immutablejs-or-another-state-wrapper-with-my-redux-store
const createSelectLocationState = () => {
	let prevRoutingState, prevRoutingStateJS

	return (state) => {
		const routingState = state.get('routing')

		if (
			prevRoutingState === undefined ||
			prevRoutingState !== routingState
		) {
			prevRoutingState = routingState
			prevRoutingStateJS = routingState.toJS()
		}

		return prevRoutingStateJS
	}
}

export const history = syncHistoryWithStore(browserHistory, store, {
	selectLocationState: createSelectLocationState(),
})
