import styled, { css } from 'styled-components'

// https://github.com/material-components/material-components-web/tree/master/packages/mdc-elevation

const Elevation1 = css`
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
		0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
`

const Elevation3 = css`
	box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
		0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
`

const Elevation12 = css`
	box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
		0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12);
`

const Elevation24 = css`
	box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
		0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
`

function padding({ noVPadding, noHPadding }) {
	return `${noVPadding ? '0' : '0.5em'} ${noHPadding ? '0' : '0.5em'}`
}

export default styled.div`
	background: ${props => props.theme.color.white};
	padding: ${padding};
	border-radius: 6px;

	${props => {
		if (props.elevation === null || props.elevation === undefined) return

		switch (props.elevation) {
			case 1:
				return Elevation1
			case 3:
				return Elevation3
			case 12:
				return Elevation12
			case 24:
				return Elevation24
		}
	}}
`
