import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ducks from 'front/ducks'

import Modal from 'ui/Modal'
import ModalTitle from 'ui/Modal/Title'
import ModalBody from 'ui/Modal/Body'
import ModalActions from 'ui/Modal/Actions'
import MenuButton from 'ui/Dropdown/MenuButton'
import Loading from 'ui/Loading'
import { FiCopy as DuplicateIcon } from 'react-icons/fi'
import { useAction } from '~/modules/utils/redux'
import Button from '~/frontend/ui/Button'

function DuplicateReportModal({
	reportId,
	folderId = null,
	reportCode,
	visible,
	setVisible,
}) {
	const [internalVisible, setInternalVisible] = useState(visible)

	const { requestId, loading, success, error } = useSelector(state =>
		state.getIn(['queries', 'duplicate']).toJS()
	)

	const duplicate = useAction(ducks.actions.queriesDuplicate)
	const reset = useAction(ducks.actions.queriesDuplicateReset)

	useEffect(() => {
		console.log({ reportId, folderId, internalVisible })
	}, [reportId, folderId, internalVisible])

	useEffect(() => {
		if (
			internalVisible === true &&
			loading === false &&
			success === false &&
			error === null
		) {
			duplicate(reportId, folderId)
		}
	}, [
		internalVisible,
		loading,
		success,
		error,
		duplicate,
		reportId,
		folderId,
	])

	// automatically hide modal when request is successfull
	useEffect(() => {
		if (internalVisible && success && requestId === reportId) {
			setInternalVisible(false)
		}
	}, [internalVisible, success, requestId, reportId])

	// we delay the reset so that it happens once the modal is hidden
	useEffect(() => () => reset(), [])

	return (
		<Modal
			visible={internalVisible}
			onDestroyed={() => {
				// if (success && requestId === reportId) {
				// 	confirmDeleteReport(reportId)
				// }
				setVisible(false)
			}}
		>
			<ModalTitle>
				Duplicando {reportCode === 'market' ? 'mercado' : 'consulta'}
			</ModalTitle>
			<ModalBody>
				{loading && <Loading>Procesando solicitud...</Loading>}
				{error && (
					<p>¡Tuvimos un error! Por favor, volvé a intentarlo.</p>
				)}
			</ModalBody>
			{error && (
				<ModalActions>
					<Button onClick={() => setInternalVisible(false)}>
						Cerrar
					</Button>
				</ModalActions>
			)}
		</Modal>
	)
}

export default function useDuplicateReport(reportId, folderId = null) {
	const [visible, setVisible] = useState(false)

	const reportCode = useSelector(state => {
		const { organizationId, serviceId } = state.get('session').toJS()
		return state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
			'report_code',
		])
	})

	const modal = visible && (
		<DuplicateReportModal
			reportId={reportId}
			folderId={folderId}
			reportCode={reportCode}
			visible={visible}
			setVisible={setVisible}
		/>
	)

	const button = (
		<MenuButton onClick={() => setVisible(true)} icon={<DuplicateIcon />}>
			Duplicar {reportCode === 'market' ? 'mercado' : 'consulta'}
		</MenuButton>
	)

	return { button, modal }
}
