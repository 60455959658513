import { createActionTypes, makeActionCreator as mac } from '@loque/reducks'
import worker from '../worker'

const types = createActionTypes(['DIMENSIONS_UPDATE', 'FILTERS_UPDATE'])

const actions = {}

actions.dimensionsUpdate = mac(types.DIMENSIONS_UPDATE, 'dimensions')
actions.filtersUpdate = mac(types.FILTERS_UPDATE, 'filters')

actions.dimensionsFetch = () => {
	return (dispatch, getState) => {
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])
		worker
			.get(
				`/form/dimension?organizationId=${organizationId}&serviceId=${serviceId}`
			)
			.then(res => dispatch(actions.dimensionsUpdate(res.body)))
			.catch(error => {
				if (process.env.NODE_ENV !== 'production') {
					console.error(`error fetching dimensions`, error)
				}
			})
	}
}

actions.filtersFetch = () => {
	return (dispatch, getState) => {
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])
		worker
			.get(
				`/form/filter?organizationId=${organizationId}&serviceId=${serviceId}`
			)
			.then(res => dispatch(actions.filtersUpdate(res.body)))
			.catch(error => {
				if (process.env.NODE_ENV !== 'production') {
					console.error(`error fetching filters`, error)
				}
			})
	}
}

const simpleOptions = {}

actions.getSimpleOptions = dimensionId => {
	return (_, getState) => {
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		const key = JSON.stringify({ dimensionId, organizationId, serviceId })

		if (simpleOptions.hasOwnProperty(key))
			return Promise.resolve(simpleOptions[key])

		return worker
			.put(`/form/filters/simple`, {
				dimensionId,
				organizationId,
				serviceId,
			})
			.then(res => {
				simpleOptions[key] = res.body
				return res.body
			})
			.catch(error => {
				if (process.env.NODE_ENV !== 'production') {
					console.error(`error fetching simple options`, error)
				}
			})
	}
}

const combinedOptions = {}

actions.getCombinedOptions = (topdimensionId, subdimensionId) => {
	return (dispatch, getState) => {
		const key = JSON.stringify({
			topdimensionId,
			subdimensionId,
		})

		if (combinedOptions.hasOwnProperty(key))
			return Promise.resolve(combinedOptions[key])

		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		return worker
			.put(`/form/filters/combined`, {
				topdimensionId,
				subdimensionId,
				organizationId,
				serviceId,
			})
			.then(res => {
				combinedOptions[key] = res.body
				return res.body
			})
			.catch(error => {
				if (process.env.NODE_ENV !== 'production') {
					console.error(`error fetching combined options`, error)
				}
			})
	}
}

export default {
	types,
	actions,
}
