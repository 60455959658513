import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import ducks from 'front/ducks'

import Modal from 'ui/Modal'
import ModalTitle from 'ui/Modal/Title'
import ModalBody from 'ui/Modal/Body'
import ModalActions from 'ui/Modal/Actions'
import MenuButton from 'ui/Dropdown/MenuButton'
import Loading from 'ui/Loading'
import Button from 'ui/Button'
import { FiShare2 as ShareIcon, FiX as XIcon } from 'react-icons/fi'
import Input from 'ui/Forms/Input'
import { useAction } from '~/modules/utils/redux'

const ChipWrapper = styled.div`
	display: inline-flex;
	background: #e3eef3;
	color: #0288d1;
	border-radius: 10px;
	padding: 4px 8px;
	align-items: center;

	&:not(:last-of-type) {
		margin-bottom: 0.5rem;
	}
`
const ChipLabel = styled.div`
	flex: 1;
	margin-right: 0.5rem;
	user-select: none;
`
const ChipRemove = styled.div`
	border-radius: 50%;
	background-color: #0288d1;
	color: #e3eef3db;
	font-size: 0.8rem;
	cursor: pointer;
	display: flex;
	padding: 2px;
`

function Chip({ children, onClick }) {
	return (
		<ChipWrapper>
			<ChipLabel>{children}</ChipLabel>
			<ChipRemove onClick={onClick}>
				<XIcon />
			</ChipRemove>
		</ChipWrapper>
	)
}

const InputCombo = styled.div`
	display: flex;
	& > input {
		flex: 1;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	& > button {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: none;
	}
`

export default function useShareFolder(folderId, folderName) {
	const [visible, setVisible] = useState(false)

	const { loading, error, targets } = useSelector(state =>
		state.getIn(['folders', 'targets']).toJS()
	)

	const reportCode = useSelector(state => {
		const { organizationId, serviceId } = state.get('session').toJS()
		return state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
			'report_code',
		])
	})

	const inputRef = useRef()
	const [inputVal, setInputVal] = useState('')
	const [users, setUsers] = useState([])

	useEffect(() => {
		if (
			loading === false &&
			error === null &&
			targets.length &&
			!users.length
		) {
			setUsers(targets)
		}
	}, [loading, error, targets, users])

	const selectedUsers = users.filter(usr => usr.has_access)
	const unselectedUsers = users.filter(usr => !usr.has_access)

	const foldersGetTargets = useAction(ducks.actions.foldersGetTargets)
	const foldersUpdateTargets = useAction(ducks.actions.foldersUpdateTargets)
	const foldersTargetsReset = useAction(ducks.actions.foldersTargetsReset)

	useEffect(() => {
		if (
			visible === true &&
			loading === false &&
			error === null &&
			!targets.length
		) {
			foldersGetTargets(folderId)
		}
	}, [visible, loading, error, targets, foldersGetTargets])

	function emailExists(email) {
		return !!users.find(usr => usr.email === (email || inputVal))
	}

	function emailBelongsToSelectedUser(email) {
		return !!users.find(
			usr => usr.email === (email || inputVal) && usr.has_access === true
		)
	}

	function addUserToSelection(email) {
		if (emailExists(email) && !emailBelongsToSelectedUser(email)) {
			setUsers(usrs =>
				usrs.map(usr => {
					if (usr.email === email) {
						usr.has_access = true
					}
					return usr
				})
			)
			setInputVal('')
		}
	}

	function removeUserFromSelection(email) {
		setUsers(usrs =>
			usrs.map(usr => {
				if (usr.email === email) {
					usr.has_access = false
				}
				return usr
			})
		)
	}

	function hide() {
		foldersTargetsReset()
		setUsers([])
		setVisible(false)
	}

	function runSecondaryAction(event) {
		event && event.preventDefault()
		hide()
	}

	function runPrimaryAction(event) {
		event.preventDefault()
		foldersUpdateTargets(folderId, selectedUsers)
		hide()
	}

	const modal = (
		<Modal
			visible={visible}
			onVisibilityChange={value => setVisible(value)}
		>
			<ModalTitle>
				Compartir la {reportCode === 'market' ? 'línea' : 'carpeta'}{' '}
				<i>{folderName}</i>
			</ModalTitle>
			<ModalBody>
				{loading && <Loading>Cargando lista de usuarios...</Loading>}
				{!loading && (
					<React.Fragment>
						<form
							onSubmit={event => {
								event.preventDefault()
								addUserToSelection(inputVal)
								inputRef.current.blur()
							}}
						>
							<InputCombo>
								<Input
									ref={inputRef}
									list="users"
									value={inputVal}
									placeholder="Buscar usuario por email..."
									onChange={event => {
										setInputVal(event.target.value)
									}}
								/>
								<Button
									role="secondary"
									disabled={
										!emailExists() ||
										emailBelongsToSelectedUser()
									}
								>
									Agregar
								</Button>

								<datalist id="users">
									{unselectedUsers.map(usr => (
										<option
											key={usr.id}
											value={usr.email}
										/>
									))}
								</datalist>
							</InputCombo>
						</form>
						<div
							style={{
								paddingTop: '1rem',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}
						>
							{selectedUsers.map(usr => (
								<Chip
									key={usr.id}
									onClick={() =>
										removeUserFromSelection(usr.email)
									}
								>
									{usr.email}
								</Chip>
							))}
						</div>
					</React.Fragment>
				)}
			</ModalBody>
			<ModalActions>
				<Button role="secondary" onClick={runSecondaryAction}>
					Cancelar
				</Button>
				<Button role="primary" onClick={runPrimaryAction}>
					Guardar
				</Button>
			</ModalActions>
		</Modal>
	)
	const button = (
		<MenuButton onClick={() => setVisible(true)} icon={<ShareIcon />}>
			Compartir {reportCode === 'market' ? 'línea' : 'carpeta'}
		</MenuButton>
	)

	return { button, modal }
}
