import React from 'react'
import { withRouter } from 'react-router'
import ducks from 'front/ducks'
import { addToBasePath } from '../index'

import styled from 'styled-components'

import {
	FiSettings as SettingsIcon,
	FiHome as ReportsIcon,
	FiLogOut as LogOutIcon,
} from 'react-icons/fi'

import NavBarSection from 'ui/NavBar/NavBarSection'
import NavBarSeparator from 'ui/NavBar/NavBarSeparator'
import NavBarItem from 'ui/NavBar/NavBarItem'

import ButtonUnstyledBase from 'ui/Button/Unstyled'
import { Row } from 'ui/ReportForm'
import { useAction } from '~/modules/utils/redux'
import { useSelector } from 'react-redux'

const ButtonUnstyled = styled(ButtonUnstyledBase)`
	display: inline-flex;
	justify-content: center;
	align-items: center;
`

const providers = {
	sos: {
		logo: {
			url: 'logo_sos_pharma_min.png',
			width: 136,
			height: 26,
		},
	},
	m2: {
		logo: {
			url: 'logoM2b_min.png',
			width: 218,
			height: 35,
		},
	},
}
const provider = providers[window.__PROVIDER]

export default withRouter(function NavBarHeader({ router }) {
	const userLogout = useAction(ducks.actions.userLogout)
	const loggedIn = useSelector(state => state.getIn(['session', 'login']))
	const userIsAdmin = useSelector(state => state.getIn(['user', 'is_admin']))
	const userEmail = useSelector(state => state.getIn(['user', 'email']))

	function handleLogout() {
		userLogout()
	}

	let reportsPanelButton, adminPanelButton

	if (
		loggedIn &&
		userIsAdmin &&
		router.location.pathname.includes('/admin') === false
	) {
		adminPanelButton = (
			<ButtonUnstyled
				onClick={() => router.push(addToBasePath('/admin'))}
				title="Panel de administración"
			>
				<SettingsIcon />
			</ButtonUnstyled>
		)
	}

	if (router.location.pathname.includes('/admin') === true) {
		reportsPanelButton = (
			<ButtonUnstyled
				onClick={e => router.push(addToBasePath(''))}
				title="Reportes"
			>
				<ReportsIcon />
			</ButtonUnstyled>
		)
	}

	return (
		<React.Fragment>
			<NavBarSection>
				<NavBarItem style={{ marginBottom: '1rem' }}>
					<img
						src={`/img/${provider.logo.url}`}
						width={provider.logo.width}
						height={provider.logo.height}
					/>
				</NavBarItem>
				<NavBarItem>
					<Row style={{ paddingRight: '1.2rem' }} separation="0.5rem">
						<div
							style={{
								flex: 1,
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
							title={userEmail}
						>
							{userEmail}
						</div>
						{adminPanelButton}
						{reportsPanelButton}
						<ButtonUnstyled
							onClick={handleLogout}
							title="Cerrar sesión"
						>
							<LogOutIcon />
						</ButtonUnstyled>
					</Row>
				</NavBarItem>
			</NavBarSection>
			<NavBarSeparator />
		</React.Fragment>
	)
})
