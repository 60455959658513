import { makeActionCreator as mac } from '@loque/reducks'
import types from './actionTypes'

const actions = {}

actions.queriesSetQueryConfigId = mac(
	types.QUERIES_SET_QUERY_CONFIG_ID,
	'queryConfigId'
)
actions.queriesSetLoadingQueryConfig = mac(
	types.QUERIES_SET_LOADING_QUERY_CONFIG,
	'loading'
)
actions.queriesSetLoadingQueries = mac(
	types.QUERIES_SET_LOADING_QUERIES,
	'loading'
)

import worker from '../../worker'
import FileSaver from 'file-saver'
import uuid from 'uuid'
import { asImmutable } from 'utils/immutable'

import notifications from '../notifications'
import nav from '../nav'
import queryPage from '../queryPage'
import liveFiltersDuck from '../liveFilters'
import queriesPresProd from '../queriesPresProd'
import { initialState as initialLiveFilters } from '../liveFilters/reducer'

function setOnlyExport(query) {
	return query.set(
		'only_export',
		!!query
			.getIn(['config', 'columns'])
			.find(
				slot => slot.get('type') === 'period',
				null,
				asImmutable({ fields: [] })
			)
			.get('fields')
			.find(field => field.get('field_name') === 'meses24')
	)
}

actions.queriesCreate = query => {
	return (dispatch, getState) => {
		if (!getState().getIn(['session', 'online'])) return
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		query = query.set('id', uuid.v4())
		query = setOnlyExport(query)

		const queryTmp = query.set('loading', 'creating-query')
		const liveFilters = initialLiveFilters.toJS()

		dispatch(actions.queriesSetQueryConfigId(query.get('id')))

		dispatch(
			notifications.actions.notify(
				'query-config-create',
				`Guardando configuración`
			)
		)
		dispatch(actions.queriesSetLoadingQueryConfig(true))

		worker
			.post('/queries', {
				query: query.toJS(),
				liveFilters,
				organizationId,
				serviceId,
			})
			.then(res => {
				dispatch(notifications.actions.notifyEnd('query-config-create'))
				dispatch(actions.queriesSetLoadingQueryConfig(false))

				dispatch(liveFiltersDuck.actions.filtersResetAll())
				if (!query.get('only_export')) {
					dispatch(queryPage.actions.queryPageSetLoading(true))
					dispatch(queryPage.actions.queryPageSetNumber(1))
					dispatch(queryPage.actions.queryPageGet(res.body))
				}

				dispatch(nav.actions.navToQuery(queryTmp.get('id')))
			})
			.catch(res => {
				console.error(`Error creating query`, res)

				dispatch(notifications.actions.notifyEnd('query-config-create'))
				dispatch(actions.queriesSetLoadingQueryConfig(false))

				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al generar la consulta'
					)
				)
			})
	}
}

actions.queriesUpdate = query => {
	return (dispatch, getState) => {
		if (!getState().getIn(['session', 'online'])) return
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		query = setOnlyExport(query)
		const liveFilters = initialLiveFilters.toJS()

		dispatch(
			notifications.actions.notify(
				'query-config-update',
				`Guardando configuración`
			)
		)
		dispatch(actions.queriesSetLoadingQueryConfig(true))

		console.log('query to update', query.toJS())

		worker
			.put(`/queries/${query.get('id')}`, {
				query: query.toJS(),
				liveFilters,
				organizationId,
				serviceId,
			})
			.then(res => {
				dispatch(notifications.actions.notifyEnd('query-config-update'))
				dispatch(actions.queriesSetLoadingQueryConfig(false))

				dispatch(liveFiltersDuck.actions.filtersResetAll())
				dispatch(queriesPresProd.actions.queriesPresProdClear())

				if (query.get('only_export') !== true) {
					dispatch(queryPage.actions.queryPageSetLoading(true))
					dispatch(queryPage.actions.queryPageSetNumber(1))
					dispatch(queryPage.actions.queryPageGet(res.body))
				}

				dispatch(nav.actions.navToQuery(query.get('id')))
			})
			.catch(error => {
				console.error(`Error updating query`, error)

				dispatch(notifications.actions.notifyEnd('query-config-update'))
				dispatch(actions.queriesSetLoadingQueryConfig(false))

				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al actualizar la consulta'
					)
				)
			})
	}
}

actions.queriesUpdateFolderInQuery = (queryConfigId, folderId) => {
	return (dispatch, getState) => {
		if (!getState().getIn(['session', 'online'])) return
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		dispatch(
			notifications.actions.notify(
				'query-config-folder',
				`Guardando cambios`
			)
		)

		return worker
			.put(`/queries/${queryConfigId}/folder`, {
				folderId,
				organizationId,
				serviceId,
			})
			.then(() => {
				dispatch(notifications.actions.notifyEnd('query-config-folder'))
			})
			.catch(error => {
				console.error(`Error updating query folder`, error)

				dispatch(notifications.actions.notifyEnd('query-config-folder'))

				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al actualizar la carpeta de la consulta'
					)
				)
			})
	}
}

actions.queriesUpdateUnidadesEquivalentes = (query, unidadesEquivalentes) => {
	return (dispatch, getState) => {
		if (!getState().getIn(['session', 'online'])) return
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		dispatch(
			notifications.actions.notify(
				'query-config-unidades',
				`Guardando configuración`
			)
		)
		dispatch(actions.queriesSetLoadingQueryConfig(true))

		return worker
			.put(`/queries/${query.get('id')}/unidades-equivalentes`, {
				query: query.toJS(),
				unidadesEquivalentes,
				organizationId,
				serviceId,
			})
			.then(res => {
				dispatch(
					notifications.actions.notifyEnd('query-config-unidades')
				)
				dispatch(actions.queriesSetLoadingQueryConfig(false))

				dispatch(queryPage.actions.queryPageSetLoading(true))
				dispatch(queryPage.actions.queryPageSetNumber(1))
				dispatch(queryPage.actions.queryPageGet(res.body))

				dispatch(nav.actions.navToQuery(query.get('id')))
			})
			.catch(error => {
				console.error(`Error updating query`, error)

				dispatch(
					notifications.actions.notifyEnd('query-config-unidades')
				)
				dispatch(actions.queriesSetLoadingQueryConfig(false))

				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al actualizar la consulta'
					)
				)
			})
	}
}

worker.transport.raw.addEventListener('message', msg => {
	if (!msg.data.hasOwnProperty('custom')) return
	if (!['xlsx', 'zip'].includes(msg.data.custom)) return

	FileSaver.saveAs(msg.data.file, msg.data.filename)
})

actions.queriesGetFile = queryConfig => {
	return (dispatch, getState) => {
		dispatch(
			notifications.actions.notify(
				'export-xls',
				`Exportando "${queryConfig.get('name')}"`
			)
		)

		const liveFilters = initialLiveFilters.toJS()
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		worker
			.put(`/queries/${queryConfig.get('id')}/file`, {
				queryConfig: queryConfig.toJS(),
				liveFilters,
				organizationId,
				serviceId,
			})
			.then(() => {
				dispatch(notifications.actions.notifyEnd('export-xls'))
			})
			.catch(error => {
				dispatch(notifications.actions.notifyEnd('export-xls'))
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al exportar los resultados'
					)
				)

				if (process.env.NODE_ENV !== 'production') {
					console.error(`Error getting file`, error)
				}
			})
	}
}

actions.queriesGetFolderTargetsRequest = mac(
	types.QUERIES_GET_FOLDER_TARGETS_REQUEST,
	'requestId'
)
actions.queriesGetFolderTargetsSuccess = mac(
	types.QUERIES_GET_FOLDER_TARGETS_SUCCESS,
	'targets'
)
actions.queriesGetFolderTargetsError = mac(
	types.QUERIES_GET_FOLDER_TARGETS_ERROR,
	'error'
)
actions.queriesGetFolderTargetsReset = mac(
	types.QUERIES_GET_FOLDER_TARGETS_RESET
)
actions.queriesGetFolderTargets = (queryConfigId, currFolderId) => {
	return (dispatch, getState) => {
		dispatch(actions.queriesGetFolderTargetsRequest(queryConfigId))
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		worker
			.get(
				`/queries/${queryConfigId}/possible-folder-targets?currFolderId=${currFolderId}&organizationId=${organizationId}&serviceId=${serviceId}`
			)
			.then(res => {
				dispatch(actions.queriesGetFolderTargetsSuccess(res.body))
			})
			.catch(res => {
				console.error(`Error getting possible folder targets`, res)
				dispatch(
					actions.queriesGetFolderTargetsError(
						(res.body && res.body.error && res.body.error) || res
					)
				)
			})
	}
}

actions.queriesGetShareTargetsRequest = mac(
	types.QUERIES_GET_SHARE_TARGETS_REQUEST,
	'requestId'
)
actions.queriesGetShareTargetsSuccess = mac(
	types.QUERIES_GET_SHARE_TARGETS_SUCCESS,
	'targets'
)
actions.queriesGetShareTargetsError = mac(
	types.QUERIES_GET_SHARE_TARGETS_ERROR,
	'error'
)
actions.queriesGetShareTargetsReset = mac(types.QUERIES_GET_SHARE_TARGETS_RESET)

actions.queriesGetShareTargets = (queryConfigId, organizationId, serviceId) => {
	return dispatch => {
		dispatch(actions.queriesGetShareTargetsRequest(queryConfigId))

		worker
			.get(
				`/user/query/${queryConfigId}?organizationId=${organizationId}&serviceId=${serviceId}`
			)
			.then(res => {
				dispatch(actions.queriesGetShareTargetsSuccess(res.body))
			})
			.catch(res => {
				console.error(`Error getting possible share targets`, res)
				dispatch(
					actions.queriesGetShareTargetsError(
						(res.body && res.body.error && res.body.error) || res
					)
				)
			})
	}
}

actions.queriesDuplicateRequest = mac(
	types.QUERIES_DUPLICATE_REQUEST,
	'requestId'
)
actions.queriesDuplicateSuccess = mac(types.QUERIES_DUPLICATE_SUCCESS)
actions.queriesDuplicateError = mac(types.QUERIES_DUPLICATE_ERROR, 'error')
actions.queriesDuplicateReset = mac(types.QUERIES_DUPLICATE_RESET)
actions.queriesDuplicate = (queryConfigId, folderId) => {
	return (dispatch, getState) => {
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		dispatch(actions.queriesDuplicateRequest(queryConfigId))

		worker
			.put(`/queries/${queryConfigId}/duplicate`, {
				folderId,
				organizationId,
				serviceId,
			})
			.then(() => {
				dispatch(actions.queriesDuplicateSuccess())
			})
			.catch(res => {
				console.error(`Error duplicating query`, res)
				dispatch(
					actions.queriesDuplicateError(
						(res.body && res.body.error && res.body.error) || res
					)
				)
			})
	}
}

actions.queriesPutFolderRequest = mac(
	types.QUERIES_PUT_FOLDER_REQUEST,
	'requestId'
)
actions.queriesPutFolderSuccess = mac(types.QUERIES_PUT_FOLDER_SUCCESS)
actions.queriesPutFolderError = mac(types.QUERIES_PUT_FOLDER_ERROR, 'error')
actions.queriesPutFolderReset = mac(types.QUERIES_PUT_FOLDER_RESET)

actions.queriesPutFolder = (queryConfigId, folderId) => {
	return dispatch => {
		dispatch(actions.queriesPutFolderRequest(queryConfigId))

		worker
			.put(`/queries/${queryConfigId}/folder`, {
				folderId,
			})
			.then(() => {
				dispatch(actions.queriesPutFolderSuccess())
			})
			.catch(res => {
				console.error(`Error putting query folder`, res)
				dispatch(
					actions.queriesPutFolderError(
						(res.body && res.body.error && res.body.error) || res
					)
				)
			})
	}
}

actions.queriesPutFolderConfirm = queryConfigId => {
	return (_, getState) => {
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		worker.put(`/queries/${queryConfigId}/folder/confirm`, {
			organizationId,
			serviceId,
		})
	}
}

actions.queriesDeleteRequest = mac(types.QUERIES_DELETE_REQUEST, 'requestId')
actions.queriesDeleteSuccess = mac(types.QUERIES_DELETE_SUCCESS)
actions.queriesDeleteError = mac(types.QUERIES_DELETE_ERROR, 'error')
actions.queriesDeleteReset = mac(types.QUERIES_DELETE_RESET)

actions.queriesDelete = query => {
	return (dispatch, getState) => {
		dispatch(actions.queriesDeleteRequest(query.id))
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		worker
			.delete(
				`/queries/${query.id}?organizationId=${organizationId}&serviceId=${serviceId}`
			)
			.then(() => {
				dispatch(actions.queriesDeleteSuccess())
			})
			.catch(res => {
				console.error(`Error deleting report`, res)
				dispatch(
					actions.queriesDeleteError(
						(res.body && res.body.error && res.body.error) || res
					)
				)
			})
	}
}

actions.queriesDeleteConfirm = queryConfigId => {
	return (_, getState) => {
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])
		worker.delete(
			`/queries/${queryConfigId}/confirm?organizationId=${organizationId}&serviceId=${serviceId}`
		)
	}
}

export default actions
