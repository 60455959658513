// @flow

import { Map, List, fromJS, Set } from 'immutable'

export function isImmutable(obj: Object) {
	return Map.isMap(obj) || List.isList(obj)
}

export function asImmutable(
	obj: Object,
	notSetValue = undefined,
	allowStringValue = true
) {
	if (obj === undefined) obj = notSetValue

	if (typeof obj === 'string' && allowStringValue === false) {
		obj = notSetValue
	}

	if (!isImmutable(obj)) {
		obj = fromJS(obj)
	}

	return obj
}

export function asJS(obj: Object) {
	return isImmutable(obj) ? obj.toJS() : obj
}

export function mergeDeep(objA: Object, objB: Object) {
	return asImmutable(objA, {}).mergeDeep(asImmutable(objB, {}))
}

export function mergeDeepToJS(objA: Object, objB: Object) {
	return mergeDeep(objA, objB).toJS()
}

export function updateState(objB: Object, toImmutable: boolean = false) {
	if (toImmutable) return (objA: Object) => mergeDeep(objA, objB)
	return (objA: Object) => mergeDeepToJS(objA, objB)
}

export function equals(objA: Object, objB: Object) {
	return asImmutable(objA).equals(asImmutable(objB))
}

export function keyIn(...keys) {
	const keySet = Set(keys)

	return (v, k) => keySet.has(k)
}

function copyUntil(graph, toKey) {
	return graph
		.filter((v, k) => {
			return k !== toKey
		})
		.map(item => {
			if (isImmutable(item)) {
				item = copyUntil(item, toKey)
			}
			return item
		})
}

export function getGraphInterval(graph, fromKeys, toKey = null) {
	const subGraph = graph.getIn(fromKeys, asImmutable([]))

	if (toKey === null) return subGraph

	return copyUntil(subGraph, toKey)
}

export function toListIn(graph, levels: Array<string>) {
	const currLevel = levels[0]
	const nextLevels = levels.slice(1)

	return graph.map((v, k) => {
		if (currLevel === '__skip__') {
			return toListIn(v, nextLevels)
		}

		if (k === currLevel) {
			const _v = Map.isMap(v) ? v.toList() : v

			return toListIn(_v, nextLevels)
		}

		return v
	})
}

export function findIn(obj, keyPath, predicate) {
	return obj.getIn(keyPath).find(predicate)
}

export function removeKeyDeepImmutable(obj, keyToRemove) {
	return asImmutable(obj)
		.delete(keyToRemove)
		.map((value, key) => {
			if (isImmutable(value)) {
				value = removeKeyDeepImmutable(value, keyToRemove)
			}

			return value
		})
}

export function removeKeyDeep(obj, keyToRemove) {
	return removeKeyDeepImmutable(obj, keyToRemove).toJS()
}

export function subtract_(objA, objB) {
	objA = asImmutable(objA)
	objB = asImmutable(objB, asImmutable({}))

	let result = asImmutable({})

	objA.forEach((itemObjA, key) => {
		if (!objB.has(key)) {
			result = result.set(key, itemObjA)
			return
		}

		const isListItem = Map.isMap(itemObjA) && itemObjA.has('id')

		if (isListItem) {
			let item = asImmutable({})

			itemObjA.forEach((prop, propKey) => {
				if (Map.isMap(prop)) {
					item = item.set(
						propKey,
						subtract(prop, objB.getIn([key, propKey]))
					)
					return
				}

				item = item.set(propKey, prop)
			})

			result = result.set(key, item)
		}
	})

	return result
}

const propIsList = prop =>
	Map.isMap(prop) && Map.isMap(prop.first()) && prop.first().has('id')

export function subtractGraph(objA, objB) {
	objA = asImmutable(objA)
	objB = asImmutable(objB, asImmutable({}))

	// console.group('subtractGraph')

	let result = asImmutable({})

	const isList =
		Map.isMap(objA) && Map.isMap(objA.first()) && objA.first().has('id')
	// const isListItem = Map.isMap(objA) && objA.has('id')

	// console.log(`A/B`, {
	// 	objA: asJS(objA),
	// 	objB: asJS(objB),
	// 	isList,
	// 	isListItem,
	// })

	if (isList) {
		objA.forEach((item, id) => {
			if (!objB.has(id)) {
				result = result.set(id, item)
				return
			}

			const _r = subtractGraph(item, objB.get(id))

			if (_r !== undefined) {
				result = result.set(id, _r)
			}
		})

		if (result.size === 0) {
			result = undefined
		}

		return result
	}

	// isListItem === true

	const listInItem = objA.find(propIsList)
	const listKeyInItem = objA.findKey(propIsList)

	// objA does not have a list as child, that means that from top to bottom we have the same tree
	if (listInItem === undefined) return

	const listInItemGraph = subtractGraph(listInItem, objB.get(listKeyInItem))

	if (listInItemGraph === undefined) return

	result = result.set(listKeyInItem, listInItemGraph)

	objA.forEach((item, id) => {
		if (
			!Map.isMap(item) ||
			!Map.isMap(item.first()) ||
			!item.first().has('id')
		) {
			result = result.set(id, item)
			return
		}
	})

	// console.log(`result`, asJS(result))
	// console.groupEnd('subtractGraph')

	return result
}

export function getRegex(obj, regex) {
	if (!Map.isMap(obj)) return

	return obj.filter((value, key) => {
		return key.match(regex) !== null
	})
}

export function getWithPrefix(obj, prefix, removePrefix = false) {
	if (!Map.isMap(obj)) return

	const _obj = obj.filter((value, key) => {
		return key.startsWith(prefix)
	})

	if (removePrefix === false) return _obj

	return _obj.reduce((acc, value, key) => {
		const _key = key.replace(prefix, '')

		return acc.set(_key, value)
	}, Map({}))
}

export function addPrefix(obj, prefix) {
	if (!Map.isMap(obj)) return

	return obj.reduce((acc, value, key) => {
		return acc.set(`${prefix}${key}`, value)
	}, Map({}))
}

export function uniqueByItemProp(list, prop) {
	if (!List.isList(list)) return

	return list.reduce((acc, item, idx) => {
		// item does not have prop, exclude it
		if (!item.has(prop)) return acc

		// acc already has item.prop, so ignore this one
		if (acc.find(_item => _item.get(prop) === item.get(prop))) return acc

		// acc does not have item.prop, add it
		acc = acc.push(item)

		return acc
	}, List([]))
}
