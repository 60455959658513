import { createActionTypes } from '@loque/reducks'
import worker from '../worker'
import { asImmutable } from 'utils/immutable'

const types = createActionTypes(['FOLDERS_CREATE_REQUEST'])

const initialState = asImmutable({})

function reducer(state = initialState) {
	return state
}

const actions = {}

actions.debugReport = reportId => {
	return (_, getState) => {
		if (!reportId) {
			reportId = getState().getIn(['queries', 'queryConfigId'])
		}
		if (!reportId) {
			return console.log(
				`could not debug report because it's id was not found`,
				{ reportId }
			)
		}
		const groupTitle = `debug report ${reportId}`
		console.group(groupTitle)
		worker
			.get(`/debug/report/${reportId}`)
			.then(res => {
				console.info(`success`)
				console.log(res.body)
				console.groupEnd(groupTitle)
			})
			.catch(res => {
				console.info(`error`)
				console.error(res)
				console.groupEnd(groupTitle)
			})
	}
}

export default {
	types,
	reducer,
	actions,
}
