import { makeActionCreator as mac } from '@loque/reducks'
import types from './actionTypes'
import { asImmutable } from 'utils/immutable'
import { initialState as emptyQueryConfig } from './reducer'

const actions = {}

actions.queryTmpLoad = mac(types.QUERY_TMP_LOAD, 'query')
actions.queryTmpSetBlocked = mac(types.QUERY_TMP_SET_BLOCKED, 'key', 'value')

actions.queryTmpSetDatefield = mac(types.QUERY_TMP_SET_DATEFIELD, 'date')
actions.queryTmpNameUpdate = mac(types.QUERY_TMP_NAME_UPDATE, 'name')
actions.queryTmpDimensionAddSlot = mac(
	types.QUERY_TMP_DIMENSION_ADD_SLOT,
	'axis',
	'slotType',
	'firstField'
)
actions.queryTmpDimensionRemoveSlot = mac(
	types.QUERY_TMP_DIMENSION_REMOVE_SLOT,
	'axis',
	'slotIndex'
)
actions.queryTmpDimensionUpdateSlots = mac(
	types.QUERY_TMP_DIMENSION_UPDATE_SLOTS,
	'axis',
	'slots'
)
actions.queryTmpDimensionUpdateSlotBlock = mac(
	types.QUERY_TMP_DIMENSION_UPDATE_SLOT_BLOCK,
	'axis',
	'slotIndex',
	'blocked'
)

actions.queryTmpInvertPeriodMeasure = mac(types.QUERY_TMP_INVERT_PERIOD_MEASURE)

actions.queryTmpDimensionAddField = mac(
	types.QUERY_TMP_DIMENSION_ADD_FIELD,
	'axis',
	'slotType',
	'newField'
)
actions.queryTmpDimensionRemoveField = mac(
	types.QUERY_TMP_DIMENSION_REMOVE_FIELD,
	'axis',
	'slotType',
	'fieldIndex'
)
actions.queryTmpDimensionUpdateFields = mac(
	types.QUERY_TMP_DIMENSION_UPDATE_FIELDS,
	'axis',
	'slotIndex',
	'fields'
)
actions.queryTmpDimensionUpdateBlock = mac(
	types.QUERY_TMP_DIMENSION_UPDATE_BLOCK,
	'axis',
	'slotIndex',
	'blocked'
)

actions.queryTmpFilterAdd = mac(types.QUERY_TMP_FILTER_ADD, 'filter')
actions.queryTmpFilterUpdate = mac(
	types.QUERY_TMP_FILTER_UPDATE,
	'filterIndex',
	'filterContent'
)
actions.queryTmpFilterRemove = mac(types.QUERY_TMP_FILTER_REMOVE, 'filterIndex')
actions.queryTmpFilterUpdateBlock = mac(
	types.QUERY_TMP_FILTER_UPDATE_BLOCK,
	'filterIndex',
	'blocked'
)

actions.queryTmpFilterSetDimension = mac(
	types.QUERY_TMP_FILTER_SET_DIMENSION,
	'filterIndex',
	'dimension'
)
actions.queryTmpFilterSetInclude = mac(
	types.QUERY_TMP_FILTER_SET_INCLUDE,
	'filterIndex',
	'include'
)
actions.queryTmpFilterAddParameter = mac(
	types.QUERY_TMP_FILTER_ADD_PARAMETER,
	'filterIndex',
	'parameter'
)
actions.queryTmpFilterRemoveParameter = mac(
	types.QUERY_TMP_FILTER_REMOVE_PARAMETER,
	'filterIndex',
	'parameterIndex'
)
actions.queryTmpFilterUpdateParameters = mac(
	types.QUERY_TMP_FILTER_UPDATE_PARAMETERS,
	'filterIndex',
	'parameters'
)
actions.queryTmpSetFolderId = mac(types.QUERY_TMP_SET_FOLDER_ID, 'folderId')
actions.queryTmpSetAddLastPrice = mac(
	types.QUERY_TMP_SET_ADD_LAST_PRICE,
	'add_last_price'
)

let lastQueryConfigIdLoaded = ''

actions.queryTmpLoadFromQueryId = (queryConfigId = '') => {
	return (dispatch, getState) => {
		if (lastQueryConfigIdLoaded === queryConfigId) return

		const state = getState()
		const organizationId = state.getIn(['session', 'organizationId'])
		const serviceId = state.getIn(['session', 'serviceId'])

		if (queryConfigId === '' || queryConfigId === null) {
			const serviceName = state.getIn([
				'user',
				'organizations',
				`${organizationId}`,
				'services',
				`${serviceId}`,
				'name',
			])

			if (serviceName === 'minik') {
				const rows = asImmutable([
					{
						type: 'dimension',
						blocked: true,
						fields: [
							{
								id: 1,
								name: 'Producto',
								field_name: 'prd_desc',
							},
						],
					},
					{
						type: 'dimension',
						blocked: true,
						fields: [
							{
								id: 2,
								name: 'Presentacion',
								field_name: 'pres_desc',
							},
						],
					},
				])

				const minikTemplate = emptyQueryConfig.setIn(
					['config', 'rows'],
					rows
				)

				dispatch(actions.queryTmpLoad(minikTemplate))
			} else {
				dispatch(actions.queryTmpLoad())
			}
		} else if (organizationId !== undefined && serviceId !== undefined) {
			const query = getState().getIn([
				'user',
				'organizations',
				`${organizationId}`,
				'services',
				`${serviceId}`,
				'queries',
				`${queryConfigId}`,
			])
			dispatch(actions.queryTmpLoad(query))
		}

		lastQueryConfigIdLoaded = queryConfigId
	}
}

export default actions
