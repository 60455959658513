import worker from '../../worker'
import { push } from 'react-router-redux'
import { createActionTypes, makeActionCreator as mac } from '@loque/reducks'
import { addToBasePath } from '../../index'

const types = createActionTypes(['ADMIN_SESSION_UPDATE_ALL'])
const actions = {}

actions.adminSessionUpdateAll = mac(types.ADMIN_SESSION_UPDATE_ALL, 'session')

actions.adminSessionSelectUser = userId => {
	return dispatch => {
		worker.post(`/admin/session/user/${userId}`).then(() => {
			dispatch(push(addToBasePath(`/admin/usuarios/${userId}/edit`)))
		})
	}
}

export default {
	types,
	actions,
}
