import React from 'react'
import Popover from '../Popover'

const transition = {
	from: {
		opacity: 1,
		transform: 'translateY(-10%)',
	},
	enter: {
		opacity: 1,
		transform: 'translateY(0)',
	},
	leave: {
		opacity: 0,
		transform: 'translateY(-6%)',
	},
	config: { mass: 1, tension: 1200, friction: 60 },
}

export default function Dropdown({
	children,
	content,
	anchorPoints = {
		popover: { x: 'left', y: 'top' },
		trigger: { x: 'left', y: 'top' },
	},
}) {
	return (
		<Popover
			triggerAction="click"
			transition={transition}
			anchorPoints={anchorPoints}
			offset={{ x: 0, y: 0 }}
			content={content}
			hideOnEsc={true}
			hideOnBlur={true}
		>
			{children}
		</Popover>
	)
}
