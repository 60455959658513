import React from 'react'
import ListFolder from './ListFolder'
import ListFile from './ListFile'
import { Col } from './common'

export default function ListBase({
	className = '',
	tree,
	selectMode,
	dispatch,
	idChain = [],
}) {
	return (
		<Col as="ul" className={className} style={{ width: '100%' }}>
			{tree.length > 0 &&
				tree.map((node) => {
					if (node.type === 'folder') {
						return (
							<ListFolder
								key={node.type + node.id}
								id={node.id}
								label={node.label}
								buttons={node.buttons}
								shared={!!node.shared}
								children={node.children}
								open={node.open}
								selected={node.selected}
								idChain={idChain}
								selectMode={selectMode}
								dispatch={dispatch}
							/>
						)
					} else {
						return (
							<ListFile
								key={node.type + node.id}
								id={node.id}
								label={node.label}
								buttons={node.buttons}
								shared={!!node.shared}
								onClick={node.onClick}
								selected={node.selected}
								highlighted={node.highlighted}
								idChain={idChain}
								selectMode={selectMode}
								dispatch={dispatch}
							/>
						)
					}
				})}
		</Col>
	)
}
