import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import styled, { css } from 'styled-components'
import { useTransition, animated } from 'react-spring'

import Portal from '../utils/Portal'
import calculateWrapperRect from './calculateWrapperRect'
import { cloneElement, isNum } from '../utils'
import useAutoFocus from '../utils/useAutoFocus'

const curtainStyles = css`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
`

const Curtain = styled.div`
	${props => (props.block ? curtainStyles : '')}
`

const PopoverBaseWrapper = styled(animated.div)`
	position: absolute;
	z-index: 4;

	${props => {
		let styles = ''
		if (isNum(props.rect.left)) {
			styles += `left: ${props.rect.left}px;`
		}
		if (isNum(props.rect.right)) {
			styles += `right: ${props.rect.right}px;`
		}
		if (isNum(props.rect.top)) {
			styles += `top: ${props.rect.top}px;`
		}
		if (isNum(props.rect.bottom)) {
			styles += `bottom: ${props.rect.bottom}px;`
		}

		return styles
	}}
`

const PopoverWrapper = React.forwardRef(
	({ hideOnBlur, hideOnEsc, hidePopover, ...props }, ref) => {
		useEffect(() => {
			function handleEsc(e) {
				if (hideOnEsc && e.key && e.key === 'Escape') {
					hidePopover()
				}
			}

			window.addEventListener('keydown', handleEsc, true)
			return () => {
				window.removeEventListener('keydown', handleEsc, false)
			}
		}, [hideOnEsc])

		return (
			<PopoverBaseWrapper
				ref={ref}
				onBlur={e => hideOnBlur && hidePopover(e)}
				{...props}
			/>
		)
	}
)

export default function Popover({
	children: trigger,
	content,
	anchorPoints = {
		popover: { x: 'left', y: 'center' },
		trigger: { x: 'right', y: 'center' },
	},
	processStyleProps,
	offset = { x: 10, y: 0 },
	transition = {
		from: {
			opacity: 0,
			transform: 'translate3d(100%,0,0)',
		},
		enter: {
			opacity: 1,
			transform: 'translate3d(0%,0,0)',
		},
		leave: {
			opacity: 0,
			transform: 'translate3d(50%,0,0)',
		},
	},
	// click | hover | focus
	triggerAction = 'click',
	hideOnBlur = false,
	hideOnEsc = false,
	blockOutsideClick = false,
	avoidEdgeCollision = false,
	onVisible = () => {},
	onHidden = () => {},
}) {
	const triggerRef = useRef(null)
	const popoverRef = useRef(null)
	const collision = useRef(null)
	const [popoverRect, setPopoverRect] = useState(null)
	const [visible, setVisible] = useState(false)
	const [localAnchorPoints, setLocalAnchorPoints] = useState(anchorPoints)
	const [didCollide, setDidCollide] = useState(false)

	useAutoFocus(visible, popoverRef)

	const transitions = useTransition(visible, null, {
		...transition,
		unique: true,
	})

	function showPopover() {
		if (!trigger.props.disabled) {
			onVisible()
			setVisible(true)
		}
	}

	function hidePopover(e) {
		if (
			e &&
			e.type === 'blur' &&
			e.relatedTarget &&
			e.relatedTarget === triggerRef.current
		) {
			// prevent hidePopover when the event is blur and the reason
			// it's blurring is that the user clicked on the target
			return
		}
		onHidden()
		setVisible(false)
	}

	const triggerProps = {
		ref: node => {
			triggerRef.current = node
		},
	}

	switch (triggerAction) {
		case 'click':
			triggerProps.onClick = visible ? hidePopover : showPopover
			break
		case 'focus':
			triggerProps.onFocus = showPopover
			triggerProps.onBlur = hidePopover
			break
		case 'hover':
			triggerProps.onMouseOver = showPopover
			triggerProps.onMouseOut = hidePopover
			break
	}

	let triggerRect
	if (triggerRef.current) {
		triggerRect = triggerRef.current.getBoundingClientRect()
	}

	function popoverRefCallback(node) {
		if (node === null) return

		// we don't want unnecessary focus() calls
		if (popoverRef.current !== node) {
			popoverRef.current = node
		}

		const nodeRect = node.getBoundingClientRect()

		if (
			popoverRect === null ||
			popoverRect.width !== nodeRect.width ||
			popoverRect.height !== nodeRect.height ||
			popoverRect.left !== nodeRect.left ||
			popoverRect.top !== nodeRect.top
		) {
			setPopoverRect({
				width: nodeRect.width,
				height: nodeRect.height,
				left: nodeRect.left,
				top: nodeRect.top,
			})
		}
	}

	useLayoutEffect(() => {
		if (avoidEdgeCollision && didCollide === false) {
			collision.current = detectEdgeCollisions(popoverRect)
			if (collision.current.length) {
				setLocalAnchorPoints(
					avoidEdgeCollision(localAnchorPoints, collision.current)
				)
				setDidCollide(true)
			}
		}
	}, [avoidEdgeCollision, didCollide, popoverRect, localAnchorPoints])

	const curtainBlocker = blockOutsideClick && visible

	return (
		<React.Fragment>
			{cloneElement(trigger, triggerProps)}
			{transitions.map(({ item: visible, props: style, key }) => {
				if (!visible) return null

				const popoverWrapperRect = calculateWrapperRect(
					triggerRect,
					localAnchorPoints,
					offset,
					popoverRect
				)

				if (typeof processStyleProps === 'function') {
					style = processStyleProps(style, collision)
				}

				return (
					<Portal key={key}>
						<Curtain block={curtainBlocker}>
							<PopoverWrapper
								key={key}
								ref={popoverRefCallback}
								style={style}
								rect={popoverWrapperRect}
								hideOnBlur={hideOnBlur}
								hideOnEsc={hideOnEsc}
								hidePopover={hidePopover}
							>
								{cloneElement(content, {
									hidePopover,
								})}
							</PopoverWrapper>
						</Curtain>
					</Portal>
				)
			})}
		</React.Fragment>
	)
}

function detectEdgeCollisions(target) {
	const edgeCollision = []
	if (
		target &&
		(target.width || target.height || target.top || target.left)
	) {
		const body = document.body.getBoundingClientRect()
		if (target.top <= 0) {
			edgeCollision.push('top')
		}
		if (target.top + target.height >= body.height) {
			edgeCollision.push('bottom')
		}
		if (target.left <= 0) {
			edgeCollision.push('left')
		}
		if (target.left + target.width >= body.width) {
			edgeCollision.push('right')
		}
	}
	return edgeCollision
}
