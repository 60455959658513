import React from 'react'
import { useTransition, animated } from 'react-spring'

export default function FadeIn({
	children,
	duration,
	wrapper,
	...wrapperProps
}) {
	const transitions = useTransition(true, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: { duration: duration && parseInt(duration) },
	})

	const Wrapper = wrapper ? animated(wrapper) : animated.div

	return transitions.map(({ props, key }) => (
		<Wrapper key={key} style={props} {...wrapperProps}>
			{children}
		</Wrapper>
	))
}
