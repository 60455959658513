import { asImmutable } from 'utils/immutable'

const initialState = asImmutable([
	{
		field_name: 'total',
		name: 'Total',
	},
	{
		field_name: 'mes',
		name: 'Mes',
	},
	{
		field_name: 'trim',
		name: 'Trimestre',
	},
	{
		field_name: 'mat',
		name: 'MAT',
	},
	{
		field_name: 'ytd',
		name: 'YTD',
	},
	{
		field_name: 'meses12',
		name: '12 meses',
	},
	{
		field_name: 'meses24',
		name: '24 meses',
	},
])

export default {
	reducer: () => initialState,
}
