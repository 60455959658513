import React from 'react'

export default function Label({ text, children }) {
	return (
		<label>
			<div>{text}</div>
			{children}
		</label>
	)
}
