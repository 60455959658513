import { createActionTypes } from '@loque/reducks'

export default createActionTypes([
	'QUERY_TMP_LOAD',
	'QUERY_TMP_SET_BLOCKED',
	'QUERY_TMP_SET_DATEFIELD',
	'QUERY_TMP_NAME_UPDATE',
	'QUERY_TMP_FAV_TOGGLE',
	'QUERY_TMP_DIMENSION_ADD_SLOT',
	'QUERY_TMP_DIMENSION_REMOVE_SLOT',
	'QUERY_TMP_DIMENSION_UPDATE_SLOTS',
	'QUERY_TMP_DIMENSION_UPDATE_SLOT_BLOCK',
	'QUERY_TMP_INVERT_PERIOD_MEASURE',
	'QUERY_TMP_DIMENSION_ADD_FIELD',
	'QUERY_TMP_DIMENSION_REMOVE_FIELD',
	'QUERY_TMP_DIMENSION_UPDATE_FIELDS',
	'QUERY_TMP_DIMENSION_UPDATE_BLOCK',
	'QUERY_TMP_FILTER_ADD',
	'QUERY_TMP_FILTER_UPDATE',
	'QUERY_TMP_FILTER_REMOVE',
	'QUERY_TMP_FILTER_UPDATE_BLOCK',
	'QUERY_TMP_FILTER_SET_DIMENSION',
	'QUERY_TMP_FILTER_SET_INCLUDE',
	'QUERY_TMP_FILTER_ADD_PARAMETER',
	'QUERY_TMP_FILTER_REMOVE_PARAMETER',
	'QUERY_TMP_FILTER_UPDATE_PARAMETERS',
	'QUERY_TMP_SET_FOLDER_ID',
	'QUERY_TMP_SET_ADD_LAST_PRICE',
])
