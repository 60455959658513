import { css } from 'styled-components'
import { transparentize, lighten, darken } from 'polished'

const searchStyles = css`
	background-color: #fff;
	border: 1px solid ${props => darken(0.04, props.theme.color.grey)};

	&:hover:not(:disabled) {
		color: ${props => darken(0.42, props.theme.color.fg)};
		background: ${props => lighten(0.92, props.theme.color.fg)};
	}
`

const blockStyles = css`
	background-color: #fff;
	border: 1px solid ${props => darken(0.04, props.theme.color.grey)};

	&:disabled {
		background-color: ${() => transparentize(0.5, '#f1f3f4')};
		border-color: ${() => transparentize(0.5, '#f1f3f4')};
		color: ${props => lighten(0.5, props.theme.color.fg)};
	}
`

const inlineStyles = css`
	background-color: transparent;
	border-width: 0 0 2px 0;
	border-style: solid;
	border-color: transparent;
`

const hoverStyles = css`
	&:hover:not(:disabled) {
		background-color: ${props => lighten(0.06, props.theme.color.grey)};
		border-color: ${props => lighten(0.06, props.theme.color.grey)};
	}
`

export const formControls = css`
	width: 100%;
	border-radius: ${props => props.theme.controls.borderRadius};
	padding: ${props => props.theme.controls.padding(props.slim)};
	font-size: 100%;
	color: ${props => props.theme.color.fg};
	
	${props => !props.inline && blockStyles}
	${props => props.inline && inlineStyles}
	${props => props.search && searchStyles}

	${props => props.noHover !== true && !props.search && hoverStyles}
`
