import React from 'react'
import styled, { css } from 'styled-components'
import wrapStrings from './wrapStrings'

const center = css`
	display: inline-flex;
	justify-content: center;
	align-items: center;
`

const ButtonUnstyledBase = styled.button`
	border: none;
	background: transparent;
	padding: 0;
	margin: 0;
	font: inherit;
	color: inherit;
	cursor: pointer;

	&:focus {
		outline: none;
	}

	${props => props.center && center}
`

export default React.forwardRef(function ButtonUnstyled(
	{ children, ...props },
	ref
) {
	return (
		<ButtonUnstyledBase ref={ref} {...props}>
			{wrapStrings(children)}
		</ButtonUnstyledBase>
	)
})
