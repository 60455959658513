import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ducks from 'front/ducks'

import Modal from 'ui/Modal'
import ModalTitle from 'ui/Modal/Title'
import ModalBody from 'ui/Modal/Body'
import ModalActions from 'ui/Modal/Actions'
import Button from 'ui/Button'
import Loading from 'ui/Loading'
import Input from 'ui/Forms/Input'
import { useAction } from '~/modules/utils/redux'
import { wrapCallback } from '~/frontend/ui/utils'

export function CreateFolderModal({
	visible,
	setVisible,
	onSuccess = () => {},
}) {
	const [internalVisible, setInternalVisible] = useState(visible)
	const [reqId, setReqId] = useState(0)
	const [folderName, setFolderName] = useState('')

	const { requestId, loading, error, isMarket } = useSelector(state => {
		const requestId = state.getIn(['folders', 'createRequestId'])
		const loading = state.getIn(['folders', 'createLoading'])
		const error = state.getIn(['folders', 'createError'])

		const { organizationId, serviceId } = state.get('session').toJS()

		const isMarket =
			state.getIn([
				'user',
				'organizations',
				`${organizationId}`,
				'services',
				`${serviceId}`,
				'report_code',
			]) === 'market'

		return {
			requestId,
			loading,
			error,
			isMarket,
		}
	})

	const foldersCreate = useAction(ducks.actions.foldersCreate)
	const foldersCreateReset = useAction(ducks.actions.foldersCreateReset)

	useEffect(() => {
		internalVisible && setReqId(rId => rId++)
	}, [internalVisible])

	function runSecondaryAction(event) {
		event.preventDefault()
		setInternalVisible(false)
	}

	function runPrimaryAction(event) {
		event.preventDefault()
		foldersCreate(reqId, folderName).then(folder => {
			onSuccess(folder)
		})
	}

	// automatically hide modal when request is successfull
	useEffect(() => {
		if (internalVisible && !loading && !error && requestId === reqId) {
			setInternalVisible(false)
		}
	}, [internalVisible, loading, error, requestId, reqId])

	// we delay the reset so that it happens once the modal is hidden
	useEffect(
		() => () => {
			setFolderName('')
			foldersCreateReset()
		},
		[]
	)

	let state
	let body

	if (loading === true) {
		// loading
		state = 1
		body = <Loading>Procesando solicitud...</Loading>
	} else if (error) {
		// error
		state = 2
		let msg = `Tuvimos un error al crear la ${
			isMarket ? 'línea' : 'carpeta'
		}.`
		if (
			error.description ===
			'folder name is taken in this service for this user'
		) {
			msg = `Ya existe una ${
				isMarket ? 'línea' : 'carpeta'
			} con el nombre "${folderName}".`
		}
		body = <p>{msg}</p>
	} else if (!loading && !error && requestId === null) {
		// waiting
		state = 0
		body = (
			<form onSubmit={runPrimaryAction}>
				<label>
					<p>Nombre</p>
					<Input
						value={folderName}
						onChange={e => setFolderName(e.target.value)}
					/>
				</label>
			</form>
		)
	}

	return (
		<Modal visible={internalVisible} onDestroyed={setVisible}>
			<ModalTitle>Crear {isMarket ? 'línea' : 'carpeta'}</ModalTitle>
			<ModalBody>{body}</ModalBody>
			<ModalActions>
				{state >= 2 && (
					<Button role="primary" onClick={runSecondaryAction}>
						Cerrar
					</Button>
				)}
				{state === 0 && (
					<Button
						role="secondary"
						onClick={runSecondaryAction}
						disabled={state === 1}
					>
						Cancelar
					</Button>
				)}
				{state === 0 && (
					<Button
						role="primary"
						onClick={runPrimaryAction}
						disabled={state === 1}
					>
						Crear
					</Button>
				)}
			</ModalActions>
		</Modal>
	)
}

export default function CreateFolder({ children }) {
	const child = React.Children.only(children)

	const [visible, setVisible] = useState(false)

	function onClick() {
		setVisible(true)
	}

	return (
		<React.Fragment>
			{React.cloneElement(child, {
				...child.props,
				onClick: wrapCallback(child.props.onClick, onClick),
			})}
			{visible && (
				<CreateFolderModal visible={visible} setVisible={setVisible} />
			)}
		</React.Fragment>
	)
}
