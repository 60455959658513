import { browserHistory } from 'react-router'
import { addToBasePath } from '../index'
import queries from './queries'
import queryPage from './queryPage'
import livefilters from './liveFilters'

const actions = {}

actions.navToQuery = queryConfigId => {
	return dispatch => {
		dispatch(queries.actions.queriesSetQueryConfigId(queryConfigId))
		dispatch(livefilters.actions.filtersResetAll())
		dispatch(queryPage.actions.queryPageSetNumber(1))
		dispatch(queryPage.actions.queryPageGet())
		dispatch(actions.navToLabService(`/${queryConfigId}`))
	}
}

actions.navToQueryEdit = queryConfigId => {
	return dispatch => {
		dispatch(queries.actions.queriesSetQueryConfigId(queryConfigId))
		dispatch(actions.navToLabService(`/${queryConfigId}/edit`))
	}
}

actions.navToQueryNew = params => {
	return dispatch => {
		dispatch(queries.actions.queriesSetQueryConfigId(null))
		dispatch(actions.navToLabService(`/new`, params))
	}
}

actions.navAfterLogin = () => {
	return dispatch => {
		dispatch(actions.navToLabService())
	}
}

actions.navToLabService = (path = '', params = {}) => {
	return (dispatch, getState) => {
		const state = getState()

		const organizationId = state.getIn(['session', 'organizationId'])
		const serviceId = state.getIn(['session', 'serviceId'])

		if (organizationId === null) {
			dispatch(navIfNotAlreadyThere('/', params))
		} else if (organizationId !== null && serviceId === null) {
			const organizationName = state
				.getIn(['user', 'organizations', `${organizationId}`, 'name'])
				.toUpperCase()
			dispatch(navIfNotAlreadyThere(`/${organizationName}`, params))
		} else if (organizationId !== null && serviceId !== null) {
			const organization = state.getIn([
				'user',
				'organizations',
				`${organizationId}`,
			])

			if (!organization)
				return dispatch(navIfNotAlreadyThere('/', params))

			const service = organization.getIn(['services', `${serviceId}`])

			if (!service) return dispatch(navIfNotAlreadyThere('/', params))

			const organizationName = organization.get('name', '').toUpperCase()
			const serviceName = service.get('name', '').toUpperCase()

			dispatch(
				navIfNotAlreadyThere(
					`/${organizationName}/${serviceName}${path}`,
					params
				)
			)
		}
	}
}

const navIfNotAlreadyThere = (nextPath = '/', params) => {
	return (dispatch, getState) => {
		const state = getState()
		const currPath = state
			.getIn(['routing', 'locationBeforeTransitions', 'pathname'])
			.replace('/servicios', '')

		if (
			currPath !== nextPath ||
			JSON.parse(JSON.stringify(params)) !== '{}'
		) {
			browserHistory.push({
				pathname: addToBasePath(nextPath),
				...params,
			})
		}
	}
}

actions.navBackOr = url => {
	return (dispatch, getState) => {
		const prevLocation = getState().getIn([
			'routing',
			'locationBeforeTransitions',
		])

		if (
			prevLocation.get('key') === null ||
			browserHistory.getCurrentLocation().pathname ===
				prevLocation.get('pathname')
		) {
			browserHistory.push(url)
		} else {
			browserHistory.goBack()
		}
	}
}

actions.navBackOrFollowSession = () => {
	return (dispatch, getState) => {
		const prevLocation = getState().getIn([
			'routing',
			'locationBeforeTransitions',
		])

		if (
			prevLocation.get('key') === null ||
			browserHistory.getCurrentLocation().pathname ===
				prevLocation.get('pathname')
		) {
			dispatch(actions.navToLabService())
		} else {
			browserHistory.goBack()
		}
	}
}

actions.navToLogin = () => {
	return dispatch => {
		browserHistory.push(addToBasePath('/login'))
	}
}

export default {
	actions,
}
