import React from 'react'
import ButtonUnstyled from '../Button/Unstyled'

import {
	FiCheckSquare as Checked,
	FiSquare as Unchecked,
	FiMinusSquare as Indeterminate,
} from 'react-icons/fi'

/*
	checked can be:
		* bool
			- false = unchecked
	   		- true = checked
		* int
			- 0 = unchecked
			- 1 = checked
			- 2 = indeterminate
*/
export default function Checkbox({ checked, onChange, style }) {
	let Icon

	if (!checked) {
		Icon = Unchecked
	} else if (checked === 2) {
		Icon = Indeterminate
	} else if (checked) {
		Icon = Checked
	}

	return (
		<ButtonUnstyled
			as="div"
			style={{
				display: 'flex',
				...style,
			}}
			onClick={onChange}
		>
			<Icon />
		</ButtonUnstyled>
	)
}
