import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ducks from 'front/ducks'

import Modal from 'ui/Modal'
import ModalTitle from 'ui/Modal/Title'
import ModalBody from 'ui/Modal/Body'
import ModalActions from 'ui/Modal/Actions'
import MenuButton from 'ui/Dropdown/MenuButton'
import Loading from 'ui/Loading'
import Button from 'ui/Button'
import { useAction } from '~/modules/utils/redux'
import { FiTrash as TrashIcon } from 'react-icons/fi'

function DeleteFolderModal({
	folderId,
	folderName,
	queries,

	reportCode,
	visible,
	setVisible,
}) {
	const [internalVisible, setInternalVisible] = useState(visible)
	const { requestId, loading, error } = useSelector(state =>
		state.getIn(['folders', 'delete']).toJS()
	)

	const foldersDelete = useAction(ducks.actions.foldersDelete)
	const foldersDeleteConfirm = useAction(ducks.actions.foldersDeleteConfirm)
	const foldersDeleteReset = useAction(ducks.actions.foldersDeleteReset)

	function runSecondaryAction(event) {
		event && event.preventDefault()
		setInternalVisible(false)
	}

	function runPrimaryAction(event) {
		event.preventDefault()
		foldersDelete(folderId, folderName)
	}

	// automatically hide modal when request is successfull
	useEffect(() => {
		if (
			internalVisible &&
			loading === false &&
			error === null &&
			requestId === folderId
		) {
			setInternalVisible(false)
		}
	}, [internalVisible, loading, error, requestId, folderId])

	// we delay the reset so that it happens once the modal is hidden
	useEffect(() => () => foldersDeleteReset(), [])

	let state
	let body

	if (loading === true) {
		// loading
		state = 1
		body = <Loading>Procesando solicitud...</Loading>
	} else if (error) {
		// error
		state = 2
		let msg = `Tuvimos un error al eliminar ${
			reportCode === 'market' ? 'el mercado ' : 'la consulta '
		}.`
		switch (error.description) {
			case 'there are other users accesing this folder, transfer ownership before deleting':
				msg = `Hay otros usuarios con acceso a ésta ${
					reportCode === 'market' ? 'línea' : 'carpeta'
				}, transferí su administración antes de eliminarla.`
				break
		}
		body = <p>{msg}</p>
	} else if (loading === false && error === null && requestId === null) {
		// waiting
		state = 0
		body = (
			<React.Fragment>
				{queries.length > 0 && (
					<p>
						Se eliminarán{' '}
						{reportCode === 'market'
							? 'los mercados'
							: 'las consultas'}{' '}
						para todos los usuarios.
					</p>
				)}
			</React.Fragment>
		)
	}

	return (
		<Modal
			visible={internalVisible}
			onDestroyed={() => {
				if (requestId === folderId) {
					foldersDeleteConfirm(folderId)
				}
				setVisible(false)
			}}
		>
			<ModalTitle>
				¿Eliminar la {reportCode === 'market' ? 'línea ' : 'carpeta '}
				<i>{folderName}</i>?
			</ModalTitle>
			<ModalBody>{body}</ModalBody>
			<ModalActions>
				{state === 2 && (
					<Button role="primary" onClick={runSecondaryAction}>
						Cerrar
					</Button>
				)}
				{state === 0 && (
					<Button
						role="secondary"
						onClick={runSecondaryAction}
						disabled={state === 1}
					>
						Cancelar
					</Button>
				)}
				{state === 0 && (
					<Button
						role="warning"
						variant="contained"
						onClick={runPrimaryAction}
						disabled={state === 1}
					>
						Eliminar
					</Button>
				)}
			</ModalActions>
		</Modal>
	)
}

export default function useDeleteFolder(folderId, folderName, queries) {
	const [visible, setVisible] = useState(false)

	const reportCode = useSelector(state => {
		const { organizationId, serviceId } = state.get('session').toJS()
		return state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
			'report_code',
		])
	})

	const modal = visible && (
		<DeleteFolderModal
			folderId={folderId}
			folderName={folderName}
			queries={queries}
			reportCode={reportCode}
			visible={visible}
			setVisible={setVisible}
		/>
	)

	const button = (
		<MenuButton onClick={() => setVisible(true)} icon={<TrashIcon />}>
			Eliminar {reportCode === 'market' ? 'línea' : 'carpeta'}
		</MenuButton>
	)

	return { button, modal }
}
