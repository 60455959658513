import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'

import ButtonUnstyled from '../Button/Unstyled'

const Button = styled(ButtonUnstyled)`
	display: flex;
	align-items: center;
	padding: 0.5rem 4.5rem 0.5rem 1.5rem;
	width: 100%;

	&:hover {
		background: ${props => lighten(0.06, props.theme.color.grey)};
	}
`

const IconWrappper = styled.div`
	margin-right: 1rem;
`

export default function MenuButton({ children, icon, ...props }) {
	return (
		<Button {...props}>
			{icon && <IconWrappper>{icon}</IconWrappper>}
			{children}
		</Button>
	)
}
