import { asImmutable } from 'utils/immutable'
import types from './actionTypes'

const initialState = asImmutable({
	queryConfigId: null,
	loadingQueryConfig: false,
	loadingQueries: false,

	duplicate: {
		requestId: null,
		loading: false,
		success: false,
		error: null,
	},

	getShareTargets: {
		targets: null,
		requestId: null,
		loading: false,
		error: null,
	},

	getFolderTargets: {
		targets: null,
		requestId: null,
		loading: false,
		error: null,
	},

	putFolder: {
		requestId: null,
		loading: false,
		error: null,
		success: false,
	},

	deleteReport: {
		requestId: null,
		loading: false,
		error: null,
		success: false,
	},
})

function reducer(state = initialState, action) {
	let next = state

	switch (action.type) {
		case types.QUERIES_SET_QUERY_CONFIG_ID:
			if (!action.queryConfigId) return next.set('queryConfigId', null)
			return next.set('queryConfigId', asImmutable(action.queryConfigId))

		case types.QUERIES_SET_LOADING_QUERY_CONFIG:
			return next.set('loadingQueryConfig', !!action.loading)

		case types.QUERIES_SET_LOADING_QUERIES:
			return next.set('loadingQueries', !!action.loading)

		// DUPLICATE
		case types.QUERIES_DUPLICATE_REQUEST:
			next = next.setIn(['duplicate', 'requestId'], action.requestId)
			next = next.setIn(['duplicate', 'loading'], true)
			next = next.setIn(['duplicate', 'success'], false)
			next = next.setIn(['duplicate', 'error'], null)
			return next

		case types.QUERIES_DUPLICATE_SUCCESS:
			next = next.setIn(['duplicate', 'loading'], false)
			next = next.setIn(['duplicate', 'success'], true)
			next = next.setIn(['duplicate', 'error'], null)
			return next

		case types.QUERIES_DUPLICATE_ERROR:
			next = next.setIn(['duplicate', 'loading'], false)
			next = next.setIn(['duplicate', 'success'], false)
			next = next.setIn(['duplicate', 'error'], action.error)

		case types.QUERIES_DUPLICATE_RESET:
			next = next.setIn(['duplicate', 'requestId'], null)
			next = next.setIn(['duplicate', 'loading'], false)
			next = next.setIn(['duplicate', 'success'], false)
			next = next.setIn(['duplicate', 'error'], null)
			return next

		// GET POSSIBLE SHARE TARGETS
		case types.QUERIES_GET_SHARE_TARGETS_REQUEST:
			next = next.setIn(
				['getShareTargets', 'requestId'],
				action.requestId
			)
			next = next.setIn(['getShareTargets', 'error'], null)
			next = next.setIn(['getShareTargets', 'loading'], true)
			return next

		case types.QUERIES_GET_SHARE_TARGETS_SUCCESS:
			next = next.setIn(['getShareTargets', 'targets'], action.targets)
			next = next.setIn(['getShareTargets', 'error'], null)
			next = next.setIn(['getShareTargets', 'loading'], false)
			return next

		case types.QUERIES_GET_SHARE_TARGETS_ERROR:
			next = next.setIn(['getShareTargets', 'error'], action.error)
			next = next.setIn(['getShareTargets', 'loading'], false)
			return next

		case types.QUERIES_GET_SHARE_TARGETS_RESET:
			next = next.setIn(['getShareTargets', 'requestId'], null)
			next = next.setIn(['getShareTargets', 'targets'], null)
			next = next.setIn(['getShareTargets', 'error'], null)
			next = next.setIn(['getShareTargets', 'loading'], false)
			return next

		// GET POSSIBLE FOLDER TARGETS
		case types.QUERIES_GET_FOLDER_TARGETS_REQUEST:
			next = next.setIn(
				['getFolderTargets', 'requestId'],
				action.requestId
			)
			next = next.setIn(['getFolderTargets', 'error'], null)
			next = next.setIn(['getFolderTargets', 'loading'], true)
			return next

		case types.QUERIES_GET_FOLDER_TARGETS_SUCCESS:
			next = next.setIn(['getFolderTargets', 'targets'], action.targets)
			next = next.setIn(['getFolderTargets', 'error'], null)
			next = next.setIn(['getFolderTargets', 'loading'], false)
			return next

		case types.QUERIES_GET_FOLDER_TARGETS_ERROR:
			next = next.setIn(['getFolderTargets', 'error'], action.error)
			next = next.setIn(['getFolderTargets', 'loading'], false)
			return next

		case types.QUERIES_GET_FOLDER_TARGETS_RESET:
			next = next.setIn(['getFolderTargets', 'requestId'], null)
			next = next.setIn(['getFolderTargets', 'targets'], null)
			next = next.setIn(['getFolderTargets', 'error'], null)
			next = next.setIn(['getFolderTargets', 'loading'], false)
			return next

		// PUT QUERY IN FOLDER
		case types.QUERIES_PUT_FOLDER_REQUEST:
			next = next.setIn(['putFolder', 'requestId'], action.requestId)
			next = next.setIn(['putFolder', 'error'], null)
			next = next.setIn(['putFolder', 'loading'], true)
			next = next.setIn(['putFolder', 'success'], false)
			return next

		case types.QUERIES_PUT_FOLDER_SUCCESS:
			next = next.setIn(['putFolder', 'error'], null)
			next = next.setIn(['putFolder', 'loading'], false)
			next = next.setIn(['putFolder', 'success'], true)
			return next

		case types.QUERIES_PUT_FOLDER_ERROR:
			next = next.setIn(['putFolder', 'error'], action.error)
			next = next.setIn(['putFolder', 'loading'], false)
			next = next.setIn(['putFolder', 'success'], false)
			return next

		case types.QUERIES_PUT_FOLDER_RESET:
			next = next.setIn(['putFolder', 'requestId'], null)
			next = next.setIn(['putFolder', 'error'], null)
			next = next.setIn(['putFolder', 'loading'], false)
			next = next.setIn(['putFolder', 'success'], false)
			return next

		// DELETE QUERY
		case types.QUERIES_DELETE_REQUEST:
			next = next.setIn(['deleteReport', 'requestId'], action.requestId)
			next = next.setIn(['deleteReport', 'error'], null)
			next = next.setIn(['deleteReport', 'loading'], true)
			next = next.setIn(['deleteReport', 'success'], false)
			return next

		case types.QUERIES_DELETE_SUCCESS:
			next = next.setIn(['deleteReport', 'error'], null)
			next = next.setIn(['deleteReport', 'loading'], false)
			next = next.setIn(['deleteReport', 'success'], true)
			return next

		case types.QUERIES_DELETE_ERROR:
			next = next.setIn(['deleteReport', 'error'], action.error)
			next = next.setIn(['deleteReport', 'loading'], false)
			next = next.setIn(['deleteReport', 'success'], false)
			return next

		case types.QUERIES_DELETE_RESET:
			next = next.setIn(['deleteReport', 'requestId'], null)
			next = next.setIn(['deleteReport', 'error'], null)
			next = next.setIn(['deleteReport', 'loading'], false)
			next = next.setIn(['deleteReport', 'success'], false)
			return next

		default:
			return next
	}
}

export default reducer
