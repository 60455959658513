import worker from '../../worker'
import { createActionTypes, makeActionCreator as mac } from '@loque/reducks'
import notifications from '../notifications'

const types = createActionTypes(['ADMIN_UPDATE_PROVIDERS'])
const actions = {}

actions.adminUpdateProviders = mac(types.ADMIN_UPDATE_PROVIDERS, 'providers')

actions.adminGetProviders = () => {
	return dispatch => {
		worker
			.get('/admin/providers')
			.then(res => {
				dispatch(actions.adminUpdateProviders(res.body))
			})
			.catch(res => {
				console.error(`Error fetching providers`, res)
				dispatch(
					notifications.actions.notifyError(
						'Tuvimos un error al listar los proveedores'
					)
				)
			})
	}
}

export default {
	types,
	actions,
}
