import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router, Route, IndexRoute } from 'react-router'

import { store, history } from './store'

import Loadable from 'react-loadable'
const Loading = () => null

import App from './containers/App'
const Admin = Loadable({
	loader: () => import('./containers/Admin'),
	loading: Loading,
})
import Reports from './containers/Reports'
const Login = Loadable({
	loader: () => import('./containers/Login'),
	loading: Loading,
})

// Reports
const SelectLabService = Loadable({
	loader: () => import('./components/Reports/SelectLabService'),
	loading: Loading,
})
const NoReport = Loadable({
	loader: () => import('./components/Reports/NoReport'),
	loading: Loading,
})
const ViewReport = Loadable({
	loader: () =>
		import(/* webpackPrefetch: true */ './components/Reports/ViewReport'),
	loading: Loading,
})
const EditReport = Loadable({
	loader: () =>
		import(/* webpackPrefetch: true */ './components/Reports/EditReport'),
	loading: Loading,
})
const CreateReport = Loadable({
	loader: () =>
		import(/* webpackPrefetch: true */ './components/Reports/CreateReport'),
	loading: Loading,
})
// import CreateReport from './components/Reports/CreateReport'

// Admin
import NoSelection from './components/Admin/NoSelection'

const CargaDeDatosDefault = Loadable({
	loader: () => import('./components/Admin/CargaDeDatos/Default'),
	loading: Loading,
})

const UserDefault = Loadable({
	loader: () => import('./components/Admin/User/Default'),
	loading: Loading,
})
const UserCreate = Loadable({
	loader: () => import('./components/Admin/User/Create'),
	loading: Loading,
})
const UserEdit = Loadable({
	loader: () => import('./components/Admin/User/Edit'),
	loading: Loading,
})

let basePath = ''

switch (window.__PROVIDER) {
	case 'sos':
		basePath = '/servicios'
		break

	case 'm2':
		basePath = '/'
		break
}

export const addToBasePath = (path = '/') => {
	return basePath === '/' ? path : `${basePath}${path}`
}

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<Route path={basePath} component={App}>
				<Route path="login" component={Login} />
				<Route path="admin" component={Admin}>
					<IndexRoute component={NoSelection} />
					<Route path="carga-de-datos">
						<IndexRoute component={CargaDeDatosDefault} />
					</Route>
					<Route path="usuarios">
						<IndexRoute component={UserDefault} />
						<Route path="new" component={UserCreate} />
						<Route path=":userId">
							<Route path="edit" component={UserEdit} />
						</Route>
					</Route>
				</Route>
				<Route component={Reports}>
					<IndexRoute component={SelectLabService} />
					<Route path=":labName">
						<IndexRoute component={SelectLabService} />
						<Route path=":serviceName">
							<IndexRoute component={NoReport} />
							<Route path="new" component={CreateReport} />
							<Route path=":queryConfigId">
								<IndexRoute component={ViewReport} />
								<Route path="edit" component={EditReport} />
							</Route>
						</Route>
					</Route>
				</Route>
			</Route>
		</Router>
	</Provider>,
	document.getElementById('target')
)
