import styled, { css } from 'styled-components'

const evenDistribution = css`
	display: flex;
	width: 100%;
	& > * {
		flex: 1;
	}
`

export default styled.div`
	& > *:not(:first-child):not(:last-child) {
		border-radius: 0;
	}

	& > *:first-child {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	& > *:last-child {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	${props => props.even && evenDistribution}
`
