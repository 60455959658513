const fontSizeBase = 12.8
const fontSans = [
	'Roboto',
	'Open Sans',
	'Avenir Next',
	'Helvetica Neue',
	'Segoe UI',
	'Helvetica',
	'Arial',
	'sans-serif',
]

const typography = {
	family: {
		accent: fontSans.map(f => `"${f}"`).join(','),
		base: fontSans.map(f => `"${f}"`).join(','),
	},
	size: {
		base: `${fontSizeBase}px`,
		h1: `${fontSizeBase * 2.6}px`,
		h2: `${fontSizeBase * 1.9}px`,
		h3: `${fontSizeBase * 1.2}px`,
		h4: `${fontSizeBase * 1.1}px`,
		h5: `${fontSizeBase * 1.1}px`,
		sm: `${fontSizeBase * 0.9}px`,
	},
	weight: {
		base: 'normal',
		h1: 300,
		h2: 300,
		h3: 400,
		h4: 400,
		h5: 400,
	},
}

export default typography
