import React, { useRef, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Loading from 'ui/Loading'
import {
	FiAlertTriangle as WarningIcon,
	FiInfo as InfoIcon,
	FiX as XIcon,
} from 'react-icons/fi'
import Unstyled from 'ui/Button/Unstyled'
import { darken } from 'polished'
import Paper from 'ui/Paper'
import { grd } from 'ui/Button'
import { format, addSeconds } from 'date-fns'

const normalNotification = css`
	${props =>
		grd(props.theme.color.accent, darken(0.05, props.theme.color.accent))}
`
const errorNotification = css`
	background: ${props => darken(0.1, props.theme.color.red)};
`
const StyledNotification = styled(Paper).attrs({ elevation: 1 })`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 1rem;

	box-shadow: none;

	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;

	border-top-right-radius: 0;
	border-bottom-right-radius: 0;

	${props => !props.error && normalNotification}
	${props => props.error && errorNotification}

	color: ${props => props.theme.color.white};

	& > *:not(:last-child) {
		margin-right: 0.8rem;
	}
`

const Message = styled.div`
	flex: 1;
`

export default function Notification({
	notification: {
		message,
		type,
		label,
		showRemoveBtn = false,
		stopwatch = false,
	},
	onRemove,
}) {
	if (Array.isArray(message)) {
		message = message.map((line, i) => <p key={i}>{line}</p>)
	}

	const isError = type === 'error'

	if (type === 'loading') {
		message = (
			<Loading style={{ borderColor: '#fff' }}>
				{message}
				{stopwatch && <Stopwatch />}
			</Loading>
		)
	}

	return (
		<StyledNotification error={isError}>
			{isError && <WarningIcon />}
			{type === 'info' && <InfoIcon />}
			<Message>{message}</Message>
			{(isError || showRemoveBtn) && (
				<Unstyled
					center
					onClick={() => onRemove(label)}
					title="Descartar"
				>
					<XIcon />
				</Unstyled>
			)}
		</StyledNotification>
	)
}

function Stopwatch() {
	const start = useRef(Date.now())
	const [elapsedSeconds, setElapsedSeconds] = useState(0)

	useEffect(() => {
		const timerId = setInterval(() => {
			const diffMs = Date.now() - start.current
			const nextElapsedSeconds = Math.floor(diffMs / 1000)
			if (nextElapsedSeconds > 0) {
				setElapsedSeconds(nextElapsedSeconds)
			}
		}, 200)

		return () => {
			clearInterval(timerId)
		}
	}, [start.current])

	return (
		<span style={{ marginLeft: '1rem' }}>
			({format(addSeconds(new Date(0), elapsedSeconds), 'mm:ss')})
		</span>
	)
}
