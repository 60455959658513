import { asImmutable } from 'utils/immutable'
import types from './actionTypes'

const initialState = asImmutable({
	online: undefined,
	login: undefined,
	credentialsMatch: false,
	navBarOpen: true,
	navBarInUse: false,
	loadingService: false,

	organizationId: null,
	serviceId: null,
	userLogin: {
		loading: false,
		success: false,
		error: null,
	},
	resumenBago: {
		loading: false,
		error: null,
	},
	multiExport: {
		state: 'idle',
		progress: null,
		error: false,
		lastExportState: null,
	},
	userRefresh: {
		loading: false,
		success: false,
		error: null,
	},
})

function reducer(state = initialState, action) {
	let nextState = state

	switch (action.type) {
		case types.SESSION_UPDATE_ALL:
			return nextState.merge(asImmutable(action.session))

		case types.SESSION_UPDATE_ONLINE:
			return nextState.set('online', action.status)

		case types.SESSION_UPDATE_LOGIN:
			return nextState.set('login', action.status)

		case types.SESSION_UPDATE_LOADING_SERVICE:
			return nextState.set('loadingService', action.loadingService)

		case types.SESSION_UPDATE_CREDENTIALS_MISMATCH:
			return nextState.set('credentialsMatch', action.status)

		case types.SESSION_TOGGLE_NAVBAR:
			return nextState.set('navBarOpen', !nextState.get('navBarOpen'))

		case types.SESSION_NAVBAR_IN_USE:
			return nextState.set('navBarInUse', action.inuse)

		case types.USER_RESUMEN_BAGO_REQUEST:
			return nextState.setIn(['resumenBago', 'loading'], true)

		case types.USER_RESUMEN_BAGO_SUCCESS:
			return nextState.setIn(['resumenBago', 'loading'], false)

		case types.USER_RESUMEN_BAGO_ERROR:
			nextState = nextState.setIn(['resumenBago', 'error'], action.error)
			return nextState.setIn(['resumenBago', 'loading'], false)

		case types.USER_MULTI_EXPORT_SELECT:
			nextState = nextState.setIn(['multiExport', 'progress'], null)
			nextState = nextState.setIn(['multiExport', 'error'], false)
			return nextState.setIn(['multiExport', 'state'], 'selecting')

		case types.USER_MULTI_EXPORT_CANCEL_SELECT:
			return nextState.setIn(['multiExport', 'state'], 'idle')

		case types.USER_MULTI_EXPORT_REQUEST:
			nextState = nextState.setIn(
				['multiExport', 'lastExportState'],
				null
			)
			return nextState.setIn(['multiExport', 'state'], 'requested')

		case types.USER_MULTI_EXPORT_PROGRESS:
			nextState = nextState.setIn(
				['multiExport', 'progress'],
				action.progress
			)
			return nextState.setIn(['multiExport', 'state'], 'progress')

		case types.USER_MULTI_EXPORT_SUCCESS:
			return nextState.setIn(['multiExport', 'state'], 'done')

		case types.USER_MULTI_EXPORT_ERROR:
			nextState = nextState.setIn(['multiExport', 'error'], true)
			return nextState.setIn(['multiExport', 'state'], 'error')

		case types.USER_MULTI_EXPORT_SET_LAST_STATE:
			return nextState.setIn(
				['multiExport', 'lastExportState'],
				action.state
			)

		case types.USER_LOGIN_REQUEST:
			nextState = nextState.setIn(['userLogin', 'loading'], true)
			nextState = nextState.setIn(['userLogin', 'success'], false)
			nextState = nextState.setIn(['userLogin', 'error'], null)
			return nextState

		case types.USER_LOGIN_SUCCESS:
			nextState = nextState.setIn(['userLogin', 'loading'], false)
			nextState = nextState.setIn(['userLogin', 'success'], true)
			nextState = nextState.setIn(['userLogin', 'error'], null)
			return nextState

		case types.USER_LOGIN_ERROR:
			nextState = nextState.setIn(['userLogin', 'loading'], false)
			nextState = nextState.setIn(['userLogin', 'success'], false)
			nextState = nextState.setIn(['userLogin', 'error'], action.error)
			return nextState

		case types.USER_LOGIN_RESET:
			nextState = nextState.setIn(['userLogin', 'loading'], false)
			nextState = nextState.setIn(['userLogin', 'success'], false)
			nextState = nextState.setIn(['userLogin', 'error'], null)
			return nextState

		case types.USER_REFRESH_REQUEST:
			nextState = nextState.setIn(['userRefresh', 'loading'], true)
			nextState = nextState.setIn(['userRefresh', 'success'], false)
			nextState = nextState.setIn(['userRefresh', 'error'], null)
			return nextState

		case types.USER_REFRESH_SUCCESS:
			nextState = nextState.setIn(['userRefresh', 'loading'], false)
			nextState = nextState.setIn(['userRefresh', 'success'], true)
			nextState = nextState.setIn(['userRefresh', 'error'], null)
			return nextState

		case types.USER_REFRESH_ERROR:
			nextState = nextState.setIn(['userRefresh', 'loading'], false)
			nextState = nextState.setIn(['userRefresh', 'success'], false)
			nextState = nextState.setIn(['userRefresh', 'error'], action.error)
			return nextState

		default:
			return nextState
	}
}

export default reducer
