import React from 'react'
import { Normalize } from 'styled-normalize'
import { createGlobalStyle } from 'styled-components'

const GlobalStyleBase = createGlobalStyle`
	* {
		box-sizing: border-box;
	}

	/* remove firefox's anchor outline */
	:focus {
		outline: none;
	}

	html,
	body {
		height: 100%;
		background-color: ${props => props.theme.color.bg};
		color: ${props => props.theme.color.fg};
	}

	html {
		font-size: ${props => props.theme.typography.size.base};
		line-height: 1.5em;
	}

	body {
		position: relative;
		font-family: ${props => props.theme.typography.family.base};
		font-weight: normal;
		word-wrap: break-word;
		font-kerning: normal;
		font-feature-settings: 'kern', 'liga', 'clig', 'calt';
		background-color: #f5f5f5;
	}

	html, body, #target{
		width: 100%;
		height: 100%;
	}

	/* not shure where we are setting other value, this is a hack */
	input {
		line-height: inherit;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	p {
		margin: 0;
		text-rendering: optimizeLegibility;
	}

	p {
		margin-bottom: 1rem;
		line-height: 1.8em;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: ${props => props.theme.typography.family.base};
		line-height: 1.5;
	}

	h1 {
		font-size: ${props => props.theme.typography.size.h1};
		font-weight: ${props => props.theme.typography.weight.h1};
	}
	h2 {
		font-size: ${props => props.theme.typography.size.h2};
		font-weight: ${props => props.theme.typography.weight.h2};
	}
	h3 {
		font-size: ${props => props.theme.typography.size.h3};
		font-weight: ${props => props.theme.typography.weight.h3};
	}
	h4 {
		font-size: ${props => props.theme.typography.size.h4};
		font-weight: ${props => props.theme.typography.weight.h4};
	}
	h5 {
		font-size: ${props => props.theme.typography.size.h5};
		font-weight: ${props => props.theme.typography.weight.h5};
	}

	ul {
		padding: 0;
	}

	th {
		font-weight: 500;
	}

	#target {
		/* we remove the transition because it lags when using filter: blur() */
		/* transition: 200ms ease-in-out; */

		&.blur {
			/* filter: blur(1px); */
		}
	}
`

export default function GlobalStyle(props) {
	return (
		<React.Fragment>
			<Normalize />
			<GlobalStyleBase {...props} />
		</React.Fragment>
	)
}
