import { asImmutable } from 'utils/immutable'

import form from './form'

const initialState = asImmutable([])

function reducer(state = initialState, action) {
	switch (action.type) {
		case form.types.DIMENSIONS_UPDATE:
			return asImmutable(action.dimensions)

		default:
			return state
	}
}

export default {
	reducer,
}
