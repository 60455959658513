import React from 'react'
import styled, { css } from 'styled-components'
import ListBase from './ListBase'

import {
	FiFolder as FolderIcon,
	FiChevronRight as ArrowRightIcon,
} from 'react-icons/fi'

import {
	Col,
	Display,
	LabelWrapper,
	Label,
	ButtonsWrapper,
	SharedIcon,
} from './common'

import Checkbox from './Checkbox'

import useHover from '../utils/useHover'

const SubList = styled(ListBase)`
	& > li > div > button:first-of-type {
		padding-left: calc(20px + ${(props) => props.idChain.length * 20}px);
	}
`
const CaretRotated = css`
	transform: translateX(-1px) rotate(90deg);
`
const Caret = styled(ArrowRightIcon)`
	transition: transform 50ms;
	margin-right: 7.2px;
	${(props) => props.open && CaretRotated}
`

const Folder = styled(FolderIcon)`
	transition: transform 50ms;
	margin-right: 0.5rem;
`

export default function ListFolder({
	id,
	label,
	buttons,
	shared = false,
	children,
	open,
	dispatch,
	selectMode,
	selected,
	idChain,
}) {
	idChain = [...idChain, id]
	const [isHovering, hoverProps] = useHover()

	function toggleOpenHandler(e) {
		dispatch({ type: 'toggleOpenFolder', idChain })
	}

	function toggleSelectHandler(e) {
		dispatch({ type: 'toggleSelectFolder', idChain })
	}

	let checked = {
		all: 1,
		none: 0,
		some: 2,
	}

	return (
		<Col as="li" style={{ lineHeight: '1.8em' }}>
			<Display {...hoverProps} sticky={open}>
				<LabelWrapper>
					<Caret open={open} onClick={toggleOpenHandler} />
					{selectMode && (
						<Checkbox
							checked={checked[selected]}
							style={{ marginRight: '.5rem' }}
							onChange={toggleSelectHandler}
						/>
					)}
					{!selectMode && <Folder onClick={toggleOpenHandler} />}
					<Label onClick={toggleOpenHandler}>
						{label}
						{shared && <SharedIcon />}
					</Label>
				</LabelWrapper>
				<ButtonsWrapper>
					{typeof buttons === 'function' && buttons({ isHovering })}
				</ButtonsWrapper>
			</Display>
			{open && (
				<SubList
					tree={children}
					selectMode={selectMode}
					dispatch={dispatch}
					idChain={idChain}
				/>
			)}
		</Col>
	)
}
