import color from './color'
import controls from './controls'
import sidebar from './sidebar'
import spacing from './spacing'
import tooltip from './tooltip'
import typography from './typography'

const theme = {
	color,
	controls,
	spacing,
	typography,
	tooltip,
	sidebar,
}

export default theme
