export default function transformSubstring(subject, search) {
	subject = String(subject)
	search = String(search).toLowerCase()

	const searches = search.split(' ')
	const subjectLower = subject.toLowerCase()

	const match = searches.find(s => subjectLower.includes(s))

	if (match) {
		const matchIdx = subjectLower.indexOf(match)

		const pre = subject.slice(0, matchIdx)
		const peri = subject.slice(matchIdx, matchIdx + match.length)
		const post = subject.slice(matchIdx + match.length)

		return { pre, post, peri }
	}

	return { pre: subject, post: '', peri: '' }
}
