import worker from '../../worker'
import { push } from 'react-router-redux'
import { createActionTypes, makeActionCreator as mac } from '@loque/reducks'
import notifications from '../notifications'
import { addToBasePath } from '../../index'
import promise from 'utils/promise'

const types = createActionTypes(['ADMIN_UPDATE_LABS'])
const actions = {}

actions.adminUpdateLabs = mac(types.ADMIN_UPDATE_LABS, 'labs')

actions.adminGetLabs = () => {
	return (dispatch: Dispatch<Function>) => {
		worker
			.get(`/admin/labs`)
			.then(res => {
				dispatch(actions.adminUpdateLabs(res.body))
			})
			.catch(res => {
				console.error(`Error fetching labs`, res)
				dispatch(
					notifications.actions.notifyError(
						`Tuvimos un error al listar los laboratorios`
					)
				)
			})
	}
}

actions.adminCreateLab = lab => {
	return (dispatch: Dispatch<Function>) => {
		dispatch(
			notifications.actions.notify(`create-lab`, `Creando laboratorio...`)
		)

		worker
			.post(`/admin/labs`, lab)
			.then(res => {
				dispatch(push(addToBasePath(`/admin/laboratorios`)))
				dispatch(notifications.actions.notifyEnd(`create-lab`))
			})
			.catch(res => {
				console.error(`Error creating the lab`, res)
				dispatch(notifications.actions.notifyEnd(`create-lab`))
				dispatch(
					notifications.actions.notifyError(
						`Tuvimos un error al crear el laboratorio`
					)
				)
			})
	}
}

actions.adminUpdateLab = (
	id: number,
	lab: { name: string, serviceIds: Array<number> }
) => {
	return (dispatch: Dispatch<Function>) => {
		dispatch(
			notifications.actions.notify(
				`update-lab`,
				`Actualizando laboratorio...`
			)
		)

		worker
			.put(`/admin/labs/${id}`, lab)
			.then(res => {
				dispatch(push(addToBasePath(`/admin/laboratorios`)))
				dispatch(notifications.actions.notifyEnd(`update-lab`))
			})
			.catch(res => {
				console.error(`Error updating the lab`, res)
				dispatch(notifications.actions.notifyEnd(`update-lab`))
				dispatch(
					notifications.actions.notifyError(
						`Tuvimos un error al actualizar el laboratorio`
					)
				)
			})
	}
}

actions.adminDeleteLab = (id: number) => {
	return (dispatch: Dispatch<Function>) => {
		dispatch(
			notifications.actions.notify(
				`delete-v`,
				`Eliminando laboratorio...`
			)
		)

		worker
			.delete(`/admin/labs/${id}`)
			.then(res => {
				dispatch(push(addToBasePath(`/admin/laboratorios`)))
				dispatch(notifications.actions.notifyEnd(`delete-lab`))
			})
			.catch(res => {
				console.error(`Error deleting the lab`, res)
				dispatch(notifications.actions.notifyEnd(`delete-lab`))
				dispatch(
					notifications.actions.notifyError(
						`Tuvimos un error al eliminar el laboratorio`
					)
				)
			})
	}
}

actions.adminUpdateLabService = (
	id: number,
	service: { id: number, last_update: string, version: number }
) => {
	return (dispatch: Dispatch<Function>) => {
		dispatch(
			notifications.actions.notify(
				`update-lab-service`,
				`Actualizando servicio del laboratorio...`
			)
		)

		worker
			.put(`/admin/labs/${id}/services/${service.id}`, service)
			.then(res => {
				dispatch(push(addToBasePath(`/admin/laboratorios`)))
				dispatch(notifications.actions.notifyEnd(`update-lab-service`))
			})
			.catch(res => {
				console.error(`Error updating the lab-service`, res)
				dispatch(notifications.actions.notifyEnd(`update-lab-service`))
				dispatch(
					notifications.actions.notifyError(
						`Tuvimos un error al actualizar el servicio del laboratorio`
					)
				)
			})
	}
}

actions.adminDeleteLabService = (organizationId: number, serviceId: number) => {
	return (dispatch: Dispatch<Function>) => {
		dispatch(
			notifications.actions.notify(
				`delete-lab-service`,
				`Eliminando servicio del laboratorio...`
			)
		)

		worker
			.delete(`/admin/labs/${organizationId}/services/${serviceId}`)
			.then(res => {
				dispatch(push(addToBasePath(`/admin/laboratorios`)))
				dispatch(notifications.actions.notifyEnd(`delete-lab-service`))
			})
			.catch(res => {
				console.error(`Error deleting the lab-service`, res)
				dispatch(notifications.actions.notifyEnd(`delete-lab-service`))
				dispatch(
					notifications.actions.notifyError(
						`Tuvimos un error al eliminar el servicio del laboratorio`
					)
				)
			})
	}
}

actions.adminUpdateLabServiceDataset = (
	providerId: number,
	orgId: number,
	serviceId: number,
	lastUpdate: string
) => {
	return (dispatch: Dispatch<Function>) => {
		dispatch(
			notifications.actions.notify(
				`update-lab-service-dataset`,
				`Actualizando el dataset...`,
				null,
				false,
				true
			)
		)

		const result = promise()

		worker
			.put(`/admin/labs/${orgId}/services/${serviceId}/dataset`, {
				lastUpdate,
				providerId,
			})
			.then(() => {
				dispatch(push(addToBasePath(`/admin/carga-de-datos`)))
				dispatch(
					notifications.actions.notifyEnd(
						`update-lab-service-dataset`
					)
				)

				result.resolve()
			})
			.catch(res => {
				console.error(`Error updating the lab-service-dataset`, res)
				dispatch(
					notifications.actions.notifyEnd(
						`update-lab-service-dataset`
					)
				)

				if (res.body.description === 'Laboratorio incorrecto') {
					dispatch(
						notifications.actions.notifyError(
							`Los datos cargados en origen no son de este laboratorio.`
						)
					)
				} else {
					dispatch(
						notifications.actions.notifyError(
							`Tuvimos un error al actualizar el dataset`
						)
					)
				}
				result.reject()
			})

		return result
	}
}

export default {
	types,
	actions,
}
