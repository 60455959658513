import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ducks from 'front/ducks'

import Modal from 'ui/Modal'
import ModalTitle from 'ui/Modal/Title'
import ModalBody from 'ui/Modal/Body'
import ModalActions from 'ui/Modal/Actions'
import MenuButton from 'ui/Dropdown/MenuButton'
import Loading from 'ui/Loading'
import Button from 'ui/Button'
import Input from 'ui/Forms/Input'
import { useAction } from '~/modules/utils/redux'
import { FiEdit3 as RenameIcon } from 'react-icons/fi'

function RenameFolderModal({
	folderId,
	prevFolderName,
	visible,
	setVisible,
	reportCode,
}) {
	const [internalVisible, setInternalVisible] = useState(visible)
	const [folderName, setFolderName] = useState(prevFolderName)

	const { requestId, loading, error } = useSelector(state =>
		state.getIn(['folders', 'rename']).toJS()
	)

	const foldersRename = useAction(ducks.actions.foldersRename)
	const foldersRenameReset = useAction(ducks.actions.foldersRenameReset)

	function runSecondaryAction(event) {
		event && event.preventDefault()
		setInternalVisible(false)
		setFolderName(prevFolderName)
	}

	function runPrimaryAction(event) {
		event.preventDefault()
		foldersRename(folderId, folderName)
	}

	// automatically hide modal when request is successfull
	useEffect(() => {
		if (
			visible &&
			loading === false &&
			error === null &&
			requestId === folderId
		) {
			setInternalVisible(false)
		}
	}, [visible, loading, error, requestId, folderId])

	// we delay the reset so that it happens once the modal is hidden
	useEffect(() => () => foldersRenameReset(), [])

	let state
	let body

	if (loading) {
		// loading
		state = 1
		body = <Loading>Procesando solicitud...</Loading>
	} else if (error) {
		// error
		state = 2
		let msg = `Tuvimos un error al renombrar la ${
			reportCode === 'market' ? 'línea' : 'carpeta'
		}.`
		if (
			error.description ===
			'folder name is taken in this service for this user'
		) {
			msg = `Ya existe una ${
				reportCode === 'market' ? 'línea' : 'carpeta'
			} con el nombre "${folderName}".`
		}
		body = <p>{msg}</p>
	} else if (!loading && !error && requestId === null) {
		// waiting
		state = 0
		body = (
			<form onSubmit={runPrimaryAction}>
				<Input
					label="Nuevo nombre"
					value={folderName}
					onChange={e => setFolderName(e.target.value)}
				/>
			</form>
		)
	}

	return (
		<Modal
			visible={internalVisible}
			onDestroyed={value => setVisible(false)}
		>
			<ModalTitle>
				Renombrar {reportCode === 'market' ? 'línea' : 'carpeta'}
			</ModalTitle>
			<ModalBody>{body}</ModalBody>
			<ModalActions>
				{state === 2 && (
					<Button role="primary" onClick={runSecondaryAction}>
						Cerrar
					</Button>
				)}
				{state === 0 && (
					<Button
						role="secondary"
						onClick={runSecondaryAction}
						disabled={state === 1}
					>
						Cancelar
					</Button>
				)}
				{state === 0 && (
					<Button
						role="primary"
						onClick={runPrimaryAction}
						disabled={state === 1}
					>
						Guardar
					</Button>
				)}
			</ModalActions>
		</Modal>
	)
}

export default function useRenameFolder(folderId, prevFolderName) {
	const [visible, setVisible] = useState(false)

	const reportCode = useSelector(state => {
		const { organizationId, serviceId } = state.get('session').toJS()
		return state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
			'report_code',
		])
	})

	const modal = visible && (
		<RenameFolderModal
			folderId={folderId}
			prevFolderName={prevFolderName}
			visible={visible}
			setVisible={setVisible}
			reportCode={reportCode}
		/>
	)

	const button = (
		<MenuButton onClick={() => setVisible(true)} icon={<RenameIcon />}>
			Renombrar {reportCode === 'market' ? 'línea' : 'carpeta'}
		</MenuButton>
	)

	return { button, modal }
}
