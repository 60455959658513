import React from 'react'
import PropTypes from 'prop-types'
import transformSubstring from '../../utils/transformSubstring'

import { Table, THead, TBody, Tr, Th, Td } from 'ui/Table'

export default class AdminTable extends React.Component {
	static propTypes = {
		header: PropTypes.arrayOf(
			PropTypes.arrayOf(
				PropTypes.shape({
					value: PropTypes.any.isRequired,
				})
			)
		),
		body: PropTypes.arrayOf(
			PropTypes.arrayOf(
				PropTypes.shape({
					value: PropTypes.any.isRequired,
				})
			)
		),
		defaultSortDim: PropTypes.string,
		defaultSortDir: PropTypes.oneOf(['asc', 'desc']),
	}

	static defaultProps = {
		header: [],
		defaultSortDir: 'asc',
		body: [],
	}

	constructor(props) {
		super(props)

		this.state = {
			sort: {},
		}

		this.setSort = this.setSort.bind(this)
	}

	componentDidMount() {
		this.setState({
			sort: {
				dimension: this.props.defaultSortDim,
				direction: this.props.defaultSortDir,
			},
		})
	}

	setSort(cell) {
		if (this.state.sort.dimension === cell.dimension) {
			this.setState((state) => {
				state.sort.direction =
					state.sort.direction === 'asc' ? 'desc' : 'asc'

				return state
			})

			return
		}

		this.setState((state) => {
			state.sort.dimension = cell.dimension
			state.sort.direction = this.props.defaultSortDir
			state.sort.type = cell.isNumber === true ? 'number' : 'string'

			return state
		})
	}

	render() {
		let header, body

		if (this.props.header.length) {
			const headerRows = this.props.header.map((row, rowIdx) => {
				const rowCells = row.map((cell, cellIdx) => {
					let onClick,
						classNames = ''

					if (cell.hasOwnProperty('dimension')) {
						classNames += ' clickable'
						onClick = () => this.setSort(cell)

						if (cell.dimension === this.state.sort.dimension) {
							classNames += ` sorter ${this.state.sort.direction}`
						}
					}

					return (
						<Th
							key={cellIdx}
							className={classNames}
							onClick={onClick}
						>
							{cell.value}
						</Th>
					)
				})

				return <Tr key={rowIdx}>{rowCells}</Tr>
			})

			header = <THead>{headerRows}</THead>
		}

		if (this.props.body.length) {
			let bodyRows = this.props.body.slice()

			if (this.state.sort.dimension) {
				bodyRows = applySort(bodyRows, {
					dimension: this.state.sort.dimension,
					direction: this.state.sort.direction,
					type: this.state.sort.type || typeof bodyRows[0][0].value,
				})
			}

			if (this.props.search !== '') {
				bodyRows = applyTransformSubstring(bodyRows, this.props.search)
			}

			bodyRows = bodyRows.map((row, rowIdx) => {
				const rowCells = row.map((cell, cellIdx) => {
					let value = cell.value

					if (cell.highlighted) {
						value = (
							<React.Fragment>
								{cell.highlighted.pre}
								<span className="highlighted">
									{cell.highlighted.peri}
								</span>
								{cell.highlighted.post}
							</React.Fragment>
						)
					}

					let align = cell.action ? 'right' : 'left'

					if (cell.align) {
						align = cell.align
					}

					return (
						<Td align={align} key={cellIdx}>
							{value}
						</Td>
					)
				})

				return <Tr key={rowIdx}>{rowCells}</Tr>
			})

			body = <TBody>{bodyRows}</TBody>
		}

		return (
			<Table>
				{header}
				{body}
			</Table>
		)
	}
}

function applyTransformSubstring(dataset, search) {
	return dataset.map((row) => {
		return row.map((cell) => {
			if (!cell.action) {
				cell.highlighted = transformSubstring(cell.value, search)
			}
			return cell
		})
	})
}

function applySort(dataset, sorter) {
	return dataset.sort((a, b) => {
		let firstRow, secondRow

		if (sorter.direction === 'asc') {
			firstRow = a
			secondRow = b
		} else if (sorter.direction === 'desc') {
			firstRow = b
			secondRow = a
		}

		const firstRowCell = firstRow
			.filter((cell) => cell.hasOwnProperty('dimension'))
			.find((cell) => cell.dimension === sorter.dimension)
		const secondRowCell = secondRow
			.filter((cell) => cell.hasOwnProperty('dimension'))
			.find((cell) => cell.dimension === sorter.dimension)

		if (
			firstRowCell === undefined ||
			!firstRowCell.hasOwnProperty('value') ||
			secondRowCell === undefined ||
			!secondRowCell.hasOwnProperty('value')
		)
			return 0

		switch (sorter.type) {
			case 'number':
				return firstRowCell.value - secondRowCell.value
				break

			case 'string':
				return firstRowCell.value.localeCompare(secondRowCell.value)
				break

			default:
				throw new TypeError(
					`applySort expects sorter.type to be (number|string), "${sorter.type}" given.`
				)
		}
	})
}
