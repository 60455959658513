import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ducks from 'front/ducks'

import Modal from 'ui/Modal'
import ModalTitle from 'ui/Modal/Title'
import ModalBody from 'ui/Modal/Body'
import ModalActions from 'ui/Modal/Actions'
import MenuButton from 'ui/Dropdown/MenuButton'
import Loading from 'ui/Loading'
import Button from 'ui/Button'
import { useAction } from '~/modules/utils/redux'
import { FiCornerRightDown as RightDownArrowIcon } from 'react-icons/fi'

function MoveReportOutOfFolderModal({
	reportId,
	reportName,

	visible,
	setVisible,
}) {
	const [internalVisible, setInternalVisible] = useState(visible)

	const { requestId, loading, error, success } = useSelector(state =>
		state.getIn(['queries', 'putFolder']).toJS()
	)

	const reportCode = useSelector(state => {
		const { organizationId, serviceId } = state.get('session').toJS()
		return state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
			'report_code',
		])
	})

	const putFolder = useAction(ducks.actions.queriesPutFolder)
	const confirmPutFolder = useAction(ducks.actions.queriesPutFolderConfirm)
	const resetPutFolder = useAction(ducks.actions.queriesPutFolderReset)

	useEffect(() => {
		if (internalVisible && !loading && reportId !== requestId) {
			putFolder(reportId)
		}
	}, [internalVisible, loading, requestId, reportId, putFolder])

	useEffect(() => {
		if (internalVisible && success && reportId === requestId) {
			setInternalVisible(false)
		}
	}, [internalVisible, success, requestId, reportId])

	function runSecondaryAction(event) {
		event && event.preventDefault()
		setInternalVisible(false)
	}

	// we delay the reset so that it happens once the modal is hidden
	useEffect(() => () => resetPutFolder(), [])

	let state
	let body

	if (loading) {
		// loading
		state = 1
		body = <Loading>Procesando solicitud...</Loading>
	} else if (error) {
		// error
		state = 2
		body = (
			<p>
				Tuvimos un error al mover{' '}
				{reportCode === 'market' ? 'el mercado' : 'la consulta'}.
			</p>
		)
	}

	return (
		<Modal
			visible={internalVisible}
			onDestroyed={() => {
				if (success && reportId === requestId) {
					confirmPutFolder(reportId)
				}
				setVisible(false)
			}}
		>
			<ModalTitle>
				Sacar {reportName && <i>{reportName}</i>} de la{' '}
				{reportCode === 'market' ? 'línea' : 'carpeta'}
			</ModalTitle>
			<ModalBody>{body}</ModalBody>
			<ModalActions>
				{state === 2 && (
					<Button role="primary" onClick={runSecondaryAction}>
						Cerrar
					</Button>
				)}
			</ModalActions>
		</Modal>
	)
}

export default function useMoveReportOutOfFolder(
	reportId,
	reportName,
	currFolderId
) {
	const [visible, setVisible] = useState(false)

	const reportCode = useSelector(state => {
		const { organizationId, serviceId } = state.get('session').toJS()
		return state.getIn([
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
			'report_code',
		])
	})

	if (currFolderId === null || currFolderId === undefined) {
		// already out of folder
		return { button: null, modal: null }
	}

	const modal = visible && (
		<MoveReportOutOfFolderModal
			reportId={reportId}
			reportName={reportName}
			visible={visible}
			setVisible={setVisible}
		/>
	)

	const button = (
		<MenuButton
			onClick={() => setVisible(true)}
			icon={<RightDownArrowIcon />}
		>
			Sacar de la {reportCode === 'market' ? 'línea' : 'carpeta'}
		</MenuButton>
	)

	return { button, modal }
}
