const spacingVStd = 64
const spacingHStd = 32

const spacing = {
	v: {
		lrgr: `${spacingVStd * 1.4}px`,
		lrg: `${spacingVStd * 1.2}px`,
		std: `${spacingVStd}px`,
		sm: `${spacingVStd * 0.6}px`,
		smr: `${spacingVStd * 0.4}px`,
		smst: `${spacingVStd * 0.2}px`,
	},
	h: {
		std: `${spacingHStd}px`,
		sm: `${spacingHStd * 0.6}px`,
		smr: `${spacingHStd * 0.4}px`,
		smst: `${spacingHStd * 0.2}px`,
	},
}

export default spacing
