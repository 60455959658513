import { combineDucks } from '@loque/reducks'

import adminLab from './admin/lab'
import adminProvider from './admin/provider'
import adminService from './admin/service'
import adminServiceStructure from './admin/serviceStructure'
import adminSession from './admin/session'
import adminUser from './admin/user'
import liveFilters from './liveFilters'
import queries from './queries'
import queryTmp from './queryTmp'
import session from './session'
import user from './user'
import admin from './admin'
import dimensions from './dimensions'
import filters from './filters'
import form from './form'
import measures from './measures'
import nav from './nav'
import notifications from './notifications'
import periods from './periods'
import folders from './folders'
// import presPerPrd from './presPerPrd'
import queriesPresProd from './queriesPresProd'
import queryPage from './queryPage'
import report from './report'
import routing from './routing'
import sintesisK from './sintesisK'
import tmp from './tmp'
import debug from './debug'

export default combineDucks({
	adminLab,
	adminProvider,
	adminService,
	adminServiceStructure,
	adminSession,
	adminUser,

	liveFilters,
	queries,
	queryTmp,
	session,
	user,
	admin,
	dimensions,
	filters,
	form,
	measures,
	nav,
	notifications,
	periods,
	folders,
	// presPerPrd,
	queriesPresProd,
	queryPage,
	report,
	routing,
	sintesisK,
	tmp,
	debug,
})
