import { useState, useEffect, useRef } from 'react'

export default function useHover() {
	const ref = useRef()
	const [isHovering, setIsHovering] = useState(false)

	useEffect(() => {
		if (!ref.current) return

		function handleMouseMove() {
			// we need to check that the ref is still there so that we
			// don't try to perform a state update on an unmounted component
			ref.current && setIsHovering(ref.current.matches(':hover'))
		}

		function handleMouseOut() {
			// we need to check that the ref is still there so that we
			// don't try to perform a state update on an unmounted component
			ref.current && setIsHovering(false)
		}

		// set isHovering false when mouse moves out of the window
		document.addEventListener('mouseout', handleMouseOut, {
			capture: true,
			passive: true,
		})

		// update isHovering with every mousemove
		document.addEventListener('mousemove', handleMouseMove, {
			capture: true,
			passive: true,
		})

		return () => {
			document.removeEventListener('mouseout', handleMouseOut)
			document.removeEventListener('mousemove', handleMouseMove)
		}
	}, [ref.current])

	return [isHovering, { ref }]
}
