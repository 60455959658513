import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import ducks from 'front/ducks'
import { asImmutable } from 'utils/immutable'

import LabServicePicker from '../LabServicePicker'
import ReportsList from './ReportsList'
import SintesisK from './SintesisK'

import Loading from 'ui/Loading'
import Button from 'ui/Button'
import { FiDownload as DownloadIcon } from 'react-icons/fi'

import NavBar from 'ui/NavBar/NavBar'
import NavBarSection from 'ui/NavBar/NavBarSection'
import NavBarSeparator from 'ui/NavBar/NavBarSeparator'
import NavBarItem from 'ui/NavBar/NavBarItem'

import { FiChevronRight as ChevronRightIcon } from 'react-icons/fi'
import NavBarHeader from '../NavBarHeader'

function ReportsNav(props) {
	const selectMode = props.multiExport.get('state') === 'selecting'
	const [selected, setSelected] = useState([])

	function handleDelete(query) {
		props.queriesDelete(query)
		props.navToLabService()
	}

	useEffect(() => {
		props.sessionNavBarInUse(true)
		return () => {
			props.sessionNavBarInUse(false)
		}
	}, [])

	let nodes = []

	nodes = nodes.concat(
		<NavBarSection key={nodes.length}>
			<NavBarItem>
				<LabServicePicker />
			</NavBarItem>
		</NavBarSection>
	)

	if (props.loadingQueries === true) {
		nodes = nodes.concat(<NavBarSeparator key={nodes.length} />)

		const consultas =
			props.reportCode === 'market' ? 'mercados' : 'consultas'

		nodes = nodes.concat(
			<NavBarSection key={nodes.length}>
				<NavBar.SubItem
					label={
						<Loading>
							Cargando {consultas}
							...
						</Loading>
					}
				/>
			</NavBarSection>
		)
	} else if (
		props.hideReportsList === undefined ||
		props.hideReportsList === false
	) {
		nodes = nodes.concat(<NavBarSeparator key={nodes.length} />)

		nodes = nodes.concat(
			<ReportsList
				serviceName={props.serviceName}
				queries={props.queries}
				queryConfigId={props.queryConfigId}
				key={nodes.length}
				deleteQuery={handleDelete}
				reportCode={props.reportCode}
				selectMode={selectMode}
				setSelected={setSelected}
			/>
		)
	} else {
		nodes = nodes.concat(<NavBarSection key={nodes.length} expand={true} />)
	}

	let multiExportButton
	if (props.multiExportAllowed) {
		let lastMultiBtn
		let multiExportState = props.multiExport.get('state')
		if (['requested', 'progress'].includes(multiExportState)) {
			let progress
			if (
				typeof props.multiExport.get('progress') === 'string' &&
				props.multiExport.get('progress') !== '0%'
			) {
				progress = ' (' + props.multiExport.get('progress') + ')'
			}
			multiExportButton = (
				<Loading>Preparando exportación{progress}...</Loading>
			)
		} else if (multiExportState === 'selecting') {
			multiExportButton = (
				<React.Fragment>
					<Button onClick={() => props.userMultiExportCancelSelect()}>
						Cancelar
					</Button>
					<Button
						role="primary"
						disabled={selected.length === 0}
						onClick={() => {
							props.userMultiExportRequest(selected)
						}}
					>
						Exportar {`(${selected.length})`}
						<ChevronRightIcon />
					</Button>
				</React.Fragment>
			)
		} else {
			multiExportButton = (
				<Button
					role="link"
					noHover
					onClick={() => {
						props.userMultiExportSelect()
					}}
				>
					Exportación múltiple
					<ChevronRightIcon />
				</Button>
			)

			if (
				['done', 'downloaded'].includes(
					props.multiExport.get('lastExportState')
				)
			) {
				lastMultiBtn = (
					<Button
						role="secondary"
						title="Descargar la última exportación múltiple"
						onClick={() => props.userGetLastMultiExport()}
					>
						<DownloadIcon />
					</Button>
				)
			}
		}

		multiExportButton = (
			<NavBarItem
				style={{ display: 'flex', justifyContent: 'space-between' }}
			>
				{multiExportButton}
				{lastMultiBtn}
			</NavBarItem>
		)
	}

	let resumenBagoButton
	if (props.serviceId === 8 && !selectMode && props.labName === 'BAGO') {
		if (props.resumenBagoState.get('loading')) {
			resumenBagoButton = <Loading>Solicitando extracción...</Loading>
		} else {
			resumenBagoButton = (
				<Button
					role="link"
					noHover
					onClick={() => {
						props.userResumenBagoRequest(
							props.organizationId,
							props.serviceId
						)
					}}
				>
					Extracción Bagó
					<ChevronRightIcon />
				</Button>
			)
		}
		resumenBagoButton = <NavBarItem>{resumenBagoButton}</NavBarItem>
	}

	let sintesisKButton
	if (!selectMode && props.serviceId === 8) {
		sintesisKButton = (
			<NavBarItem>
				<SintesisK />
			</NavBarItem>
		)
	}

	if (resumenBagoButton || sintesisKButton || multiExportButton) {
		nodes = nodes.concat(
			<NavBarSection key={nodes.length}>
				{multiExportButton}
				{resumenBagoButton}
				{sintesisKButton}
			</NavBarSection>
		)
		nodes = nodes.concat(<NavBarSeparator key={nodes.length} />)
	}

	return (
		<NavBar>
			<NavBarHeader />
			{nodes}
		</NavBar>
	)
}

const mapStateToProps = (state) => {
	const organizationId = state.getIn(['session', 'organizationId'])
	const serviceId = state.getIn(['session', 'serviceId'])
	const queryConfigId = state.getIn(['queries', 'queryConfigId'])

	const labName = state
		.getIn(['user', 'organizations', `${organizationId}`, 'name'], '')
		.toUpperCase()
	const service = state.getIn(
		[
			'user',
			'organizations',
			`${organizationId}`,
			'services',
			`${serviceId}`,
		],
		asImmutable({})
	)
	const queries = service.get('queries', asImmutable([]))

	const reportCode = state.getIn([
		'user',
		'organizations',
		`${organizationId}`,
		'services',
		`${serviceId}`,
		'report_code',
	])

	return {
		user: state.get('user'),
		organizationId,
		serviceId,
		multiExport: state.getIn(['session', 'multiExport']),
		resumenBagoState: state.getIn(['session', 'resumenBago']),
		multiExportAllowed: !!service.get('multi_export'),
		labName,
		serviceName: service.get('name', '').toUpperCase(),
		queryConfigId,
		queries,
		loadingQueries: state.getIn(['session', 'loadingQueries']),
		reportCode,
	}
}

const mapDispatchToProps = {
	queriesDelete: ducks.actions.queriesDelete,
	navToLabService: ducks.actions.navToLabService,
	userResumenBagoRequest: ducks.actions.userResumenBagoRequest,
	userMultiExportRequest: ducks.actions.userMultiExportRequest,
	userMultiExportSelect: ducks.actions.userMultiExportSelect,
	userMultiExportCancelSelect: ducks.actions.userMultiExportCancelSelect,
	userGetLastMultiExport: ducks.actions.userGetLastMultiExport,
	sessionNavBarInUse: ducks.actions.sessionNavBarInUse,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsNav)
