import { createActionTypes, makeActionCreator as mac } from '@loque/reducks'
import worker from '../worker'
import { asImmutable } from 'utils/immutable'

const types = createActionTypes([
	'FOLDERS_CREATE_REQUEST',
	'FOLDERS_CREATE_SUCCESS',
	'FOLDERS_CREATE_ERROR',
	'FOLDERS_CREATE_RESET',

	'FOLDERS_DELETE_REQUEST',
	'FOLDERS_DELETE_SUCCESS',
	'FOLDERS_DELETE_ERROR',
	'FOLDERS_DELETE_RESET',

	'FOLDERS_RENAME_REQUEST',
	'FOLDERS_RENAME_SUCCESS',
	'FOLDERS_RENAME_ERROR',
	'FOLDERS_RENAME_RESET',

	'FOLDERS_GET_TARGETS_REQUEST',
	'FOLDERS_GET_TARGETS_SUCCESS',
	'FOLDERS_GET_TARGETS_ERROR',
	'FOLDERS_GET_TARGETS_RESET',

	'FOLDERS_PUT_TARGETS_REQUEST',
	'FOLDERS_PUT_TARGETS_SUCCESS',
	'FOLDERS_PUT_TARGETS_ERROR',
])

const initialState = asImmutable({
	createRequestId: null,
	createLoading: false,
	createError: null,

	delete: {
		requestId: null,
		loading: false,
		error: null,
	},

	rename: {
		requestId: null,
		loading: false,
		error: null,
	},

	targets: {
		targets: [],
		loading: false,
		error: null,

		updateLoading: false,
		updateError: null,
	},
})

function reducer(state = initialState, action) {
	let next = state
	switch (action.type) {
		// CREATE
		case types.FOLDERS_CREATE_REQUEST:
			next = next.set('createRequestId', action.requestId)
			next = next.set('createError', null)
			return next.set('createLoading', true)

		case types.FOLDERS_CREATE_SUCCESS:
			next = next.set('createError', null)
			return next.set('createLoading', false)

		case types.FOLDERS_CREATE_ERROR:
			next = next.set('createError', action.error)
			return next.set('createLoading', false)

		case types.FOLDERS_CREATE_RESET:
			next = next.set('createRequestId', null)
			next = next.set('createError', null)
			return next.set('createLoading', false)

		// DELETE
		case types.FOLDERS_DELETE_REQUEST:
			next = next.setIn(['delete', 'requestId'], action.requestId)
			next = next.setIn(['delete', 'error'], null)
			next = next.setIn(['delete', 'loading'], true)
			return next

		case types.FOLDERS_DELETE_SUCCESS:
			next = next.setIn(['delete', 'error'], null)
			next = next.setIn(['delete', 'loading'], false)
			return next

		case types.FOLDERS_DELETE_ERROR:
			next = next.setIn(['delete', 'error'], action.error)
			next = next.setIn(['delete', 'loading'], false)
			return next

		case types.FOLDERS_DELETE_RESET:
			next = next.setIn(['delete', 'requestId'], null)
			next = next.setIn(['delete', 'error'], null)
			next = next.setIn(['delete', 'loading'], false)
			return next

		// RENAME
		case types.FOLDERS_RENAME_REQUEST:
			next = next.setIn(['rename', 'requestId'], action.requestId)
			next = next.setIn(['rename', 'error'], null)
			next = next.setIn(['rename', 'loading'], true)
			return next

		case types.FOLDERS_RENAME_SUCCESS:
			next = next.setIn(['rename', 'error'], null)
			next = next.setIn(['rename', 'loading'], false)
			return next

		case types.FOLDERS_RENAME_ERROR:
			next = next.setIn(['rename', 'error'], action.error)
			next = next.setIn(['rename', 'loading'], false)
			return next

		case types.FOLDERS_RENAME_RESET:
			next = next.setIn(['rename', 'requestId'], null)
			next = next.setIn(['rename', 'error'], null)
			next = next.setIn(['rename', 'loading'], false)
			return next

		// GET TARGETS
		case types.FOLDERS_GET_TARGETS_REQUEST:
			next = next.setIn(['targets', 'error'], null)
			next = next.setIn(['targets', 'loading'], true)
			return next

		case types.FOLDERS_GET_TARGETS_SUCCESS:
			next = next.setIn(['targets', 'error'], null)
			next = next.setIn(['targets', 'loading'], false)
			next = next.setIn(['targets', 'targets'], action.targets)
			return next

		case types.FOLDERS_GET_TARGETS_ERROR:
			next = next.setIn(['targets', 'error'], action.error)
			next = next.setIn(['targets', 'loading'], false)
			return next

		case types.FOLDERS_GET_TARGETS_RESET:
			next = next.setIn(['targets', 'error'], null)
			next = next.setIn(['targets', 'loading'], false)
			next = next.setIn(['targets', 'targets'], [])
			return next

		// PUT TARGETS
		case types.FOLDERS_PUT_TARGETS_REQUEST:
			next = next.setIn(['targets', 'updateError'], null)
			next = next.setIn(['targets', 'updateLoading'], true)
			return next

		case types.FOLDERS_PUT_TARGETS_SUCCESS:
			next = next.setIn(['targets', 'updateError'], null)
			next = next.setIn(['targets', 'updateLoading'], false)
			next = next.setIn(['targets', 'targets'], [])
			return next

		case types.FOLDERS_PUT_TARGETS_ERROR:
			next = next.setIn(['targets', 'updateError'], action.error)
			next = next.setIn(['targets', 'updateLoading'], false)
			return next

		default:
			return next
	}
}

const actions = {}

actions.foldersCreateRequest = mac(types.FOLDERS_CREATE_REQUEST, 'requestId')
actions.foldersCreateSuccess = mac(types.FOLDERS_CREATE_SUCCESS)
actions.foldersCreateError = mac(types.FOLDERS_CREATE_ERROR, 'error')
actions.foldersCreateReset = mac(types.FOLDERS_CREATE_RESET)
actions.foldersCreate = (requestId, folderName) => {
	return (dispatch, getState) => {
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		dispatch(actions.foldersCreateRequest(requestId))

		return worker
			.post('/folders', { folderName, organizationId, serviceId })
			.then(res => {
				dispatch(actions.foldersCreateSuccess())
				return res.body
			})
			.catch(res => {
				console.error(`Error creating folder`, res)
				dispatch(
					actions.foldersCreateError(
						(res.body && res.body.error && res.body.error) || res
					)
				)
			})
	}
}

actions.foldersDeleteRequest = mac(types.FOLDERS_DELETE_REQUEST, 'requestId')
actions.foldersDeleteSuccess = mac(types.FOLDERS_DELETE_SUCCESS)
actions.foldersDeleteError = mac(types.FOLDERS_DELETE_ERROR, 'error')
actions.foldersDeleteReset = mac(types.FOLDERS_DELETE_RESET)
actions.foldersDelete = folderId => {
	return (dispatch, getState) => {
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		dispatch(actions.foldersDeleteRequest(folderId))

		worker
			.delete(
				`/folders/${folderId}?organizationId=${organizationId}&serviceId=${serviceId}`
			)
			.then(() => {
				dispatch(actions.foldersDeleteSuccess())
			})
			.catch(res => {
				console.error(`Error deleting folder`, res)
				dispatch(
					actions.foldersDeleteError(
						(res.body && res.body.error && res.body.error) || res
					)
				)
			})
	}
}

actions.foldersDeleteConfirm = folderId => {
	return (_, getState) => {
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		worker.delete(
			`/folders/${folderId}/confirm?organizationId=${organizationId}&serviceId=${serviceId}`
		)
	}
}

actions.foldersRenameRequest = mac(types.FOLDERS_RENAME_REQUEST, 'requestId')
actions.foldersRenameSuccess = mac(types.FOLDERS_RENAME_SUCCESS)
actions.foldersRenameError = mac(types.FOLDERS_RENAME_ERROR, 'error')
actions.foldersRenameReset = mac(types.FOLDERS_RENAME_RESET)
actions.foldersRename = (folderId, folderName) => {
	return (dispatch, getState) => {
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		dispatch(actions.foldersRenameRequest(folderId))

		worker
			.put(`/folders/${folderId}/name`, {
				folderName,
				organizationId,
				serviceId,
			})
			.then(() => {
				dispatch(actions.foldersRenameSuccess())
			})
			.catch(res => {
				console.error(`Error renaming folder`, res)
				dispatch(
					actions.foldersRenameError(
						(res.body && res.body.error && res.body.error) || res
					)
				)
			})
	}
}

actions.foldersTargetsRequest = mac(types.FOLDERS_GET_TARGETS_REQUEST)
actions.foldersTargetsSuccess = mac(
	types.FOLDERS_GET_TARGETS_SUCCESS,
	'targets'
)
actions.foldersTargetsError = mac(types.FOLDERS_GET_TARGETS_ERROR, 'error')
actions.foldersTargetsReset = mac(types.FOLDERS_GET_TARGETS_RESET)
actions.foldersGetTargets = folderId => {
	return (dispatch, getState) => {
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		dispatch(actions.foldersTargetsRequest())

		worker
			.get(
				`/folders/${folderId}/targets?organizationId=${organizationId}&serviceId=${serviceId}`
			)
			.then(res => {
				dispatch(actions.foldersTargetsSuccess(res.body))
			})
			.catch(res => {
				console.error(`Error getting folder targets`, res)
				dispatch(
					actions.foldersTargetsError(
						(res.body && res.body.error && res.body.error) || res
					)
				)
			})
	}
}

actions.foldersPutTargetsRequest = mac(types.FOLDERS_PUT_TARGETS_REQUEST)
actions.foldersPutTargetsSuccess = mac(types.FOLDERS_PUT_TARGETS_SUCCESS)
actions.foldersPutTargetsError = mac(types.FOLDERS_PUT_TARGETS_ERROR, 'error')
actions.foldersUpdateTargets = (folderId, targets) => {
	return (dispatch, getState) => {
		const organizationId = getState().getIn(['session', 'organizationId'])
		const serviceId = getState().getIn(['session', 'serviceId'])

		dispatch(actions.foldersPutTargetsRequest())

		worker
			.put(`/folders/${folderId}/targets`, {
				targets,
				organizationId,
				serviceId,
			})
			.then(() => {
				dispatch(actions.foldersPutTargetsSuccess())
			})
			.catch(res => {
				console.error(`Error updating folder targets`, res)
				dispatch(
					actions.foldersPutTargetsError(
						(res.body && res.body.error && res.body.error) || res
					)
				)
			})
	}
}

export default {
	types,
	reducer,
	actions,
}
