import React from 'react'
import styled from 'styled-components'
import { useTransition, animated } from 'react-spring'

import Portal from '../utils/Portal'

const CurtainBase = styled(animated.div)`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	cursor: auto;
	z-index: 2;

	background: radial-gradient(
		ellipse farthest-corner at center 0px,
		rgba(0, 0, 0, 0.26),
		rgba(0, 0, 0, 0.28)
	);
	opacity: 0;
`

const transition = {
	from: {
		opacity: 0,
	},
	enter: {
		opacity: 1,
	},
	leave: {
		opacity: 0,
	},
	config: {
		duration: 200,
	},
}

export default function Curtain({ visible }) {
	const transitions = useTransition(visible, null, transition)
	return (
		<React.Fragment>
			{transitions.map(({ item: visible, props: style, key }) => {
				if (!visible) return null

				return (
					<Portal key={key}>
						<CurtainBase style={style} />
					</Portal>
				)
			})}
		</React.Fragment>
	)
}
