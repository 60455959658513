import color from './color'

const tooltip = {
	durationIn: 50,
	durationOut: 20,
	// durationIn: 1000,
	// durationOut: 1000,
	padding: '4px 8px',
	fg: color.white,
	bg: color.blackAlpha2,
	fontSize: '.86rem',
	borderRadius: '4px',
}

export default tooltip
